import { DateTime } from 'luxon';
import { Collaborator } from '../collaborators/Collaborator';
import SystemDocumentType from './SystemDocumentType';
import DocumentUploadControl, {
  DocumentUploadControlSaved,
  IDocumentControlSaved,
} from './DocumentUploadControl';
import DocumentUploadControlVisitor from '../projects/DocumentUploadControlVisitor';
import DocumentUploadControlPendingSaved from './DocumentUploadControlSaved';
import { SystemDocument } from './SystemDocument';
import { ProjectDocumentControlForm } from '../../Components/Proyectos/ProjectConfiguration/ProjectConfigurationDocsForm';

class DocumentUploadControlConfirmed implements DocumentUploadControlSaved {
  private constructor(
    private control: DocumentUploadControlPendingSaved,
    private uploadedBy: Collaborator,
    private uploadedOn: DateTime,
    private document: SystemDocument
  ) {}

  static from(
    control: DocumentUploadControlPendingSaved,
    uploadedBy: Collaborator,
    uploadedOn: DateTime,
    document: SystemDocument
  ) {
    return new this(control, uploadedBy, uploadedOn, document);
  }

  toIDocumentControl(): IDocumentControlSaved {
    const data = this.control.toIDocumentControl();
    return {
      ...data,
      ccdp_responsable: this.uploadedBy.getId(),
      ccdp_fecha_carga: String(this.uploadedOn.toUTC().toISODate()),
      ccdp_docid: this.document.getId(),
    };
  }

  accept(visitor: DocumentUploadControlVisitor): any {
    return visitor.visitDocumentUploadControlConfirmed(this);
  }

  getId(): string {
    return this.control.getId();
  }

  getDocumentType(): SystemDocumentType {
    return this.control.getDocumentType();
  }

  getCollaborator(): Collaborator {
    return this.control.getCollaborator();
  }

  getConcept(): string {
    return this.control.getConcept();
  }

  getLimitDate(): DateTime {
    return this.control.getLimitDate();
  }

  getDocument(): SystemDocument {
    return this.document;
  }

  isUploaded(): boolean {
    return true;
  }
  isExpired(): boolean {
    return false;
  }
  syncWith(doc: DocumentUploadControl): this {
    this.control.syncWith(doc);
    return this;
  }
}

export default DocumentUploadControlConfirmed;
