import * as React from 'react';
import {
  Text,
  Icon,
  PersonaSize,
  OverflowButtonType,
  Facepile,
  IFacepilePersona,
  Slider,
} from '@fluentui/react';
import { DateCustom } from '../../lib/DateCustom';
import './Calendar.scss';
import { styles } from './CalendarStyles';
import { Activity } from '../../system/activity/Activity';
import TimeTaxSupportSystemClient from '../../system/TimeTaxSupportSystemClient';
import MessageCourier from '../../lib/MessageCourier';
import { CalendarActivityCalloutFooter } from './CalendarActivityCalloutFooter';
import { User } from '../../system/User';
import { useTranslation } from 'react-i18next';
import { ActivityMultipleOptionsDialog } from './ActivityDialog/ActivityMenuOptions/ActivityMultipleOptionsDialog';
import XhrRequestError from '../../lib/XhrRequestError';
import ActivitySystem from '../../system/activity/ActivitySystem';
import { ConfirmDuplicatedActivityDialog } from './ConfirmDuplicatedActivityDialog';

interface ICalendarActivityCalloutWrapper {
  labelId: string;
  descriptionId: string;
  targetId: string;
  activity: Activity;
  system: TimeTaxSupportSystemClient;
  user: User;
  toggleIsCalloutVisible: () => void;
  eventData: any;
  openDialog: () => void;
  refreshEvents: () => void;
  courier: MessageCourier;
  pendingTag: boolean;
  isAvaliablePeriodForEditOrCreateActivity: boolean;
  openUnableModifyHoursDialog?: () => void;
  openDialogEdit?: () => void;
  changeBlockUI: (v: boolean) => void;
}

export const CalendarActivityCalloutWrapper = (props: ICalendarActivityCalloutWrapper) => {
  const { t } = useTranslation();
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
  const [showAcceptTagDialog, setShowAcceptTagDialog] = React.useState<boolean>(false);
  const [showDeclineTagDialog, setShowDeclineTagDialog] = React.useState<boolean>(false);
  const [changeParticipation, setChangeParticipation] = React.useState<boolean>(false);
  const [showDuplicatedActivityMsg, setShowDuplicatedActivityMsg] = React.useState<boolean>(false);
  const [showDateBlockedActivityMsg, setShowDateBlockedActivityMsg] = React.useState<boolean>(false);
  const [hoursToAccept, setHoursToAccept] = React.useState<number>(0);
  React.useEffect(() => {
    setHoursToAccept(props.activity.getHours());
  }, [props.activity]);

  const collaboratorActivity = React.useMemo(
    () => props.activity.getCollaboratorActivityList().find((colact) => colact.referencesUser(props.user)),
    [props.activity, props.user]
  );

  const pendingTag = React.useMemo(
    () =>
      !!(
        collaboratorActivity &&
        !collaboratorActivity.getChecked() &&
        !collaboratorActivity.getChecked_by_colid()
      ),
    [collaboratorActivity]
  );

  const declined = React.useMemo(
    () =>
      !!(
        collaboratorActivity &&
        !collaboratorActivity.getChecked() &&
        collaboratorActivity.getChecked_by_colid()
      ),
    [collaboratorActivity]
  );

  const checked = React.useMemo(
    () => !!collaboratorActivity && collaboratorActivity.getChecked(),
    [collaboratorActivity]
  );
  const getHoursChecked = () => {
    let colact = collaboratorActivity;
    return colact && colact.getHoras();
  };

  //  ------------------------------- Aceptar tag -------------------------------
  const [confirmDuplicatedActivityDialog, setConfirmDuplicatedActivityDialog] =
    React.useState<boolean>(false);

  const onCloseConfirmDuplicatedActivityDialog = async (confirm: boolean) => {
    if (confirm) {
      if (props.activity) {
        props.changeBlockUI(true);
        try {
          if (props.activity.getReplicated_from_id()) {
            if (!showAcceptTagDialog) {
              setShowAcceptTagDialog(true);
            }
          } else {
            acceptTag(false, false);
          }
        } catch (error) {
          props.courier.messageError(error);
        }
        props.changeBlockUI(false);
      }
    }
    setConfirmDuplicatedActivityDialog(false);
  };

  const onClickAcceptTag = async () => {
    props.changeBlockUI(true);
    try {
      const except = props.activity.getId();
      const hasDuplicates = await props.system.checkDuplicates(props.activity, except);
      if (hasDuplicates) {
        setConfirmDuplicatedActivityDialog(true);
      } else {
        if (props.activity.getReplicated_from_id()) {
          if (!showAcceptTagDialog) {
            setShowAcceptTagDialog(true);
          }
        } else {
          acceptTag(false, false);
        }
      }
    } catch (error) {
      props.courier.messageError(error);
    }
    props.changeBlockUI(false);
  };

  const acceptTag = async (allAfterThis: boolean = false, all: boolean = false) => {
    props.changeBlockUI(true);
    try {
      let colact = collaboratorActivity;
      if (colact && colact.getId()) {
        await props.system.getActivitySystem().acceptTag(colact.getId(), hoursToAccept, allAfterThis, all);
        props.refreshEvents();
        if (allAfterThis || all) {
          props.courier.messageSuccess(t('Las actividades fueron aceptadas de manera exitosa'));
        } else {
          props.courier.messageSuccess(t('La actividad fue aceptada de manera exitosa'));
        }
      }
      setChangeParticipation(false);
    } catch (error) {
      props.courier.messageError(error);
      if (
        error instanceof XhrRequestError &&
        error.apiErrorCodeIs(ActivitySystem.duplicatedActivityErrorCode)
      ) {
        setShowDuplicatedActivityMsg(true);
      }

      if (
        error instanceof XhrRequestError &&
        error.apiErrorCodeIs(ActivitySystem.dateBlockedActivityErrorCode)
      ) {
        setShowDateBlockedActivityMsg(true);
      }
    } finally {
      props.changeBlockUI(false);
    }
  };

  //  ------------------------------- Declinar tag -------------------------------------------------------
  const onClickDeclineTag = () => {
    if (props.activity.getReplicated_from_id()) {
      if (!showDeclineTagDialog) {
        setShowDeclineTagDialog(true);
      }
    } else {
      declineTag(false, false);
    }
  };

  const declineTag = async (allAfterThis: boolean = false, all: boolean = false) => {
    props.changeBlockUI(true);
    try {
      let colact = collaboratorActivity;
      if (colact && colact.getId()) {
        await props.system.getActivitySystem().declineTag(colact.getId(), allAfterThis, all);
        props.refreshEvents();
        if (allAfterThis || all) {
          props.courier.messageSuccess(t('Las actividades fueron declinadas de manera exitosa'));
        } else {
          props.courier.messageSuccess(t('La actividad fue declinada de manera exitosa'));
        }
      }
      setChangeParticipation(false);
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      props.changeBlockUI(false);
    }
  };

  //  ------------------------------- Eliminado de la actividad -------------------------------
  const onClickDeleteActivity = () => {
    if (props.activity.getReplicated_from_id()) {
      if (!showDeleteDialog) {
        setShowDeleteDialog(true);
      }
    } else {
      deleteActivity(false, false);
    }
  };

  const deleteActivity = async (allAfterThis: boolean = false, all: boolean = false) => {
    props.changeBlockUI(true);
    try {
      await props.system.getActivitySystem().delete(props.activity, allAfterThis, all);
      props.refreshEvents();
      if (allAfterThis || all) {
        props.courier.messageSuccess(t('Las actividades se eliminaron de manera exitosa'));
      } else {
        props.courier.messageSuccess(t('La actividad se eliminó de manera exitosa'));
      }
      setChangeParticipation(false);
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      props.changeBlockUI(false);
    }
  };

  // Mapea el array que necesita <Facepile> para pasarselo como parametro
  const [CollaboratorsPersona, setCollaboratorsPersona] = React.useState<IFacepilePersona[]>([]);
  React.useEffect(() => {
    let colaboradorActividadList = props.activity.getCollaboratorActivityList();
    if (colaboradorActividadList) {
      let _colList = colaboradorActividadList.map((colact) => {
        return {
          personaName: colact.getCollaborator().fullName(),
        };
      });
      setCollaboratorsPersona(_colList);
    } else {
      setCollaboratorsPersona([]);
    }
  }, [props.activity, props.eventData]);

  return (
    <>
      <div className={styles.container}>
        <Text block variant="xLarge" className={styles.title} title={props.activity.getName()}>
          {/* title={props.eventData.event.title}> */}
          <Icon iconName="AccountActivity" className="text-icon" />
          {props.activity.getName()}
          {/* {props.eventData.event.title} */}
          <Text
            className="margin-proyecto "
            block
            variant="small"
            title={`${props.activity.getProject()?.getTitleProject()}`}
            // title={`(${props.eventData.event._def.extendedProps.data.proyecto.pro_codigo}) - ${props.eventData.event._def.extendedProps.data.proyecto.pro_nombre} `}
          >
            {/* {`(${props.eventData.event._def.extendedProps.data.proyecto.pro_codigo}) - ${props.eventData.event._def.extendedProps.data.proyecto.pro_nombre} `} */}
            {`${props.activity.getProject()?.getTitleProject()} `}
          </Text>
        </Text>
        <Text block variant="medium">
          <Icon iconName="Clock" className="text-icon" />
          {DateCustom.getDisplayText(props.activity.getStart(), props.activity.getEnd())}
        </Text>

        <Text block variant="medium" className="margin-documents">
          <Icon iconName="TextDocument" className="text-icon" />
          {/* {props.eventData.event._def.extendedProps.data.cantDocumentosCargados > 0 */}
          {props.activity.getDocumentActivityList().length > 0
            ? t('Tiene ') +
              // props.eventData.event._def.extendedProps.data.cantDocumentosCargados +
              props.activity.getDocumentActivityList().length +
              t(' documento/s')
            : t('No tiene documentos cargados')}
        </Text>

        <div className={styles.facepileTeamContainer}>
          <Icon iconName="Group" className={styles.groupIcon} />

          <Facepile
            className={styles.facepileTeam}
            personas={CollaboratorsPersona}
            personaSize={PersonaSize.size24}
            maxDisplayablePersonas={10}
            overflowButtonProps={{ ariaLabel: 'Ver más' }}
            overflowButtonType={OverflowButtonType.descriptive}
          />
        </div>

        {(pendingTag || changeParticipation) && (
          <div>
            <hr />
            <Text block variant="small">
              {props.activity.getCollaboratorOwner()?.fullName() +
                t(' te añadió a esta actividad, agrégala a tu calendario.')}
            </Text>
            <hr />

            <Text block variant="medium">
              {t('Aceptar ') + DateCustom.formatHorasToString(hoursToAccept)}
            </Text>

            <Slider
              onChange={(val) => setHoursToAccept(val)}
              min={0.25}
              // max={8}
              max={props.activity.getHours() >= 0.25 ? props.activity.getHours() : 8}
              step={0.25}
              value={hoursToAccept}
              showValue={false}
              defaultValue={props.activity.getHours()}
            />
          </div>
        )}

        {declined && !changeParticipation && (
          <div>
            <hr />
            <Text block variant="small">
              {t('Declinaste esta actividad.')}
            </Text>
            <hr />
          </div>
        )}

        {checked && !changeParticipation && (
          <div className="hours-checked">
            <Icon iconName="ReminderTime" className="text-icon" />
            <Text block variant="medium" className="d-inline">
              {t('Aceptaste ') + DateCustom.formatHorasToString(getHoursChecked())}
            </Text>
          </div>
        )}

        {showDuplicatedActivityMsg && (
          <div className="hours-checked text-error">
            <Icon iconName="Warning" className="text-icon" />
            <Text block variant="medium" className="d-inline text-error">
              {t('Esta actividad está duplicada')}
            </Text>
          </div>
        )}

        {showDateBlockedActivityMsg && (
          <div className="hours-checked text-error">
            <Icon iconName="Warning" className="text-icon" />
            <Text block variant="medium" className="d-inline text-error">
              {t('En esta fecha ya no está disponible la creación/edición de actividades')}
            </Text>
          </div>
        )}
      </div>
      <CalendarActivityCalloutFooter
        refreshEvents={props.refreshEvents}
        courier={props.courier}
        activity={props.activity}
        system={props.system}
        user={props.user}
        openDialog={props.openDialog}
        openDialogEdit={props.openDialogEdit}
        onClickDeleteActivity={onClickDeleteActivity}
        onClickAcceptTag={onClickAcceptTag}
        onClickDeclineTag={onClickDeclineTag}
        isAvaliablePeriodForEditOrCreateActivity={props.isAvaliablePeriodForEditOrCreateActivity}
        openUnableModifyHoursDialog={props.openUnableModifyHoursDialog}
        pendingTag={pendingTag}
        declined={declined}
        checked={checked}
        onClickChangeParticipation={(v: boolean) => setChangeParticipation(v)}
        changeParticipation={changeParticipation}
      />

      {/* ---------------------- Eliminar Dialog ---------------------- */}
      {props.activity.getReplicated_from_id() && (
        <ActivityMultipleOptionsDialog
          system={props.system}
          activity={props.activity}
          showDialog={showDeleteDialog}
          toggleShowDialog={() => {
            setShowDeleteDialog(!showDeleteDialog);
          }}
          onAcceptCallback={deleteActivity}
          acceptText={t('Eliminar')}
        ></ActivityMultipleOptionsDialog>
      )}

      {/* ---------------------- Aceptar tag Dialog ---------------------- */}
      <ActivityMultipleOptionsDialog
        system={props.system}
        activity={props.activity}
        showDialog={showAcceptTagDialog}
        toggleShowDialog={() => {
          setShowAcceptTagDialog(!showAcceptTagDialog);
        }}
        onAcceptCallback={acceptTag}
        acceptText={t('Aceptar')}
      ></ActivityMultipleOptionsDialog>
      {/* ---------------------- Declinar tag Dialog ---------------------- */}
      <ActivityMultipleOptionsDialog
        system={props.system}
        activity={props.activity}
        showDialog={showDeclineTagDialog}
        toggleShowDialog={() => {
          setShowDeclineTagDialog(!showDeclineTagDialog);
        }}
        onAcceptCallback={declineTag}
        acceptText={t('Declinar')}
      ></ActivityMultipleOptionsDialog>

      <ConfirmDuplicatedActivityDialog
        show={confirmDuplicatedActivityDialog}
        onClose={onCloseConfirmDuplicatedActivityDialog}
      ></ConfirmDuplicatedActivityDialog>
    </>
  );
};
