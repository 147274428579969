import { DateTime } from 'luxon';
import { SystemDateParser } from '../SystemDateParser';

export interface IPeriodDatesBlocked {
  usuario: null;
  pbc_id: string;
  pbc_desde: string;
  pbc_hasta: string;
  pbc_activo: boolean;
  pbc_usid: string;
  pbc_fecha_alta: string;
  pbc_motivo: string;
}

export class PeriodDatesBlocked {
  protected constructor(
    private id: string,
    private from: DateTime,
    private to: DateTime,
    private active: boolean,
    private motive: string
  ) {}

  static identifiedBy(id: string, from: DateTime, to: DateTime, active: boolean, motive: string) {
    // Assertions aca
    return new this(id, from, to, active, motive);
  }

  static buildFrom(pbc: IPeriodDatesBlocked) {
    let parser = new SystemDateParser('dd/LL/yyyy');
    return this.identifiedBy(
      pbc.pbc_id,
      parser.parseNoTime(pbc.pbc_desde),
      parser.parseNoTime(pbc.pbc_hasta),
      pbc.pbc_activo,
      pbc.pbc_motivo
    );
  }

  isInRange(d: DateTime){
    return (
          (d >= this.getFrom() && d <= this.getTo()) ||
          d.hasSame(this.getFrom(), 'day') ||
          d.hasSame(this.getTo(), 'day')
        );
  }

  getId() {
    return this.id;
  }
  getFrom() {
    return this.from;
  }
  getTo() {
    return this.to;
  }
  getActive() {
    return this.active;
  }
  getMotive() {
    return this.motive;
  }
}
