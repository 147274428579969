import { DateTime } from 'luxon';
import CollaboratorSystem, { ICollaborator } from '../CollaboratorSystem';
import { Collaborator } from '../Collaborator';
import { SystemDateParser } from '../../SystemDateParser';
import { newEntityDefaultId } from '../../../common/constants';
import AssertionEntity from '../../../lib/FormValidator/Assertion/AssertionEntity';
import AssertionItem from '../../../lib/FormValidator/Assertion/AssertionItem';

export interface ICollaboratorTeam {
  coleq_activo: boolean;
  coleq_id: string | null;
  coleq_colid: string;
  coleq_eqid: string;
  coleq_porc_asig: number;
  coleq_total_horas_asig: number | null;
  coleq_favorito: boolean;
  coleq_ultimo_uso: string;
  __delete: boolean;
  colaborador: ICollaborator;
}

class CollaboratorTeam {
  protected constructor(
    private id: string | null,
    private active: boolean,
    private percentage_asignation: number,
    private total_hours_asignation: number,
    private __delete: boolean,
    private isFavorite: boolean,
    private lastUse: DateTime,
    private collaborator: Collaborator
  ) {}

  static identifiedBy(
    id: string | null,
    active: boolean,
    percentage_asignation: number,
    total_hours_asignation: number,
    isDeleted: boolean,
    isFavorite: boolean,
    lastUse: DateTime,
    collaborator: Collaborator
  ) {
    // Assertions aca
    const assertions = CollaboratorTeamAssertions.build();
    assertions.evaluateAllAssertions({ percentage_asignation });
    return new this(
      id,
      active,
      percentage_asignation,
      total_hours_asignation,
      isDeleted,
      isFavorite,
      lastUse,
      collaborator
    );
  }

  static buildFrom(collaboratorSystem: CollaboratorSystem, collaboratorTeam: ICollaboratorTeam) {
    let collaborator = Collaborator.fromICollaborator(collaboratorSystem, collaboratorTeam.colaborador);
    return this.identifiedBy(
      collaboratorTeam.coleq_id,
      collaboratorTeam.coleq_activo,
      collaboratorTeam.coleq_porc_asig,
      collaboratorTeam.coleq_total_horas_asig ? collaboratorTeam.coleq_total_horas_asig : 0,
      collaboratorTeam.__delete,
      collaboratorTeam.coleq_favorito,
      SystemDateParser.defaultParser().parseUTC(collaboratorTeam.coleq_ultimo_uso),
      collaborator
    );
  }

  static initializeFromColaborador(colab: Collaborator): CollaboratorTeam {
    // todo revisar caso de preuba en excel.
    return this.identifiedBy(null, colab.getActive(), 0, 0, false, false, DateTime.now(), colab);
  }

  toICollaboratorTeam(collaboratorTeam: CollaboratorTeam, teamId: string) {
    let ret: ICollaboratorTeam = {
      coleq_activo: collaboratorTeam.getActive(),
      coleq_id: this.id,
      coleq_eqid: teamId,
      coleq_colid: collaboratorTeam.getCollaborator().getId(),
      coleq_porc_asig: collaboratorTeam.getPercentage_asignation(),
      coleq_total_horas_asig: collaboratorTeam.getTotal_hours_asignation(),
      __delete: collaboratorTeam.get__delete(),
      coleq_favorito: collaboratorTeam.isFavorite,
      coleq_ultimo_uso: String(collaboratorTeam.lastUse.toISO()),
      colaborador: collaboratorTeam.getCollaborator().toICollaborator(),
    };
    return ret;
  }

  collaboratorIs(aCollaborator: Collaborator) {
    if (aCollaborator === null) return false;
    return aCollaborator.isIdentifiedBy(this.getCollaborator().getId());
  }

  isForSameCollaboratorAs(anotherCollaborator: CollaboratorTeam) {
    return anotherCollaborator.collaboratorIs(this.getCollaborator());
  }

  isActive(): boolean {
    return this.active && !this.__delete;
  }

  public getId() {
    return this.id;
  }
  public getActive() {
    return this.active;
  }
  public getPercentage_asignation() {
    return this.percentage_asignation;
  }
  public getTotal_hours_asignation() {
    return this.total_hours_asignation;
  }
  public getFavorite() {
    return this.isFavorite;
  }

  public get__delete() {
    return this.__delete;
  }
  public getCollaborator() {
    return this.collaborator;
  }

  public setId(value: string) {
    this.id = value;
  }
  public setActive(value: boolean) {
    this.active = value;
  }
  public setPercentage_asignation(value: number) {
    this.percentage_asignation = value;
  }
  public setTotal_hours_asignation(value: number) {
    this.total_hours_asignation = value;
  }
  public set__delete(value: boolean) {
    this.__delete = value;
  }
}

class CollaboratorTeamAssertions extends AssertionEntity {
  private constructor() {
    super();
  }

  static build() {
    const instance = new this();
    instance.addAssertionForPercentageAssignation();
    return instance;
  }

  addAssertionForPercentageAssignation(key = 'percentage_asignation') {
    this.defineAssertionForProperty(
      key,
      AssertionItem.forClosure(
        (value) => value >= 0 && value <= 100,
        key,
        'Percentage_asignation must be a number between 0 and 100'
      )
    );
  }
}

export default CollaboratorTeam;
