import * as React from 'react';
import './Calendar.scss';
import TTSEvent from './TTSEvent';
import ActivityDialogEdit from './ActivityDialog/ActivityDialogEdit';
import ActivityDialog from './ActivityDialog/ActivityDialog';
import { CalendarNewActivityCallout } from './CalendarNewActivityCallout';
import { CalendarActivityCallout } from './CalendarActivityCallout';
import { Activity } from '../../system/activity/Activity';
import TimeTaxSupportSystemClient from '../../system';
import CollaboratorActivity from '../../system/collaborators/collaborator_activity/CollaboratorActivity';
import { BillableDistribution } from '../../system/billable_distribution/BillableDistribution';
import { DateCustom } from '../../lib/DateCustom';
import MessageCourier from '../../lib/MessageCourier';
import { newEntityDefaultId } from '../../common/constants';
import { IProjectDescriptor } from '../../system/projects/ProjectDescriptor';
import { User } from '../../system/User';
import { ActivityValidations } from './ActivityDialog/ActivityValidations';
import { useTranslation } from 'react-i18next';
import { IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import SystemConfiguration from '../../system/configuration/SystemConfiguration';
import { UnableModifyHoursDialog } from './UnableModifyHoursDialog';
import { ConfirmDuplicatedActivityDialog } from './ConfirmDuplicatedActivityDialog';
import PeriodDatesBlockedSystem from '../../system/period_dates_blocked/PeriodDatesBlockedSystem';
import { DateTime } from 'luxon';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';

interface ICalendarEventCallout {
  system: TimeTaxSupportSystemClient;
  projects: IProjectDescriptor[];
  user: User;
  eventData: any | null;
  targetId: string;
  labelId: string;
  descriptionId: string;
  toggleIsCalloutVisible: () => void;
  isCalloutVisible: boolean;
  commonContext: any;
  getEvents: () => void;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
  //estas propiedades de abajo son exclusivas para el uso de la actividad en la lista.
  onlyShowDialogs?: boolean;
  activityProvided?: Activity;
  showDialog?: boolean;
  showDialogEdit?: boolean;
  toggleShowDialog?: () => void;
  toggleShowDialogEdit?: () => void;
  // openUnableModifyHoursDialog: () => void;
  config: SystemConfiguration;
  periodsBlocked: PeriodDatesBlockedSystem;
  hideBtnEdit?: boolean;
  hideMenuOptions?: boolean;
}

export const CalendarEventCallout = (props: ICalendarEventCallout) => {
  const { t } = useTranslation();
  const [activity, setActivity] = React.useState<Activity>();
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [projectContext, setProjectContext] = React.useState<any>();
  const [projectOptions, setProjectOptions] = React.useState<IComboBoxOption[]>([]);
  const [showDialogEdit, setShowDialogEdit] = React.useState<boolean>(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [unableModifyHoursDialog, setUnableModifyHoursDialog] = React.useState<boolean>(false);
  const [confirmDuplicatedActivityDialog, setConfirmDuplicatedActivityDialog] =
    React.useState<boolean>(false);

  const onCloseUnableModifyHoursDialog = () => {
    props.getEvents();
    setUnableModifyHoursDialog(false);
  };

  const openUnableModifyHoursDialog = () => {
    setUnableModifyHoursDialog(true);
  };

  const ttsEvent = new TTSEvent();
  const validations = new ActivityValidations();

  const eventData = React.useMemo(() => {
    return props.eventData;
  }, [props.eventData]);

  const getActivity = async () => {
    if (props.activityProvided) {
      setActivity(props.activityProvided);
    } else {
      props.changeBlockUI(true);
      try {
        let id = props.eventData.event._def.extendedProps.data.id;
        if (ttsEvent.isNewEventExternal(props.eventData.event)) {
          id = newEntityDefaultId;
        }
        const activityFromAPI: Activity = await props.system.getActivitySystem().getActivity(id);
        if (activityFromAPI != null) {
          const start = props.eventData.event.start as Date;
          const end = props.eventData.event.end as Date;
          activityFromAPI.moveTo(start, end);
          if (props.config && !props.config.canUploadActivitiesOn(start) && activityFromAPI.isNew()) {
            openUnableModifyHoursDialog();
            // cierra el callout
            props.toggleIsCalloutVisible();
          }
        }
        if (ttsEvent.isNewEventExternal(props.eventData.event)) {
          activityFromAPI.setName(props.eventData.event.title);
          activityFromAPI.setRefer_outlook(props.eventData.event._def.extendedProps.data.id);
        }
        setActivity(activityFromAPI);
      } catch (error) {
      } finally {
        props.changeBlockUI(false);
      }
    }
  };

  React.useEffect(() => {
    if (props.isCalloutVisible || (props.onlyShowDialogs && props.activityProvided)) {
      getActivity();
    }
  }, [props.isCalloutVisible, props.activityProvided, props.onlyShowDialogs]);

  const onChangeActivity = (e: any) => {
    if (activity) {
      const _activity = activity.getCopy();
      const { name, value } = e.target;
      _activity.setGeneric(name, value);
      setActivity(_activity);
    }
  };

  /**
   * Cambia la fecha de inicio y tambien actualiza la fecha de fin.
   * @param date
   */
  const onChangeDate = (date: any) => {
    if (activity) {
      let _activity: Activity = activity.getCopy();
      _activity.setStart(DateCustom.changeOnlyDate(_activity.getStart(), date));
      _activity.setEnd(DateCustom.addMinutes(_activity.getStart(), _activity.getHours() * 60));
      setActivity(_activity);
    }
  };

  const onChangeDropdown = async (name: string, option: any) => {
    // Dropdown de proyecto
    if (name === 'proid') {
      onChangeProject(option);
    }
    if (name === 'hours') {
      onChangeHours(option);
    }
  };

  const onChangeProject = async (option: any) => {
    if (activity) {
      const _proid = option.key;
      const _projectContext = await props.system.getActivitySystem().getContextFromProyecto(_proid);
      if (props.projects) {
        const project = props.projects.find((project) => project.isIdentifiedBy(_proid));
        if (project) {
          const _activity = activity.getCopy();
          _activity.setProject(project);
          setActivity(_activity);
        }
      }
      setProjectContext(_projectContext);
    }
  };

  const initialContext = useAsyncDataCourier(
    () =>
      activity && !activity?.isNew()
        ? props.system.getActivitySystem().getContextFromProyecto(activity!.getPro_id())
        : Promise.resolve(null),
    [activity?.getId()],
    props.courier
  );

  React.useEffect(() => {
    setProjectContext(initialContext);
  }, [initialContext]);

  const onChangeHours = async (option: any) => {
    // option.key = cantidad de minutos
    if (activity && option) {
      let _activity: Activity = activity.getCopy();
      let _duration = option.key / 60;
      _activity.setEnd(DateCustom.addMinutes(_activity.getStart(), option.key));
      _activity.setHours(_duration);
      setActivity(_activity);
    }
  };

  const updateActivity = () => {
    if (activity) {
      setActivity(activity.getCopy());
    }
  };

  React.useEffect(() => {
    if (activity && projectContext) {
      let _activity = activity.getCopy();
      // Solo setea los datos por defecto del proyecto si es nueva.
      if (_activity.isNew()) {
        // if(projectContext.)
        _activity.setTimezone(props.system.getDateParser().getUserTimezone());
        let _collaboratorActivityList = CollaboratorActivity.getCollaboratorActivityFromProjectContext(
          props.system.getCollaboratorSystem(),
          projectContext.proyecto.colaboradores,
          activity.getHours(),
          props.user.getCollaboratorId()
        );
        const shouldSuggestTags = activity.getProject()?.getSuggestTags();
        if (!shouldSuggestTags) {
          _collaboratorActivityList = _collaboratorActivityList.filter((colact) =>
            colact.getCollaborator().isIdentifiedBy(props.user.getCollaboratorId())
          );
        }
        _activity.setCollaboratorActivityList(_collaboratorActivityList);

        let _billableDistributionList = BillableDistribution.getBillableDistributionFromProjectContext(
          props.system.getCompanySystem(),
          projectContext.proyecto.empresas
        );
        _activity.setDistributionBillableList(_billableDistributionList);
        setActivity(_activity);
      }
    }
  }, [projectContext]);

  React.useEffect(() => {
    // Prepara las opciones para el dropdown de proyectos
    let _projectOptions: IComboBoxOption[] = [];
    if (props.projects) {
      const ownProjects = props.projects.filter((p) => {
        return p.isOwner(props.user.getCollaborator());
      });

      if (ownProjects.length > 0) {
        _projectOptions.push({
          key: 'Header1',
          text: 'Proyectos que has generado y participas',
          itemType: SelectableOptionMenuItemType.Header,
        });
        ownProjects
          .sort((a, b) => (a.getId() < b.getId() ? 1 : -1))

          .forEach((op) => {
            _projectOptions.push({
              key: op.getId(),
              text: op.getTitleProject(),
            });
          });
      }

      const participateProjects = props.projects.filter((p) => {
        return !p.isOwner(props.user.getCollaborator());
      });

      if (participateProjects.length > 0) {
        _projectOptions.push(
          { key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
          { key: 'Header2', text: 'Proyectos que participas', itemType: SelectableOptionMenuItemType.Header }
        );
        participateProjects
          .sort((a, b) => (a.getId() < b.getId() ? 1 : -1))
          .forEach((p) => {
            _projectOptions.push({
              key: p.getId(),
              text: p.getTitleProject(),
            });
          });
      }

      setProjectOptions(_projectOptions);
    }
  }, [props.projects, props.user]);

  const isAvaliablePeriodForEditOrCreateActivity = React.useMemo(() => {
    if (activity || eventData) {
      let isBlockedByPeriods: boolean = false;
      let isBlockedByConfig: boolean = false;

      if (activity) {
        isBlockedByPeriods = props.periodsBlocked.isDateBlocked(DateTime.fromJSDate(activity.getStart()));
        isBlockedByConfig = !props.config.canUploadActivitiesOn(activity.getStart());
      } else if (eventData && eventData.event) {
        const start = eventData.event.start;
        const end = eventData.event.end;
        isBlockedByPeriods = props.periodsBlocked.isDateBlocked(DateTime.fromJSDate(start));
        isBlockedByConfig = !props.config.canUploadActivitiesOn(end);
      }

      return !isBlockedByPeriods && !isBlockedByConfig;
    }
    return false;
  }, [activity, props.config, props.periodsBlocked]);

  const openDialog = () => {
    console.log('paso toggle 3');
    props.toggleIsCalloutVisible();
    setShowDialog(true);
    // } else {
    //   if (props.openUnableModifyHoursDialog) props.openUnableModifyHoursDialog();
  };

  const openDialogEdit = () => {
    if (isAvaliablePeriodForEditOrCreateActivity) {
      console.log('paso toggle 4');
      props.toggleIsCalloutVisible();
      setShowDialogEdit(true);
      setEdit(true);
    } else {
      openUnableModifyHoursDialog();
    }
  };

  React.useEffect(() => {
    if (
      eventData?.event?._def?.extendedProps?.data?.id === newEntityDefaultId &&
      eventData.view.type === 'dayGridMonth'
    ) {
      openDialogEdit();
    }
  }, [eventData?.event?._def?.extendedProps?.data?.id]);

  React.useEffect(() => {
    if (activity && activity.isNew() && props.isCalloutVisible) {
      openDialogEdit();
    }
  }, [activity, props.isCalloutVisible]);

  const onSave = async () => {
    if (activity) {
      props.changeBlockUI(true);
      try {
        const except = activity?.isNew() ? '' : activity.getId();
        const hasDuplicates = await props.system.checkDuplicates(activity, except);
        if (hasDuplicates) {
          setConfirmDuplicatedActivityDialog(true);
        } else {
          await props.system.saveActivity(activity);
          props.getEvents();
          props.courier.messageSuccess(t('La actividad se guardó de manera exitosa'));
          setEdit(false);
        }
      } catch (error) {
        props.courier.messageError(error);
      }
      props.changeBlockUI(false);
    }
  };

  const onCloseConfirmDuplicatedActivityDialog = async (confirm: boolean) => {
    if (confirm) {
      if (activity) {
        props.changeBlockUI(true);
        try {
          await props.system.saveActivity(activity);
          props.getEvents();
          props.courier.messageSuccess(t('La actividad se guardó de manera exitosa'));
        } catch (error) {
          props.courier.messageError(error);
        }
        setEdit(false);
        props.changeBlockUI(false);
      }
    }
    setConfirmDuplicatedActivityDialog(false);
  };

  const isNewEvent = () => {
    if (props.eventData !== null) {
      return (
        eventData && (ttsEvent.isNewEvent(eventData.event) || ttsEvent.isNewEventExternal(eventData.event))
      );
    }
    if (props.activityProvided) {
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    if (props.showDialog !== undefined) {
      setShowDialog(props.showDialog);
    }
    if (props.showDialogEdit !== undefined) {
      setShowDialogEdit(props.showDialogEdit);
    }
  }, [props.showDialog, props.showDialogEdit]);

  const onEdit = () => {
    if (isAvaliablePeriodForEditOrCreateActivity) {
      setShowDialog(false);
      setShowDialogEdit(true);
      setEdit(true);
      if (props.toggleShowDialog) props.toggleShowDialog();
    } else {
      openUnableModifyHoursDialog();
    }
  };

  return (
    <>
      {!props.onlyShowDialogs && (
        <>
          {activity && props.isCalloutVisible ? (
            <>
              {isNewEvent() ? (
                // ######################################################################################################
                //                                      Callout de nueva actividad
                // ######################################################################################################
                <CalendarNewActivityCallout
                  validations={validations}
                  user={props.user}
                  updateActivity={updateActivity}
                  projectOptions={projectOptions}
                  activity={activity}
                  eventData={eventData}
                  onChangeActivity={onChangeActivity}
                  onChangeDate={onChangeDate}
                  onChangeDropdown={onChangeDropdown}
                  labelId={props.labelId}
                  descriptionId={props.descriptionId}
                  targetId={props.targetId}
                  openDialog={openDialogEdit}
                  toggleIsCalloutVisible={() => {
                    console.log('paso toggle props new act.');
                    props.toggleIsCalloutVisible();
                  }}
                  onSave={onSave}
                  submitted={submitted}
                  onSubmit={(v: boolean) => setSubmitted(v)}
                  config={props.config}
                />
              ) : (
                // ######################################################################################################
                //                                  Callout de actividad ya creada
                // ######################################################################################################
                <CalendarActivityCallout
                  labelId={props.labelId}
                  activity={activity}
                  system={props.system}
                  user={props.user}
                  descriptionId={props.descriptionId}
                  targetId={props.targetId}
                  toggleIsCalloutVisible={() => {
                    console.log('paso toggle props act.');
                    props.toggleIsCalloutVisible();
                  }}
                  eventData={eventData}
                  openDialog={openDialog}
                  openDialogEdit={openDialogEdit}
                  refreshEvents={props.getEvents}
                  courier={props.courier}
                  isAvaliablePeriodForEditOrCreateActivity={isAvaliablePeriodForEditOrCreateActivity}
                  openUnableModifyHoursDialog={openUnableModifyHoursDialog}
                  changeBlockUI={props.changeBlockUI}
                />
              )}
            </>
          ) : null}
        </>
      )}
      {/* ##########################################################################################################
                                  Dialog de actividad creada o nueva para editar
         ########################################################################################################### */}
      <>
        {activity && /*isNewEvent() &&*/ edit && (
          <ActivityDialogEdit
            projectOptions={projectOptions}
            projectContext={projectContext}
            system={props.system}
            user={props.user}
            activity={activity}
            commonContext={props.commonContext}
            onChangeActivity={onChangeActivity}
            onChangeDate={onChangeDate}
            onChangeDropdown={onChangeDropdown}
            updateActivity={updateActivity}
            showDialog={showDialogEdit}
            courier={props.courier}
            toggleShowDialog={() => {
              setShowDialogEdit(!showDialogEdit);
              setEdit(false);
            }}
            validations={validations}
            submitted={submitted}
            onSubmit={(v: boolean) => setSubmitted(v)}
            onSave={onSave}
            config={props.config}
          />
        )}

        {/* ##########################################################################################################
                                      Dialog de actividad, (informativa, no se puede editar)
         ########################################################################################################### */}
        {activity && !isNewEvent() && (
          <ActivityDialog
            commonContext={props.commonContext}
            refreshEvents={props.getEvents}
            activity={activity}
            system={props.system}
            user={props.user}
            courier={props.courier}
            showDialog={showDialog}
            isAvaliablePeriodForEditOrCreateActivity={isAvaliablePeriodForEditOrCreateActivity}
            toggleShowDialog={() => {
              setShowDialog(!showDialog);
              if (props.toggleShowDialog) props.toggleShowDialog();
            }}
            onEdit={onEdit}
            changeBlockUI={props.changeBlockUI}
            config={props.config}
            hideBtnEdit={props.hideBtnEdit}
            hideMenuOptions={props.hideMenuOptions}
          />
        )}
      </>

      <UnableModifyHoursDialog
        show={unableModifyHoursDialog}
        onClose={onCloseUnableModifyHoursDialog}
      ></UnableModifyHoursDialog>

      <ConfirmDuplicatedActivityDialog
        show={confirmDuplicatedActivityDialog}
        onClose={onCloseConfirmDuplicatedActivityDialog}
      ></ConfirmDuplicatedActivityDialog>
    </>
  );
};
