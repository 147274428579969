import DialogTemplate from '../../Common/DialogTemplate';
import ComboBoxSearch from '../../Common/ComboBoxSearch';
import { Text, Icon, TextField } from '@fluentui/react';
import { DateCustom } from '../../../lib/DateCustom';
import { styles } from '../CalendarStyles';
import { ActivityTeam } from './ActivityTeam';
import { ActivityDocs } from './ActivityDocs';
import { ActivityDistribution } from './ActivityDistribution';
import { Activity } from '../../../system/activity/Activity';
import TimeTaxSupportSystemClient from '../../../system';
import { DateSelector } from '../../Common/DateSelector';
import MessageCourier from '../../../lib/MessageCourier';
import { User } from '../../../system/User';
import { ActivityValidations } from './ActivityValidations';
import { ErrorIntermediate } from '../../Common/ErrorIntermediate';
import { useTranslation } from 'react-i18next';
import { IComboBoxOption } from '@fluentui/react';
import SystemConfiguration from '../../../system/configuration/SystemConfiguration';
import React from 'react';
import { CalendarEventChangeDateDialog } from '../CalendarEventChangeDateDialog';
import { getMinDate } from '../../../lib/DatePickerUtils';

interface IActivityDialogEdit {
  user: User;
  showDialog: boolean;
  toggleShowDialog: () => void;
  onChangeActivity: (e: any) => void;
  onChangeDate: (e: any) => void;
  onChangeDropdown: (name: string, option: any) => void;
  updateActivity: () => void;
  projectOptions: IComboBoxOption[];
  activity: Activity;
  commonContext: any;
  projectContext: any;
  system: TimeTaxSupportSystemClient;
  onSave: () => void;
  courier: MessageCourier;
  validations: ActivityValidations;
  submitted: boolean;
  onSubmit: (v: boolean) => void;
  config?: SystemConfiguration;
}

const ActivityDialogEdit = (props: IActivityDialogEdit) => {
  const { t } = useTranslation();
  const onChange = (e: any) => {
    props.onChangeActivity(e);
  };

  const [shouldShowChangeDateDialog, setShouldShowChangeDateDialog] = React.useState<boolean>(false);
  const [showChangeDateDialog, setShowChangeDateDialog] = React.useState<boolean>(false);

  const getSelectedKey = () => {
    let _project = props.activity.getProject();
    if (_project != null) {
      let selectedKey = _project.getId() != null ? _project.getId() : '';
      return [selectedKey];
    } else {
      return [];
    }
  };

  const userLoggedIsOwner = () => {
    return props.user.getCollaboratorId() === props.activity.getOwner_colid();
  };

  const startTimeSaved = React.useMemo(() => {
    return props.activity.getStart().getTime();
  }, []);
  const endTimeSaved = React.useMemo(() => {
    return props.activity.getEnd().getTime();
  }, []);
  const durationSaved = React.useMemo(() => {
    return props.activity.getHours();
  }, []);

  const getOptions = () => {
    if (props.activity.getId() && props.activity.getId() !== 'new') {
      const isInCurrentProjects = props.projectOptions.find((opt) => opt.key === getSelectedKey()[0]);
      if (isInCurrentProjects) {
        return props.projectOptions;
      } else {
        // este caso es especial por si el proyecto ya no está en vigencia
        const project = props.activity.getProject();
        const ret: IComboBoxOption[] = [
          { key: project ? project.getId() : '', text: project ? project.getTitleProject() : '' },
        ];
        return ret;
      }
    } else {
      return props.projectOptions;
    }
  };

  const onCloseChangeDateDialog = (confirm: boolean) => {
    if (confirm) {
      submit();
    }
    setShowChangeDateDialog(false);
  };

  const submit = () => {
    props.onSubmit(true);
    if (props.validations.all(props.activity, props.user)) props.onSave();
  };

  const hasBeenChangeDates = () => {
    return (
      props.activity.getStart().getTime() !== startTimeSaved ||
      props.activity.getEnd().getTime() !== endTimeSaved ||
      props.activity.getHours() !== durationSaved
    );
  };

  return (
    <>
      {props.activity && (
        <>
          <DialogTemplate
            showDialog={props.showDialog}
            toggleShowDialog={props.toggleShowDialog}
            title={props.activity.getName() ? props.activity.getName() : t('Crear una nueva actividad')}
            onAccept={() => {
              if (hasBeenChangeDates() && !props.activity.isNew()) {
                setShowChangeDateDialog(true);
              } else {
                submit();
              }
            }}
            onCancel={() => {
              props.toggleShowDialog();
            }}
            minWidth={600}
            // maxWidth={750}
            acceptText={t('Guardar')}
            cancelText={t('Cancelar')}
            blocking={true}
          >
            <div className="animation-height calendar-activity-scroll">
              <Text block variant="xLarge" className={styles.title}>
                <Icon iconName="AccountActivity" className="text-icon" />
                <TextField
                  className={styles.input}
                  placeholder={t('Nombre de la actividad')}
                  value={props.activity.getName() ? props.activity.getName() : ''}
                  name="name"
                  disabled={!userLoggedIsOwner()}
                  onChange={onChange}
                  underlined
                />
              </Text>
              <div className="ml-3">
                <ErrorIntermediate
                  validation={props.validations.name}
                  submitted={props.submitted}
                  params={props.activity.getName()}
                ></ErrorIntermediate>
              </div>

              <Text block variant="large">
                <Icon iconName="Teamwork" className={styles.iconComboBox} />
                <ComboBoxSearch
                  className={styles.inputDropdown}
                  options={getOptions()}
                  selected={getSelectedKey()}
                  onSelectOption={(option: IComboBoxOption) => props.onChangeDropdown('proid', option)}
                  disabled={props.activity.getId() && props.activity.getId() !== 'new' ? true : false}
                />
              </Text>

              <div className="ml-3">
                <ErrorIntermediate
                  validation={props.validations.project}
                  submitted={props.submitted}
                  params={props.activity.getProject()}
                ></ErrorIntermediate>
              </div>

              <div className={styles.dateSelector}>
                <DateSelector
                  defaultStartDate={props.activity.getStart()}
                  defaultDuration={props.activity.getHours()}
                  onChangeDuration={(valueDuration: any) => {
                    const durationInHours = DateCustom.convertMillisToHours(valueDuration);
                    props.activity.setHours(durationInHours);
                    props.activity.getCollaboratorActivityList().forEach((colact) => {
                      colact.setHoras(durationInHours);
                    });
                    props.updateActivity();
                  }}
                  disabled={!userLoggedIsOwner()}
                  onChangeStart={(valueStart) => {
                    props.activity.setStart(valueStart);
                    props.updateActivity();
                  }}
                  onChangeEnd={(valueEnd) => {
                    props.activity.setEnd(valueEnd);
                    props.updateActivity();
                  }}
                  minDate={getMinDate(props.config, props.activity)}
                  maxDate={
                    props.activity.getProject() ? props.activity.getProject()!.getEnd().toJSDate() : undefined
                  }
                />
                <div className="ml-3">
                  <ErrorIntermediate
                    validation={props.validations.duration}
                    submitted={props.submitted}
                    params={props.activity.getHours()}
                  ></ErrorIntermediate>{' '}
                </div>
                <div className="ml-for-error-msg-out-of-range">
                  <ErrorIntermediate
                    validation={props.validations.dateInRangeProject}
                    submitted={props.submitted}
                    params={{ project: props.activity.getProject(), start: props.activity.getStart() }}
                  ></ErrorIntermediate>
                </div>
              </div>

              <Text block variant="large">
                <Icon iconName="SearchCalendar" className="text-icon" />
                <TextField
                  placeholder={t('Añade una descripción (opcional)')}
                  multiline
                  rows={3}
                  className={styles.inputTextArea}
                  value={props.activity.getDescription() ? props.activity.getDescription() : ''}
                  name="description"
                  onChange={onChange}
                  disabled={!userLoggedIsOwner()}
                />
              </Text>
              {props.commonContext && (
                <div className="ms-motion-fadeIn">
                  <ActivityDocs
                    system={props.system}
                    document_types={props.commonContext.documentos_tipo}
                    edit={true}
                    activity={props.activity}
                    updateActivity={props.updateActivity}
                  ></ActivityDocs>
                  <ActivityTeam
                    colaborators={props.commonContext.colaboradores}
                    updateActivity={props.updateActivity}
                    system={props.system}
                    user={props.user}
                    edit={true}
                    activity={props.activity}
                    userLoggedIsOwner={userLoggedIsOwner()}
                    validations={props.validations}
                    submitted={props.submitted}
                  />
                  <ActivityDistribution
                    projectCompanies={props.commonContext.empresas}
                    updateActivity={props.updateActivity}
                    system={props.system}
                    edit={true}
                    activity={props.activity}
                    userLoggedIsOwner={userLoggedIsOwner()}
                    validations={props.validations}
                    submitted={props.submitted}
                    projectContext={props.projectContext}
                  ></ActivityDistribution>
                </div>
              )}
              <CalendarEventChangeDateDialog
                onClose={onCloseChangeDateDialog}
                show={showChangeDateDialog}
              ></CalendarEventChangeDateDialog>
            </div>
          </DialogTemplate>
        </>
      )}
    </>
  );
};

export default ActivityDialogEdit;
