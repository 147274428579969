import React from 'react';
import { User } from '../../system/User';
import CollaboratorPersona from '../Collaborator/CollaboratorPersona';

interface IUserPersonaProps {
  user: User;
  titlePrefix?: string;
  className?: string;
  small?: boolean;
  hidePersonaDetails?: boolean;
  onClick?: () => void;
}

const UserPersona = React.forwardRef<any, IUserPersonaProps>(({ user, ...props }, ref) => {
  return (
    <CollaboratorPersona
      collaborator={user.getCollaborator()}
      type={user.getProfile()}
      ref={ref}
      {...props}
    />
  );
});

export default UserPersona;
