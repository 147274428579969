import * as React from 'react';
import { Text, Icon, IComboBoxOption, IPersonaSharedProps } from '@fluentui/react';
import { styles } from '../CalendarStyles';
import { Activity } from '../../../system/activity/Activity';
import CollaboratorActivity from '../../../system/collaborators/collaborator_activity/CollaboratorActivity';
import { Collaborator } from '../../../system/collaborators/Collaborator';
import TimeTaxSupportSystemClient from '../../../system';
import CollaboratorPersona from '../../Collaborator/CollaboratorPersona';
import { User } from '../../../system/User';
import { ActivityValidations } from './ActivityValidations';
import { ErrorIntermediate } from '../../Common/ErrorIntermediate';
import { useTranslation } from 'react-i18next';
import ComboBoxSearch from '../../Common/ComboBoxSearch';

interface IActivityTeam {
  activity: Activity;
  edit: boolean;
  colaborators: Collaborator[];
  system: TimeTaxSupportSystemClient;
  user: User;
  updateActivity?: () => void;
  userLoggedIsOwner: boolean;
  validations?: ActivityValidations;
  submitted?: boolean;
}

export const ActivityTeam = (props: IActivityTeam) => {
  const { t } = useTranslation();
  const collaboratorOptions = React.useMemo(() => {
    return props.colaborators;
  }, [props.colaborators]);

  const [textDropDown, setTextDropDown] = React.useState<string>('');

  const refreshAvailableOptions = () => {
    let _collaboratorAvailableOptions: Collaborator[] = [];
    _collaboratorAvailableOptions = collaboratorOptions.filter((col) => {
      const isInTeam = props.activity
        .getCollaboratorActivityList()
        .find((colact) => colact.getCollaborator().isIdentifiedBy(col.getId()) && !colact.get__delete());
      if (!isInTeam) {
        return true;
      }
      return false;
    });

    setColaboratorAvailableOptions(_collaboratorAvailableOptions);
  };

  const onDeleteItem = (i: number) => {
    let _collaboratorActivityList = [...props.activity.getCollaboratorActivityList()];
    if (canDelete(_collaboratorActivityList[i])) {
      if (!_collaboratorActivityList[i].getId()) {
        _collaboratorActivityList.splice(i, 1);
      } else {
        _collaboratorActivityList[i].set__delete(true);
      }
      props.activity.setCollaboratorActivityList(_collaboratorActivityList);
      if (props.updateActivity) props.updateActivity();
      // refreshAvailableOptions();
    }
  };

  React.useEffect(() => {
    refreshAvailableOptions();
  }, [props.activity]);

  const canDelete = (colact: CollaboratorActivity) => {
    return !props.user.getCollaborator().isIdentifiedAs(colact.getCollaborator());
  };

  const [collaboratorOptionsAvailable, setColaboratorAvailableOptions] =
    React.useState<Collaborator[]>(collaboratorOptions);

  const handleChangeColaborator2 = (option?: IComboBoxOption | undefined) => {
    setTextDropDown('');
    if (option) {
      let _collaboratorActivityList = [...props.activity.getCollaboratorActivityList()];
      let col_id = option.key;
      const col = collaboratorOptions.find((col: Collaborator) => col.getId() === col_id);
      if (col) {
        let colToPush = CollaboratorActivity.getNewFromColaborador(
          props.system.getCollaboratorSystem(),
          col,
          props.activity.getHours()
        );
        _collaboratorActivityList.push(colToPush);
        props.activity.setCollaboratorActivityList(_collaboratorActivityList);
        if (props.updateActivity) props.updateActivity();
        // refreshAvailableOptions();
      }
    }
  };

  const isAconfirmedTag = (colact: CollaboratorActivity) => {
    return (
      (colact.getChecked() && colact.getDate_tag()) ||
      props.user.getCollaborator().isIdentifiedAs(colact.getCollaborator())
    );
  };

  return (
    <div id="team-section">
      {props.edit /*&& props.activity.isNew()*/ ? (
        <>
          <Text block variant="medium" className={styles.subtitleCard}>
            {t('Equipo')}
          </Text>

          <Text block variant="large" className={styles.dropdownDocsCont}>
            <Icon iconName="Group" className={styles.iconForText} />

            <ComboBoxSearch
              className={styles.inputDropdown}
              disabled={!props.userLoggedIsOwner}
              options={collaboratorOptionsAvailable.map((colOption: Collaborator) => {
                let ret: IComboBoxOption = {
                  key: colOption.getId(),
                  text: colOption.fullName(),
                };
                return ret;
              })}
              selected={['-1']}
              onSelectOption={(o) => handleChangeColaborator2(o)}
              cleanOnSelect={true}
            />
          </Text>

          {props.validations && (
            <div className="ml-3">
              <ErrorIntermediate
                validation={props.validations.team}
                submitted={!!props.submitted}
                params={props.activity.getCollaboratorActivityList()}
              ></ErrorIntermediate>
            </div>
          )}

          <Text>
            {props.activity.getCollaboratorActivityList() &&
              props.activity.getCollaboratorActivityList().map((colact: CollaboratorActivity, i) => {
                const PersonaData: IPersonaSharedProps = {
                  text: colact.getCollaborator().fullName(),
                };
                if (!colact.get__delete()) {
                  return (
                    <div key={i} className="mt-1">
                      <div className={styles.sectionText}>
                        <CollaboratorPersona
                          collaborator={colact.getCollaborator()}
                          usePresenseForActivity={true}
                          dateTag={colact.getDate_tag()}
                          confirmedActivity={colact.getChecked()}
                        ></CollaboratorPersona>
                      </div>
                      <div className={styles.sectionDelete}>
                        {props.userLoggedIsOwner && !isAconfirmedTag(colact) && (
                          <Icon
                            iconName="Trash"
                            className={styles.iconDelete}
                            onClick={() => onDeleteItem(i)}
                          />
                        )}
                      </div>
                    </div>
                  );
                }
              })}
          </Text>
        </>
      ) : (
        <div>
          <Text block variant="medium" className={styles.subtitleCard}>
            <Icon iconName="Group" className="text-icon" />
            {t('Equipo')}
          </Text>

          <Text>
            {props.activity.getCollaboratorActivityList() &&
              props.activity.getCollaboratorActivityList().map((colact: CollaboratorActivity, i) => {
                return (
                  <div key={i} className="mt-1">
                    <div className={styles.sectionText}>
                      <CollaboratorPersona
                        collaborator={colact.getCollaborator()}
                        usePresenseForActivity={true}
                        dateTag={colact.getDate_tag()}
                        confirmedActivity={colact.getChecked()}
                      ></CollaboratorPersona>
                    </div>
                  </div>
                );
              })}
          </Text>
        </div>
      )}
    </div>
  );
};
