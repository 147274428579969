export class Currency {
  constructor(
    private id: string,
    private denomination: string,
    private symbol: string,
    private convertionToDollarsRate: number
  ) {}

  static identified(id: string, denomination: string, symbol: string, convertionToDollarsRate: number) {
    return new this(id, denomination, symbol, convertionToDollarsRate);
  }
}
