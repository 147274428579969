import { DateTime } from 'luxon';
import LazyInitialization from '../../lib/LazyInitialization';
import { OrganizationChartNode } from '../organization_chart/OrganizationChartNode';
import { CollaboratorPosition } from './CollaboratorPosition';
import { CollaboratorPositionHelper } from './CollaboratorPositionHelper';
import { CollaboratorSystem, ICollaboratorPosition } from './CollaboratorSystem';
import { CompanyPosition } from './CompanyPosition';

export class ProvidedCollaboratorPosition implements CollaboratorPosition {
  //     return new this(id,
  //       new LazyInitialization(() => position),
  //       new LazyInitialization(() => organizationNode))
  //   }

  protected constructor(
    private id: string,
    private collaboratorId: string,
    private from: DateTime,
    private to: DateTime | null,
    private position: LazyInitialization<CompanyPosition>,
    private organizationNode: LazyInitialization<OrganizationChartNode>
  ) {}

  //   static with(
  //     id: string,
  // position: CompanyPosition,
  // organizationNode: OrganizationChartNode
  //   ) {
  //     return new this(id,
  //       new LazyInitialization(() => position),
  //       new LazyInitialization(() => organizationNode))
  //   }

  static fromICollaboratorPosition(
    system: CollaboratorSystem,
    collaboratorPosition: ICollaboratorPosition,
    nodes: OrganizationChartNode[] = []
  ) {
    const position = new LazyInitialization<CompanyPosition>(() => {
      if (!collaboratorPosition.cargo) {
        return system.getCompanyPositionIdentifiedBy(collaboratorPosition.carcol_cargoid);
      }
      return Promise.resolve(CompanyPosition.fromICompanyPosition(system, collaboratorPosition.cargo));
    });

    const node = new LazyInitialization<OrganizationChartNode>(() => {
      const nodeId = collaboratorPosition.carcol_norid;
      const possibleNode = nodes.find((n) => n.isIdentifiedBy(nodeId));
      if (possibleNode) return Promise.resolve(possibleNode);
      return system.getOrganizationChartSystem().getNode(nodeId);
    });

    const dateParser = system.getDateParser();
    return new this(
      collaboratorPosition.carcol_id,
      collaboratorPosition.carcol_colid,
      dateParser.parseUTC(collaboratorPosition.carcol_fecha_desde),
      collaboratorPosition.carcol_fecha_hasta
        ? dateParser.parseUTC(collaboratorPosition.carcol_fecha_hasta)
        : null,
      position,
      node
    );
  }

  getId(): string {
    return this.id;
  }

  getCollaboratorId(): string {
    return this.collaboratorId;
  }

  getFrom(): DateTime {
    return this.from;
  }
  getTo(): DateTime | null {
    return this.to;
  }

  getHelper(): CollaboratorPositionHelper {
    return CollaboratorPositionHelper.fromProvided(this);
  }

  async getPositionName() {
    const position = await this.position.value();

    return position.getName();
  }

  async getPosition() {
    const position = await this.position.value();

    return position;
  }

  getOrganizationNode(): Promise<OrganizationChartNode> {
    return this.organizationNode.value();
  }

  async nodeIs(node: OrganizationChartNode): Promise<boolean> {
    const collaboratorNode = await this.getOrganizationNode();
    return collaboratorNode.isIdentifiedAs(node);
  }

  async toICollaboratorPosition() {
    let _cargo = await this.getPosition();

    let _ICargo = CompanyPosition.toICompanyPosition(_cargo);
    let ret: ICollaboratorPosition = {
      cargo: _ICargo,
      carcol_colid: this.collaboratorId,
      carcol_id: this.id,
      carcol_cargoid: _ICargo.cargo_id,
      carcol_fecha_desde: this.from.toUTC().toISO()!,
      carcol_fecha_hasta: this.to ? this.to.toUTC().toISO() : null,
      carcol_activo: true,
      carcol_norid: (await this.getOrganizationNode()).getId(),
    };
    return ret;
  }
}
