import XhrRequestHandler from '../../lib/XhrRequestHandler';
import { CostPerHour, CostPerHourJson } from './CostPerHour';
import { CostPerHourFromExcelFormToPost } from '../../Components/Templates/Wizard/UploadTemplate';

export interface IPostCostPerHour {
  remun_sap_id: string;
  remun_mes: string;
  remun_anio: string;
  remun_costo_hora: string;
  remun_coef_valor_agr: string;
  remun_nombre_valor_agr: 'AVA' | 'BVA';
}

export interface ICostPerHour {
  rcp_id: string;
  rcp_periodo_anio: string;
  rcp_periodo_mes: string;
  rcp_costo_hora: string;
  rcp_colid: string;
  rcp_coef_valor_agr: string;
  rcp_nombre_valor_agr: string;
}

export interface ICostPerHourJson {
  remun_sap_id: string;
  remun_mes: string;
  remun_anio: string;
  remun_costo_hora: string;
  remun_coef_valor_agr: string;
  remun_nombre_valor_agr: 'AVA' | 'BVA';
}

class CostPerHourSystem {
  private readonly entityBase = '/remun-colab-periodo-pre-liquidacion';
  private readonly costPerHour = '/costos-por-hora';

  constructor(private requestHandler: XhrRequestHandler) {}

  getDownloadModelExcelURI() {
    let url = `${this.entityBase}/excel-model`;
    return `${this.requestHandler.requestUrl(url)}`;
  }

  async getCostsPerHour() {
    const CPH = await this.requestHandler.get<any[]>(`${this.entityBase}${this.costPerHour}`);
    // console.log(CPH)
    return CPH['data'].map((costPH) => CostPerHour.fromICostPerHour(costPH));
  }

  async saveFromExcelForm(
    costPerHour: CostPerHourFromExcelFormToPost[]
  ): Promise<CostPerHourFromExcelFormToPost[]> {
    const savedCostPerHourResult = await this.requestHandler.post<CostPerHourFromExcelFormToPost[], unknown>(
      `${this.entityBase}/import`,
      {
        costPerHour: costPerHour,
      }
    );
    return savedCostPerHourResult;
  }

  getBaseUrlForDatagrid() {
    return this.requestHandler.requestUrl(`${this.entityBase}/datagrid`);
  }

  async getCostPerHourById(id: string) {
    let ret = await this.requestHandler.get<CostPerHourJson>(`${this.entityBase}/${id}`);
    console.log(id);
    console.log(ret);
    return CostPerHour.fromJson(ret);
  }

  async save(fileType: CostPerHour) {
    const saveData = fileType.toJson();
    const savedCostResult = await this.requestHandler.post<ICostPerHour, ICostPerHour>(
      this.entityBase,
      saveData
    );
    // this.entityBase.reload()
    return CostPerHour.fromJson(savedCostResult);
  }

  async processMassiveRemun(list: IPostCostPerHour[]): Promise<any> {
    const saveData = list;
    const savedCostPerHourResult = await this.requestHandler.post<any, any>(
      this.entityBase + '/process-masive-remun',
      saveData
    );
    return savedCostPerHourResult;
  }

  //   async list(): Promise<CostPerHour[]> {
  //     const offices = await this.requestHandler.get<ICostPerHour[]>(this.entityBase);

  //     return offices.map((office) => CostPerHour.named(office.of_id, office.of_nombre));
  //   }
}

export default CostPerHourSystem;
