import * as React from 'react';
import { Text, Icon, ComboBox, IComboBoxOption, IComboBox } from '@fluentui/react';
import { styles } from '../CalendarStyles';
import { Activity } from '../../../system/activity/Activity';
import SystemDocumentType from '../../../system/documents/SystemDocumentType';
import { ActivityDocumentToSubmit } from '../../../system/documents/ActivityDocumentToSubmit';
import PlainFileDropzone from '../../FileDropzone/PlainFileDropzone';
import { ActivityDocument } from '../../../system/documents/ActivityDocument';
import TimeTaxSupportSystemClient from '../../../system';
import { useTranslation, Trans } from 'react-i18next';

interface IActivityDocs {
  system: TimeTaxSupportSystemClient;
  activity: Activity;
  edit: boolean;
  document_types: SystemDocumentType[];
  // system: TimeTaxSupportSystemClient;
  updateActivity?: () => void;
  onChangeActivityDocument?: (_documentActivityList: ActivityDocument[]) => void;
}

export const ActivityDocs = (props: IActivityDocs) => {
  const { t, i18n } = useTranslation();
  const [textDropDown, setTextDropDown] = React.useState<string>('');

  const onItemClick = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    index?: number | undefined
  ) => {
    setTextDropDown('');
    if (option) {
      let documentType = props.document_types.find((doct) => doct.getId() === option.key);
      let _documentActivityToSubmitList = props.activity.getDocumentActivityToSubmitList();
      if (documentType) {
        let actDocToSub = ActivityDocumentToSubmit.forType(documentType);
        _documentActivityToSubmitList.push(actDocToSub);
        props.activity.setDocumentActivityToSubmitList(_documentActivityToSubmitList);
        if (props.updateActivity) {
          props.updateActivity();
        }
      }
    }
  };

  const onDeleteItem = (i: number) => {
    let _documentActivityToSubmitList = props.activity.getDocumentActivityToSubmitList();
    _documentActivityToSubmitList.splice(i, 1);
    props.activity.setDocumentActivityToSubmitList(_documentActivityToSubmitList);
    if (props.updateActivity) {
      props.updateActivity();
    }
  };

  const documentOptions = React.useMemo(() => {
    if (props.edit) {
      return props.document_types.map((doct: SystemDocumentType) => {
        return {
          key: doct.getId(),
          text: doct.getName(),
        };
      });
    } else return [];
  }, [props.document_types]);

  const onDropFiles = async (document: ActivityDocumentToSubmit, files: File[]): Promise<void> => {
    files.forEach((file) => document.addFile(file));
    if (props.updateActivity) {
      props.updateActivity();
    }
  };

  const downloadDocument = async (documentActivity: ActivityDocument) => {
    const downloadUrl = await props.system.getDocumentSystem().downloadUrlOf(documentActivity);
    window.open(downloadUrl, '_blank');
  };

  React.useEffect(() => {}, [props]);

  return (
    <div id="docs-section">
      {props.edit && (
        <>
          <Text block variant="medium" className={styles.subtitleCard}>
            {t('Documentos')}
          </Text>

          <Text block variant="large" className={styles.dropdownDocsCont}>
            <Icon iconName="TextDocument" className={styles.iconForText} />
            <ComboBox
              placeholder={t('Tipo de documento')}
              options={documentOptions}
              className={styles.inputDropdown}
              text={textDropDown}
              // calloutProps={{ doNotLayer: true }}
              onItemClick={onItemClick}
            />
          </Text>

          <Text>
            {props.activity.getDocumentActivityToSubmitList().length > 0 &&
              props.activity.getDocumentActivityToSubmitList().map((document, i) => {
                const type = document.getTypeName();
                return (
                  <div key={i} className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm10">
                        <PlainFileDropzone onDrop={(files) => onDropFiles(document, files)} openOnClick>
                          <div className={`${styles.documentsSection}`}>
                            <div className={styles.sectionText}>
                              <Icon iconName="Attach" className={styles.iconForText} />({type}{' '}
                              {t('- Click aquí o Arrastra y suelta documentos')} )
                              {document.getFiles
                                ? document.getFiles().map((file, i) => {
                                    return i > 0 ? `, ${file.name}` : ` ${file.name}`;
                                  })
                                : t('(Click aquí o Arrastra y suelta documentos)')}
                            </div>
                          </div>
                        </PlainFileDropzone>
                      </div>
                      <div className={`ms-Grid-col ms-sm2 mt-1`}>
                        <Icon
                          iconName="Trash"
                          className={styles.iconDelete}
                          onClick={() => onDeleteItem(i)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            {props.activity.getDocumentActivityList().length > 0 &&
              props.activity.getDocumentActivityList().map((document, i: number) => {
                return (
                  <div key={i} onClick={() => downloadDocument(document)}>
                    <div className={styles.documentsSectionHover} key={i}>
                      <div className={styles.sectionText}>
                        <Icon iconName="Attach" className={styles.iconForText} />
                        {document.getName()}
                      </div>
                    </div>
                  </div>
                );
              })}

            {props.activity.getDocumentErrors().map((error, i) => {
              return (
                <div key={i}>
                  <div className={styles.documentsSectionHover} key={i}>
                    <div className={styles.sectionText}>
                      <Icon iconName="Attach" className={styles.iconForTextError} />
                      {error}
                    </div>
                  </div>
                </div>
              );
            })}
          </Text>
        </>
      )}
      {!props.edit && (
        <>
          <Text block variant="medium" className={styles.subtitleCard}>
            <Icon iconName="TextDocument" className="text-icon" />
            {t('Documentos')}
          </Text>
          {props.activity.getDocumentActivityList().length > 0 &&
            props.activity.getDocumentActivityList().map((document, i: number) => {
              return (
                <div key={i} onClick={() => downloadDocument(document)}>
                  <div className={styles.documentsSectionHover} key={i}>
                    <div className={styles.sectionText}>
                      <Icon iconName="Attach" className={styles.iconForText} />
                      {document.getName()}
                    </div>
                  </div>
                </div>
              );
            })}

          {props.activity.getDocumentErrors().map((error, i) => {
            return (
              <div key={i}>
                <div className={styles.documentsSectionHover} key={i}>
                  <div className={styles.sectionText}>
                    <Icon iconName="Attach" className={styles.iconForTextError} />
                    {error}
                  </div>
                </div>
              </div>
            );
          })}

          {props.activity.getDocumentActivityList().length === 0 &&
            props.activity.getDocumentErrors().length === 0 && (
              <Text block variant="small">
                {t('No hay documentos cargados')}
              </Text>
            )}
        </>
      )}
    </div>
  );
};
