import React from 'react';
import DialogTemplate from './Common/DialogTemplate';
import { Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useUserDevice } from '../common/providers/UserDeviceProvider';

const PWAInstallation = () => {
  const { t } = useTranslation();
  const userDevice = useUserDevice();
  const [deferredPrompt, setDeferredPrompt] = React.useState<BeforeInstallPromptEvent>();

  const handleBeforeInstallPrompt = React.useCallback(
    (e) => {
      e.preventDefault();
      if (userDevice.isMobile()) {
        setTimeout(() => {
          setDeferredPrompt(e);
        }, 10000);
      }
    },
    [userDevice]
  );

  React.useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [handleBeforeInstallPrompt]);

  const onInstall = () => {
    deferredPrompt!.prompt();
    deferredPrompt!.userChoice.finally(() => {
      setDeferredPrompt(undefined);
    });
  };

  return (
    <DialogTemplate
      showDialog={!!deferredPrompt}
      toggleShowDialog={() => {}}
      title={t('Instala la app')}
      onAccept={onInstall}
      onCancel={() => {
        setDeferredPrompt(undefined);
      }}
      acceptText={t('Instalar')}
      cancelText={t('Cancelar')}
      minWidth={600}
    >
      <div>
        <Text block variant="medium">
          {t('Instala nuestra app para una mejor experiencia!')}
        </Text>
      </div>
    </DialogTemplate>
  );
};
export default PWAInstallation;
