import { ActionButton } from '@fluentui/react';
import MessageCourier from '../../lib/MessageCourier';
import TimeTaxSupportSystemClient from '../../system';
import { styles } from './CalendarStyles';
import { IIconProps } from '@fluentui/react';
import { Activity } from '../../system/activity/Activity';
import { User } from '../../system/User';
import { useTranslation, Trans } from 'react-i18next';

interface ICalendarActivityCalloutFooter {
  refreshEvents: () => void;
  courier: MessageCourier;
  activity: Activity;
  system: TimeTaxSupportSystemClient;
  user: User;
  openDialog: () => void;
  openDialogEdit?: () => void;
  onClickDeleteActivity: () => void;
  onClickAcceptTag: () => void;
  onClickDeclineTag: () => void;
  isAvaliablePeriodForEditOrCreateActivity: boolean;
  openUnableModifyHoursDialog?: () => void;
  pendingTag: boolean;
  declined: boolean;
  checked: boolean;
  onClickChangeParticipation: (v: boolean) => void;
  changeParticipation: boolean;
}

export const CalendarActivityCalloutFooter = (props: ICalendarActivityCalloutFooter) => {
  const { t, i18n } = useTranslation();
  const fullScreenIcon: IIconProps = { iconName: 'FullScreen' };
  const editIcon: IIconProps = { iconName: 'Edit' };
  const replicateIcon: IIconProps = { iconName: 'RepeatAll' };
  const DeleteIcon: IIconProps = { iconName: 'Delete' };
  const EventAcceptIcon: IIconProps = { iconName: 'EventAccepted' };
  const EventDeclineIcon: IIconProps = { iconName: 'EventDeclined' };
  const RemoveFromTrashIcon: IIconProps = { iconName: 'RemoveFromTrash' };

  const reactivateActivity = async () => {
    try {
      await props.system.getActivitySystem().reactivate(props.activity);
      props.refreshEvents();
      props.courier.messageSuccess(t('La actividad se restauró de manera exitosa'));
    } catch (error) {
      props.courier.messageError(error);
    }
  };

  const canEdit = () => {
    return props.user.getCollaboratorId() === props.activity.getOwner_colid();
  };

  const onClickAcceptTagActivity = () => {
    if (props.isAvaliablePeriodForEditOrCreateActivity) {
      props.onClickAcceptTag();
    } else {
      if (props.openUnableModifyHoursDialog) props.openUnableModifyHoursDialog();
    }
  };

  const onClickDeclineTagActivity = () => {
    if (props.isAvaliablePeriodForEditOrCreateActivity) {
      props.onClickDeclineTag();
    } else {
      if (props.openUnableModifyHoursDialog) props.openUnableModifyHoursDialog();
    }
  };

  const onClickChangeParticipation = () => {
    props.onClickChangeParticipation(true);
  };

  return (
    <div className={styles.footer}>
      {/* Puede eliminar si es el dueño de la actividad */}
      {canEdit() ? (
        <div className="d-inline">
          <ActionButton
            className={styles.buttonControls}
            iconProps={fullScreenIcon}
            allowDisabledFocus
            onClick={() => props.openDialog()}
          >
            {t('Ver detalles')}
          </ActionButton>
          {!props.activity.isDeleted() ? (
            <ActionButton
              onClick={() => props.onClickDeleteActivity()}
              className={styles.secondButtonControl}
              iconProps={DeleteIcon}
              allowDisabledFocus
              disabled={!props.isAvaliablePeriodForEditOrCreateActivity}
            >
              {t('Eliminar')}
            </ActionButton>
          ) : (
            <ActionButton
              onClick={reactivateActivity}
              className={styles.secondButtonControl}
              iconProps={RemoveFromTrashIcon}
              allowDisabledFocus
              disabled={!props.isAvaliablePeriodForEditOrCreateActivity}
            >
              {t('Restaurar')}
            </ActionButton>
          )}
          <ActionButton
            className={styles.buttonControls}
            iconProps={editIcon}
            allowDisabledFocus
            onClick={() => {
              if (props.openDialogEdit) props.openDialogEdit();
            }}
          >
            {t('Editar')}
          </ActionButton>
        </div>
      ) : (
        <div className="d-inline">
          {/*En el caso de que el usuario loggeado esté pendiente de tag, acepta o declina la actividad*/}
          {(props.pendingTag || props.changeParticipation) && (
            <div className="d-inline">
              <ActionButton
                onClick={() => onClickAcceptTagActivity()}
                className={styles.secondButtonControl}
                iconProps={EventAcceptIcon}
                allowDisabledFocus
              >
                {t('Aceptar')}
              </ActionButton>
              <ActionButton
                onClick={() => onClickDeclineTagActivity()}
                className={styles.secondButtonControl}
                iconProps={EventDeclineIcon}
                allowDisabledFocus
                // disabled={!props.isAvaliablePeriodForEditOrCreateActivity}
              >
                {t('Declinar')}
              </ActionButton>
            </div>
          )}

          {props.declined && !props.changeParticipation && (
            <div className="d-inline">
              <ActionButton
                onClick={() => onClickChangeParticipation()}
                className={styles.secondButtonControl}
                iconProps={editIcon}
                allowDisabledFocus
                // disabled={!props.isAvaliablePeriodForEditOrCreateActivity}
              >
                {t('Editar participación')}
              </ActionButton>
            </div>
          )}

          {!props.pendingTag && props.checked && !props.changeParticipation && (
            <div className="d-inline">
              <ActionButton
                className={styles.buttonControls}
                iconProps={fullScreenIcon}
                allowDisabledFocus
                onClick={() => props.openDialog()}
              >
                {t('Ver detalles')}
              </ActionButton>
              <ActionButton
                onClick={() => onClickChangeParticipation()}
                className={styles.secondButtonControl}
                iconProps={editIcon}
                allowDisabledFocus
                // disabled={!props.isAvaliablePeriodForEditOrCreateActivity}
              >
                {t('Editar participación')}
              </ActionButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
