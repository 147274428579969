class Environment {
  ttsBaseApiUri() {
    return `${this.ttsBaseUri()}${this.ttsApiRelativeUri()}`;
  }

  apiUrl(path: string) {
    const base = this.ttsBaseApiUri();
    return `${base}${path}`;
  }

  ttsBaseUri() {
    return this.retrieveRequired('REACT_APP_TTS_BASE_URL');
  }

  enableMMI() {
    return this.retrieveRequired('REACT_APP_TTS_ENABLE_MMI') === '1';
  }

  enableDocumentClassification() {
    return this.retrieveRequired('REACT_APP_TTS_ENABLE_DOCUMENT_CLASSIFICATION') === '1';
  }

  private ttsApiRelativeUri() {
    return this.retrieveRequired('REACT_APP_TTS_API_RELATIVE_URL');
  }

  private retrieveRequired(key: string) {
    const value = process.env[key];
    if (value === undefined) {
      throw new Error(`${key} is not defined`);
    }
    return value;
  }
}

export default Environment;
