import { Collaborator } from './collaborators/Collaborator';
import { UserProfile } from './UserProfile';
import { IUser } from './AuthenticationSystem';

export class User {
  protected constructor(
    private collaborator: Collaborator,
    private profile: UserProfile,
    private id: string,
    private name: string,
    private email: string
  ) {}

  static fromIUser(collaborator: Collaborator, profile: UserProfile, user: IUser) {
    return new this(collaborator, profile, user.us_id, user.us_nombre, user.us_email);
  }

  referencesCollaborator(collaborator: Collaborator): boolean {
    return this.collaborator.isIdentifiedAs(collaborator);
  }

  getCollaboratorId() {
    return this.collaborator.getId();
  }

  getCollaborator(): Collaborator {
    return this.collaborator;
  }

  isStandardUser(): boolean {
    return this.profile.isStandardUser();
  }

  isDirectiveUser(): boolean {
    return this.profile.isDirectiveUser();
  }

  isPowerUser(): boolean {
    return this.profile.isPowerUser();
  }

  isAdminUser(): boolean {
    return this.profile.isAdminUser();
  }

  hasPowerUserPrivileges() {
    return this.isPowerUser() || this.isAdminUser();
  }

  getProfile(): UserProfile {
    return this.profile;
  }
}
