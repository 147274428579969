import React from 'react';
import { Persona, PersonaPresence, PersonaSize } from '@fluentui/react/lib/Persona';

import { Collaborator } from '../../system/collaborators/Collaborator';
import useAsyncData from '../../common/custom-hooks/useAsyncData';
import { UserProfile } from '../../system/UserProfile';

interface ICollaboratorPersonaProps {
  collaborator: Collaborator;
  titlePrefix?: string;
  className?: string;
  small?: boolean;
  onClick?: () => void;
  usePresenseForActivity?: boolean;
  confirmedActivity?: boolean;
  dateTag?: string | Date;
  type?: UserProfile;
  hidePersonaDetails?: boolean;
}

const CollaboratorPersona = React.forwardRef<any, ICollaboratorPersonaProps>(
  ({ collaborator, type, ...props }, ref) => {
    const positionName = useAsyncData(() => collaborator.getPositionName(), [collaborator]);

    const getPresence = () => {
      if (props.confirmedActivity) {
        return PersonaPresence.online;
      }
      if (!props.confirmedActivity && props.dateTag) {
        return PersonaPresence.busy;
      }
      return PersonaPresence.away;
    };

    return (
      <Persona
        onRenderPrimaryText={() => (
          <div>
            <div>{collaborator.fullName()}</div>
            <span style={{ fontSize: '9px', position: 'absolute', bottom: '-3px' }}>
              {type ? type.getName() : null}
            </span>
          </div>
        )}
        text={collaborator.fullName()}
        ref={ref}
        secondaryText={positionName}
        // tertiaryText={'admin'}
        presence={props.usePresenseForActivity ? getPresence() : undefined}
        size={props.small ? PersonaSize.size24 : PersonaSize.size32}
        className={props.className}
        title={`${props.titlePrefix || ''} ${collaborator.fullName()}`}
        onClick={props.onClick}
        hidePersonaDetails={props.hidePersonaDetails}
      />
    );
  }
);

export default CollaboratorPersona;
