import { TActividadEvent } from '../../classes/Calendar/CalendarEvent';
import Utils from '../Common/Utils';

class TTSEvent {
  isNewEvent(event: TActividadEvent): boolean {
    return (
      event.extendedProps &&
      event.extendedProps.data &&
      (!event.extendedProps.data.id || isNaN(+event.extendedProps.data.id)) &&
      (!event.extendedProps.data.isExternal || this.isNewEventExternal(event)) &&
      !this.isLicenseEvent(event) &&
      !event.extendedProps.data.isNonWorkingDay
    );
  }

  isLicenseEvent(event: TActividadEvent): boolean | undefined {
    return event.extendedProps.data.isLicenseDay;
  }

  isNewEventExternal(event: TActividadEvent): boolean {
    return event.extendedProps.data.isExternal && event.extendedProps.data.isNewFromExternal;
  }

  getDuraciones = () => {
    let durationOptions: any[] = [];
    let duracion = 15;
    while (duracion <= 60 * 8) {
      durationOptions.push({ key: duracion, text: Utils.getLabelForDuracion(duracion) });
      duracion += 15;
    }
    return durationOptions;
  };

  private roundMinutes = (date: Date) => {
    let esPasadaMediaHora = Math.round(date.getMinutes() / 60);

    date.setHours(date.getHours());

    if (esPasadaMediaHora === 1) {
      date.setMinutes(30, 0, 0);
    } else {
      date.setMinutes(0, 0, 0);
    }
    return date;
  };

  roundHoraInicial = () => {
    var date = new Date(); // 4:55
    return this.roundMinutes(date); // 5:00
  };
}

export default TTSEvent;
