import { Icon, Text } from '@fluentui/react';
import React from 'react';
import DialogTemplate from '../Common/DialogTemplate';
import { useTranslation, Trans } from 'react-i18next';

export interface InsufficientpermissionsDialogProps {
  show: boolean;
  onClose: (accept: boolean) => void;
}

export const InsufficientpermissionsDialog = (props: InsufficientpermissionsDialogProps) => {
  const { t, i18n } = useTranslation();

  const cancel = () => {
    props.onClose(false);

  };

  const accept = () => {
    props.onClose(true);
  };

  return (
    <DialogTemplate
      showDialog={props.show}
      toggleShowDialog={() => props.onClose(false)}
      title={t('Permisos insuficientes')}
      onAccept={accept}
      onCancel={cancel}
      acceptText={t('Ir al inicio')}
      cancelText={t('Cancel')}
      minWidth={600}
      hideCancelButton={true}
    >
      <div>
        <Trans>
          <Text block variant="medium">
            <Icon iconName="Warning" className="text-icon" />
            {t("Lo sentimos, pero no tienes los permisos suficientes para ver el contenido de esta página")}
          </Text>
        </Trans>
      </div>
    </DialogTemplate>
  );
};
