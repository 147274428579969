import React from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { Text } from '@fluentui/react';

import DialogTemplate from './Components/Common/DialogTemplate';

class StandardErrorBoundary extends React.Component<
  { translate: TFunction<'translation', undefined> },
  { hasError: boolean; error: any }
> {
  constructor(props) {
    super(props);

    // to keep track of when an error occurs
    // and the error itself
    this.state = {
      hasError: false,
      error: undefined,
    };
  }

  // update the component state when an error occurs
  static getDerivedStateFromError(error) {
    // specify that the error boundary has caught an error
    return {
      hasError: true,
      error: error,
    };
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', (e) => this.promiseRejectionHandler(e));
  }

  promiseRejectionHandler(e: PromiseRejectionEvent) {
    console.log('reason :>> ', e.reason);
    this.setState(StandardErrorBoundary.getDerivedStateFromError(e.reason));
  }

  // defines what to do when an error gets caught
  componentDidCatch(error, errorInfo) {
    // log the error
    console.log('Error caught!');
    console.error(error);
    console.error(errorInfo);
  }

  render() {
    const t = this.props.translate;

    // if an error occurred
    if (this.state.hasError) {
      return (
        <DialogTemplate
          showDialog={true}
          toggleShowDialog={() => {}}
          title={t('Error')}
          onAccept={() => {
            window.location.reload();
          }}
          hideCancelButton={true}
          onCancel={() => {}}
          acceptText={t('Recargar')}
          cancelText={t('')}
          blocking={false}
          draggable={false}
          minWidth={600}
        >
          <div className="align-center">
            <div className="mt-2">
              <Text variant="large" className="fw-500 d-block text-center">
                {t('Hubo un error')}
              </Text>
              <Text variant="medium" className="d-block mt-2 mb-2">
                {t('Por favor, intenta recargar la página')}
              </Text>

              <hr />
            </div>
          </div>
        </DialogTemplate>
      );
    }

    return this.props.children;
  }
}

const ErrorBoundaryWrapper = (props) => {
  const { t } = useTranslation();

  return <StandardErrorBoundary translate={t}>{props.children}</StandardErrorBoundary>;
};

export default ErrorBoundaryWrapper;
