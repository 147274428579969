import XhrRequestHandler, { XhrRequestError } from '../../lib/XhrRequestHandler';
import FileHasher from './FileHasher';
import { FileToUpload } from './FileToUpload';

export const uploadTypeProject = 'proyecto';
export const uploadTypeActivity = 'actividad';
export const uploadTypeSettlement = 'liquidacionIndirecta';
export const uploadItemProjectType = 'proyecto';
export const uploadItemActivityType = 'actividad';
export const uploadItemSettlmentType = 'liquidacionIndirecta';

interface IUploadDataItem {
  cmdi_id: string;
  cmdi_cmdid: string;
  cmdi_aws_bucket: string;
  cmdi_aws_key: string;
  cmdi_doc_uuid: string;
  cmdi_content_type: string;
}

export interface IUploadData {
  cargaDocumentosItems: IUploadDataItem[];
}

interface IUploadDataBaseNew {
  cmd_cant_archivos: number;
  cmd_cant_bytes_total: number;
  cmd_tipo: typeof uploadTypeProject | typeof uploadTypeActivity | typeof uploadTypeSettlement;
}

interface IUploadDataItemBaseNew {
  cmdi_cant_bytes: number;
  cmdi_nombre_archivo: string;
  cmdi_doc_uuid: string;
  cmdi_content_type: string;
  doctId: string;
}

interface IUploadProjectDataItemNew extends IUploadDataItemBaseNew {
  tipo: typeof uploadItemProjectType;
}

interface IUploadActivityDataItemNew extends IUploadDataItemBaseNew {
  actId: string;
  tipo: typeof uploadItemActivityType;
}

interface IUploadSettlementDataItemNew extends IUploadDataItemBaseNew {
  cmdi_dxliid: string;
  tipo: typeof uploadItemSettlmentType;
}

export interface IUploadProjectDataNew extends IUploadDataBaseNew {
  cmd_proid: string;
  folderId?: string;
  cargaDocumentosItems: IUploadProjectDataItemNew[];
}

export interface IUploadSettlementDataNew extends IUploadDataBaseNew {
  cmd_liqid: string;
  cargaDocumentosItems: IUploadSettlementDataItemNew[];
}

export interface IUploadActivityDataNew extends IUploadDataBaseNew {
  cmd_actid: string;
  cargaDocumentosItems: IUploadActivityDataItemNew[];
}

export type IUploadDataNew = IUploadProjectDataNew | IUploadActivityDataNew | IUploadSettlementDataNew;

export type FileUploadTracker = (file: File, loaded: number, total: number) => unknown;

interface IUploadFilesData {
  files: FileToUpload[];
  uploadData: IUploadDataNew;
}

export interface IFilesUploadTracker {
  onPreparingUpload: () => void;
  onStartUpload: (files: File[], abortController: AbortController) => void;
  /**
   * @TODO ver de separar en casos de error o no
   */
  onEndUpload: () => void;
  onFileProgress: (file: File, loaded: number, total: number) => void;
  onFileUploadError: (file: File) => void;
}

class DocumentsUploader {
  private readonly uploadEntityBase = '/carga-documentos';
  private readonly itemUploadEntityBase = '/carga-documentos-items';

  private filesUploadTrackers: IFilesUploadTracker[];
  private uploadQueue: IUploadFilesData[];
  private uploading: boolean;

  private constructor(private requestHandler: XhrRequestHandler) {
    this.filesUploadTrackers = [];
    this.uploadQueue = [];
    this.uploading = false;
  }

  static start(requestHandler: XhrRequestHandler) {
    return new this(requestHandler);
  }

  registerFileUploadTracker(tracker: IFilesUploadTracker) {
    this.filesUploadTrackers.push(tracker);
  }

  informPreparingUpload() {
    this.filesUploadTrackers.forEach((tracker) => tracker.onPreparingUpload());
  }

  upload(files: FileToUpload[], uploadData: IUploadDataNew) {
    if (files.length === 0) throw new Error('No hay archivos para cargar');
    this.uploadQueue.push({ files, uploadData });
    if (!this.uploading) {
      return this.processNextInQueue();
    }
  }

  private processNextInQueue(): Promise<void> {
    if (this.uploadQueue.length === 0) return Promise.resolve();
    this.uploading = true;
    const { files, uploadData } = this.uploadQueue.shift()!;

    return this.process(files, uploadData).finally(() => {
      this.uploading = false;
      this.processNextInQueue();
    });
  }

  private async process(files: FileToUpload[], uploadData: IUploadDataNew) {
    const data = await this.generateUploadItems(uploadData);
    const abortController = new AbortController();

    const uploadPromises: Promise<void>[] = [];
    data.cargaDocumentosItems.forEach((item) => {
      const file = this.fileByUploadItem(files, item);
      uploadPromises.push(this.uploadFileFromItem(file, item, abortController));
    });

    this.informStartToTrackers(files, abortController);

    try {
      await Promise.all(uploadPromises);
    } catch (e) {
      console.error('uploadError :>>', e);
    } finally {
      this.informEndToTrackers();
    }
  }

  private async uploadFileFromItem(file: File, item: IUploadDataItem, abortController: AbortController) {
    const hash = await FileHasher.for(file);
    const url = await this.uploadUrl(item, hash);
    const config = {
      uploadTracker: (loaded: number, total: number) => {
        this.informProgressToTrackers(file, loaded, total);
      },
      abortController,
      headers: {
        'Content-MD5': hash,
        'Content-Type': file.type,
      },
    };

    try {
      // await Waiter.waitFor(5000);
      await this.requestHandler.unauthenticatedPutForUpload(url, file, config);
      await this.informSuccessfulUpload(item);
    } catch (e) {
      if (e instanceof XhrRequestError && e.isCancel()) {
        await this.informCancelUpload(item);
      } else {
        let msg = undefined;
        if (e instanceof XhrRequestError) {
          const requestData = e.responseData();
          console.log('requestData :>> ', requestData);
        }
        await this.informErrorUpload(item, msg);
      }
      this.informFileErrorToTrackers(file);
    }
  }

  private informFileErrorToTrackers(file: File) {
    this.filesUploadTrackers.forEach((tracker) => tracker.onFileUploadError(file));
  }

  private informProgressToTrackers(file: File, loaded: number, total: number) {
    this.filesUploadTrackers.forEach((tracker) => tracker.onFileProgress(file, loaded, total));
  }

  private informEndToTrackers() {
    this.filesUploadTrackers.forEach((tracker) => tracker.onEndUpload());
  }

  private informStartToTrackers(filesToUpload: FileToUpload[], abortController: AbortController) {
    const newLocal = filesToUpload.map((f) => f.getFile());
    this.filesUploadTrackers.forEach((tracker) => tracker.onStartUpload(newLocal, abortController));
  }

  private async uploadUrl({ cmdi_id }: IUploadDataItem, fileHash: string) {
    const response = await this.requestHandler.get<{ url: string }>(
      `${this.itemUploadEntityBase}/temporal-upload-url/${cmdi_id}?hash=${encodeURIComponent(fileHash)}`
    );

    return response.url;
  }

  private informSuccessfulUpload({ cmdi_id }: IUploadDataItem) {
    return this.requestHandler.post(`${this.itemUploadEntityBase}/inform-upload/${cmdi_id}`);
  }

  private informErrorUpload({ cmdi_id }: IUploadDataItem, msg?: string) {
    const data = msg ? { msg } : undefined;
    return this.requestHandler.post<unknown, { msg?: string }>(
      `${this.itemUploadEntityBase}/inform-error/${cmdi_id}`,
      data
    );
  }

  private informCancelUpload({ cmdi_id }: IUploadDataItem, msg?: string) {
    const data = msg ? { msg } : undefined;
    return this.requestHandler.post<unknown, { msg?: string }>(
      `${this.itemUploadEntityBase}/inform-cancel/${cmdi_id}`,
      data
    );
  }

  private async generateUploadItems(uploadData: IUploadDataNew) {
    const data = await this.requestHandler.post<IUploadData, IUploadDataNew>(
      `${this.uploadEntityBase}`,
      uploadData
    );

    if (!data.cargaDocumentosItems || data.cargaDocumentosItems.length === 0) {
      throw new Error('Hubo un error al intentar cargar el archivo');
    }

    return data;
  }

  private fileByUploadItem(files: FileToUpload[], item: IUploadDataItem): File {
    const fileReference = files.find((file) => file.isIdentifiedBy(item.cmdi_doc_uuid));
    if (!fileReference) throw new Error('Hubo un error a recuperar el archivo');
    return fileReference.getFile();
  }
}

export default DocumentsUploader;
