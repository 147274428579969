import React, { lazy, Suspense } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  routes,
  routesAvailableForDirective,
  routesAvailableForPowerUser,
  routesAvailableForStandardUser,
} from './common/constants';

import Base from './Components/Layout/Base';
import BasicBlockUI from './Components/Common/BasicBlockUI';
import { InsufficientpermissionsDialog } from './Components/Common/InsufficientpermissionsDialog';

import Home from './Components/Home';

import { t } from 'i18next';
import useAsyncDataFull from './common/custom-hooks/useAsyncDataFull';
import TimeTaxSupportSystemClient from './system/TimeTaxSupportSystemClient';
import { User } from './system/User';
import Environment from './lib/Environment';
import MessageCourier from './lib/MessageCourier';
import SystemConfiguration from './system/configuration/SystemConfiguration';
import PeriodDatesBlockedSystem from './system/period_dates_blocked/PeriodDatesBlockedSystem';

const CalendarView = lazy(() => import('./Components/Calendar/CalendarView'));
const Tutorial = lazy(() => import('./Components/Tutorial'));
const NotFound = lazy(() => import('./Components/NotFound'));
const ActivityView = lazy(() => import('./Components/Activity/ActivityView'));
const CompanyTreeView = lazy(() => import('./Components/CompanyTree/CompanyTreeView'));
const ProjectView = lazy(() => import('./Components/Proyectos/ProjectView'));
const ProjectConfiguration = lazy(
  () => import('./Components/Proyectos/ProjectConfiguration/ProjectConfiguration')
);
const DefenseFileContainer = lazy(() => import('./Components/Wizard/Archivos-Defensa/DefenseFileContainer'));
const ProjectList = lazy(() => import('./Components/Proyectos/List/ProjectList'));
const ActivityList = lazy(() => import('./Components/Activity/ActivityList'));
const ArchivosDefensaList = lazy(
  () => import('./Components/Archivos-defensa/Defense-file/ArchivosDefensaList')
);
const ArchivosDefensaView = lazy(
  () => import('./Components/Archivos-defensa/Defense-file/ArchivosDefensaView')
);
const SettlementsList = lazy(() => import('./Components/Settlements/Indirect/SettlementsList'));
const SettlementsView = lazy(() => import('./Components/Settlements/Indirect/SettlementsView'));
const CostPerHourList = lazy(() => import('./Components/Templates/CostPerHourList'));
const CostPerHourView = lazy(() => import('./Components/Templates/CostPerHourView'));
const DocumentList = lazy(() => import('./Components/Document/List/DocumentList'));
const NotificationList = lazy(() => import('./Components/Notifications/List/NotificationList'));
const PanelProjectsProgress = lazy(
  () => import('./Components/Panel/PanelProjectsProgress/PanelProjectsProgress')
);
const PanelCollaborators = lazy(() => import('./Components/Panel/PanelCollaborators/PanelCollaborators'));
const BlockPeriods = lazy(() => import('./Components/BlockPeriods/BlockPeriods'));
const BasicView = lazy(() => import('./Components/Layout/BasicView'));
const CollaboratorList = lazy(() => import('./Components/Collaborator/CollaboratorList'));
const CollaboratorView = lazy(() => import('./Components/Collaborator/CollaboratorView/CollaboratorView'));
const ProjectPanel = lazy(() => import('./Components/Proyectos/ProjectPanel/ProjectPanel'));
const ProjectRequiredDocument = lazy(
  () => import('./Components/Proyectos/ProjectRequiredDocument/ProjectRequiredDocument')
);
const ControlDocument = lazy(() => import('./Components/ControlDocument/ControlDocument'));
const PanelCompanies = lazy(() => import('./Components/Panel/PanelCompanies/PanelCompanies'));
const MassiveActivitiesUploadAssistant = lazy(
  () => import('./Components/MassiveActivitiesUploadAssistant/MassiveActivitiesUploadAssistant')
);
const MassiveActivitiesChecker = lazy(
  () => import('./Components/MassiveActivitiesChecker/MassiveActivitiesChecker')
);
const MassiveDistributionCorrector = lazy(
  () => import('./Components/MassiveDistributionCorrector/MassiveDistributionCorrector')
);
const SettlementDetail = lazy(() => import('./Components/Settlements/Indirect/SettlementDetail'));
const DirectSettlementDetail = lazy(() => import('./Components/Settlements/Direct/DirectSettlementDetail'));
const ReportingSettlementHoursCompaniesDE = lazy(
  () => import('./Components/Reporting/ReportingSettlementHoursCompaniesDE')
);
const ReportingSettlementHoursSectorDE = lazy(
  () => import('./Components/Reporting/ReportingSettlementHoursSectorDE')
);
const ReportingHoursProjects = lazy(() => import('./Components/Reporting/ReportingHoursProjects'));
const ReportingSettlementPreliquidation = lazy(
  () => import('./Components/Reporting/ReportingSettlementPreliquidation')
);
const PanelOwn = lazy(() => import('./Components/Panel/PanelOwn/PanelOwn'));
const Maintenance = lazy(() => import('./Components/Maintenance/Maintenance'));
const DirectSettlementsView = lazy(() => import('./Components/Settlements/Direct/DirectSettlementsView'));
const DirectSettlementsList = lazy(() => import('./Components/Settlements/Direct/DirectSettlementsList'));
const MassiveDefenseList = lazy(
  () => import('./Components/Archivos-defensa/Massive-Defense/MassiveDefenseList')
);
const MailList = lazy(() => import('./Components/Mail/List/MailList'));
const MassiveDefenseDetail = lazy(
  () => import('./Components/Archivos-defensa/Massive-Defense/MassiveDefenseDetail')
);
const MassiveDefenseView = lazy(
  () => import('./Components/Archivos-defensa/Massive-Defense/MassiveDefenseView')
);
const LicensesView = lazy(() => import('./Components/Licenses/LicensesView'));
const LicensesList = lazy(() => import('./Components/Licenses/LicensesList'));
const LicensesDetail = lazy(() => import('./Components/Licenses/LicensesDetail'));
const ProjectMassiveMailInspector = lazy(
  () => import('./Components/Proyectos/ProjectMassiveMailInspector/ProjectMassiveMailInspector')
);
const ReportingDocsControl = lazy(() => import('./Components/Reporting/ReportingDocsControl'));
const ReportingMMI = lazy(() => import('./Components/Reporting/ReportingMMI'));
const CostPerHourDetail = lazy(() => import('./Components/Templates/CostPerHourDetail'));
const ProjectDistributionByPeriodWizard = lazy(
  () => import('./Components/Proyectos/ProjectDistributionByPeriod/ProjectDistributionByPeriodWizard')
);
const ProjectDistributionByPeriodViewer = lazy(
  () => import('./Components/Proyectos/ProjectDistributionByPeriod/ProjectDistributionByPeriodViewer')
);
const ReportingBillableDistribution = lazy(
  () => import('./Components/Reporting/ReportingBillableDistribution')
);
// TODO: SACAR ESTOS COMPONENTES
const BasicInputsView = lazy(() => import('./Components/Common/BasicInputsView'));
const FormExample = lazy(() => import('./Components/Common/FormExample'));

interface IRouterContextProps {
  system: TimeTaxSupportSystemClient;
  environment: Environment;
  courier: MessageCourier;
}

const defaultConfig = SystemConfiguration.default();
const defaultPeriods = PeriodDatesBlockedSystem.default();
let timeout: any = null;

const AppInRouterContext = (props: IRouterContextProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [block, setBlock] = React.useState(false);
  const [showInsufficentPermissionsDialog, setShowInsufficentPermissionsDialog] = React.useState(false);
  const [us, setUs] = React.useState<User>();
  const [config, setConfig] = React.useState<SystemConfiguration>(defaultConfig);
  const [periodsBlocked, setPeriodsBlocked] = React.useState<PeriodDatesBlockedSystem>(defaultPeriods);

  const changeBlockUI = (shouldBlock: boolean) => {
    setBlock(shouldBlock);
  };

  const { data, loaded, loading } = useAsyncDataFull(
    () => props.system.loggedInUser(),
    [props.system], // location.pathname
    undefined,
    changeBlockUI
  );

  const loadedUser = React.useMemo(() => {
    return loaded && !loading;
  }, [loaded, loading]);

  const getConfig = React.useCallback(async () => {
    const c = await props.system.configuration();
    setConfig(c);
  }, [props.system]);

  const getPeriodsBlocked = React.useCallback(async () => {
    const p = await props.system.periodsBlocked();
    setPeriodsBlocked(PeriodDatesBlockedSystem.build(p));
  }, [props.system]);

  React.useEffect(() => {
    if (loadedUser) {
      setUs(data);
      if (data) {
        getConfig();
        getPeriodsBlocked();
      }
    }
  }, [data, loadedUser, getConfig, getPeriodsBlocked]);

  React.useEffect(() => {
    showInsufficentPermissions(location.pathname);
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  }, [us, loadedUser, navigate, location.pathname]);

  // React.useEffect(() => {
  //   if (us && !(us.getProfile().isPowerUser() || us.getProfile().isAdminUser())) {
  //     props.system.signOut('');
  //   }
  // }, [us]);

  const showInsufficentPermissions = (pathname: string) => {
    let _pathname = pathname.split('/')[1];
    if (_pathname)
      if (_pathname && _pathname !== '/') {
        if (us) {
          let isAvailableRoute = false;

          if (us.getProfile().isStandardUser()) {
            isAvailableRoute = !!routesAvailableForStandardUser.find((route) => {
              return getRouteName(route) === _pathname;
            });
          }

          if (us.getProfile().isDirectiveUser()) {
            isAvailableRoute = !!routesAvailableForDirective.find((route) => {
              return getRouteName(route) === _pathname;
            });
          }

          if (us.getProfile().isPowerUser() || us.getProfile().isAdminUser()) {
            isAvailableRoute = !!routesAvailableForPowerUser.find((route) => {
              return getRouteName(route) === _pathname;
            });
          }

          if (!isAvailableRoute) {
            setShowInsufficentPermissionsDialog(true);
          }
        }
      }
  };

  const onCloseInsufficentPermissionsDialog = () => {
    setShowInsufficentPermissionsDialog(false);
    navigate(routes.calendar);
  };

  const getRouteName = (route: string) => {
    let s = route;
    if (s[0] === '/') {
      s = route.substring(1);
    }
    return s;
  };

  const getVideoName = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('video');
  };

  return (
    <>
      <Suspense fallback={<BasicBlockUI blockUI={true} />}>
        <BasicBlockUI blockUI={block} />
        <InsufficientpermissionsDialog
          onClose={onCloseInsufficentPermissionsDialog}
          show={showInsufficentPermissionsDialog}
        />

        <Routes>
          {us && (
            <Route
              path="/"
              element={
                <Base
                  courier={props.courier}
                  system={props.system}
                  user={us}
                  changeBlockUI={changeBlockUI}
                  config={config}
                  periodsBlocked={periodsBlocked}
                  environment={props.environment}
                />
              }
            >
              <Route
                index
                element={
                  <Home
                    courier={props.courier}
                    system={props.system}
                    user={us}
                    changeBlockUI={changeBlockUI}
                  />
                }
              />
              <Route path={routes.help} element={<Tutorial video={getVideoName()} />} />
              <Route
                path={routes.documents}
                element={
                  <DocumentList
                    courier={props.courier}
                    system={props.system}
                    user={us}
                    environment={props.environment}
                    changeBlockUI={changeBlockUI}
                  />
                }
              />

              <Route
                path={routes.bloqueo_periodos}
                element={
                  <BlockPeriods
                    courier={props.courier}
                    system={props.system}
                    user={us}
                    changeBlockUI={changeBlockUI}
                  />
                }
              />

              <Route
                path={routes.mails}
                element={
                  <MailList
                    courier={props.courier}
                    system={props.system}
                    user={us}
                    changeBlockUI={changeBlockUI}
                  />
                }
              />

              <Route
                path={routes.notifications}
                element={
                  <NotificationList
                    courier={props.courier}
                    system={props.system}
                    user={us}
                    changeBlockUI={changeBlockUI}
                    config={config}
                    periodsBlocked={periodsBlocked}
                  />
                }
              />
              <Route
                path={routes.activity_list}
                element={
                  <ActivityList
                    config={config}
                    courier={props.courier}
                    system={props.system}
                    user={us}
                    changeBlockUI={changeBlockUI}
                    periodsBlocked={periodsBlocked}
                  />
                }
              />

              <Route
                path={routes.direct_settlement_list}
                element={
                  <DirectSettlementsList
                    changeBlockUI={changeBlockUI}
                    system={props.system}
                    courier={props.courier}
                  />
                }
              />
              <Route path={routes.direct_settlement}>
                <Route
                  path=":id"
                  element={
                    <DirectSettlementDetail
                      system={props.system}
                      courier={props.courier}
                      changeBlockUI={changeBlockUI}
                    />
                  }
                />
              </Route>

              <Route
                path={routes.settlement_list}
                element={
                  <SettlementsList
                    changeBlockUI={changeBlockUI}
                    system={props.system}
                    courier={props.courier}
                  />
                }
              />
              <Route path={routes.settlement}>
                <Route
                  path=":id"
                  element={<SettlementDetail system={props.system} courier={props.courier} />}
                />
              </Route>
              <Route
                path={routes.settlementView}
                element={<SettlementsView system={props.system} courier={props.courier} />}
              />
              <Route
                path={routes.directSettlementView}
                element={
                  <DirectSettlementsView
                    changeBlockUI={changeBlockUI}
                    system={props.system}
                    courier={props.courier}
                  />
                }
              />

              <Route
                path={routes.cost_per_hour_list}
                element={
                  <CostPerHourList
                    changeBlockUI={changeBlockUI}
                    system={props.system}
                    courier={props.courier}
                  />
                }
              />
              <Route
                path={routes.cost_per_hour_view}
                element={
                  <CostPerHourView
                    system={props.system}
                    courier={props.courier}
                    changeBlockUI={changeBlockUI}
                    user={us}
                  />
                }
              />
              <Route
                path={routes.cost_per_hour_view}
                element={
                  <CostPerHourView
                    changeBlockUI={changeBlockUI}
                    system={props.system}
                    courier={props.courier}
                    user={us}
                  />
                }
              />
              <Route path={routes.activity} element={<ActivityView />} />
              <Route
                path={routes.defense_file_assistant}
                element={
                  <DefenseFileContainer
                    system={props.system}
                    courier={props.courier}
                    changeBlockUI={changeBlockUI}
                  />
                }
              />
              <Route path={routes.defense_file}>
                <Route
                  index
                  element={
                    <ArchivosDefensaList
                      courier={props.courier}
                      changeBlockUI={changeBlockUI}
                      system={props.system}
                    />
                  }
                />
                <Route
                  path=":id"
                  element={
                    <ArchivosDefensaView
                      system={props.system}
                      courier={props.courier}
                      changeBlockUI={changeBlockUI}
                    />
                  }
                />
              </Route>
              <Route
                path={routes.massive_defense_view}
                element={
                  <MassiveDefenseView
                    system={props.system}
                    courier={props.courier}
                    changeBlockUI={changeBlockUI}
                  />
                }
              />
              <Route path={routes.massive_defense_list}>
                <Route
                  index
                  element={
                    <MassiveDefenseList
                      courier={props.courier}
                      changeBlockUI={changeBlockUI}
                      system={props.system}
                    />
                  }
                />
                <Route
                  path=":id"
                  element={
                    <MassiveDefenseDetail
                      system={props.system}
                      courier={props.courier}
                      changeBlockUI={changeBlockUI}
                    />
                  }
                />
              </Route>
              <Route
                path={routes.collaborator_list}
                element={
                  <CollaboratorList
                    changeBlockUI={changeBlockUI}
                    system={props.system}
                    courier={props.courier}
                  />
                }
              />
              <Route
                path={routes.company_tree}
                element={
                  <CompanyTreeView
                    system={props.system}
                    courier={props.courier}
                    changeBlockUI={changeBlockUI}
                    user={us}
                  />
                }
              />

              <Route
                path={routes.project_list}
                element={
                  <ProjectList
                    system={props.system}
                    courier={props.courier}
                    user={us}
                    changeBlockUI={changeBlockUI}
                  />
                }
              />

              <Route path={routes.project_config}>
                <Route
                  path=":id"
                  element={
                    <ProjectConfiguration
                      system={props.system}
                      courier={props.courier}
                      blockUi={changeBlockUI}
                      user={us}
                      environment={props.environment}
                    />
                  }
                />
              </Route>

              <Route path={routes.edit_distribution_by_period}>
                <Route
                  path=":id"
                  element={
                    <ProjectDistributionByPeriodWizard
                      system={props.system}
                      courier={props.courier}
                      user={us}
                      changeBlockUI={changeBlockUI}
                      config={config}
                      periodsBlocked={periodsBlocked}
                      environment={props.environment}
                    />
                  }
                />
              </Route>

              <Route path={routes.view_distribution_by_period}>
                <Route
                  path=":id"
                  element={
                    <ProjectDistributionByPeriodViewer
                      system={props.system}
                      courier={props.courier}
                      user={us}
                      changeBlockUI={changeBlockUI}
                      config={config}
                      periodsBlocked={periodsBlocked}
                      environment={props.environment}
                    />
                  }
                />
              </Route>

              <Route path={routes.project_docs}>
                <Route
                  path=":id"
                  element={
                    <ProjectRequiredDocument
                      system={props.system}
                      courier={props.courier}
                      user={us}
                      changeBlockUI={changeBlockUI}
                      environment={props.environment}
                    />
                  }
                />
              </Route>
              <Route path={routes.project_panel}>
                <Route
                  path=":id"
                  element={
                    <ProjectPanel
                      system={props.system}
                      courier={props.courier}
                      environment={props.environment}
                    />
                  }
                />
              </Route>

              <Route
                path={`${routes.reportsCompany}`}
                element={
                  <ReportingSettlementHoursCompaniesDE
                    changeBlockUI={changeBlockUI}
                    system={props.system}
                    courier={props.courier}
                  />
                }
              />

              <Route
                path={`${routes.reportsProjectDocs}`}
                element={
                  <ReportingDocsControl
                    user={us}
                    changeBlockUI={changeBlockUI}
                    system={props.system}
                    courier={props.courier}
                  />
                }
              />

              <Route
                path={`${routes.reportsMMI}`}
                element={
                  <ReportingMMI
                    user={us}
                    changeBlockUI={changeBlockUI}
                    system={props.system}
                    courier={props.courier}
                  />
                }
              />

              <Route
                path={`${routes.reportsBillableDistribution}`}
                element={
                  <ReportingBillableDistribution
                    user={us}
                    changeBlockUI={changeBlockUI}
                    system={props.system}
                    courier={props.courier}
                  />
                }
              />

              <Route
                path={routes.reportsSector}
                element={
                  <ReportingSettlementHoursSectorDE
                    changeBlockUI={changeBlockUI}
                    system={props.system}
                    user={us}
                    courier={props.courier}
                  />
                }
              />

              <Route
                path={routes.reportsProject}
                element={
                  <ReportingHoursProjects
                    changeBlockUI={changeBlockUI}
                    system={props.system}
                    user={us}
                    courier={props.courier}
                  />
                }
              />

              <Route
                path={routes.preliquidation}
                element={
                  <ReportingSettlementPreliquidation
                    changeBlockUI={changeBlockUI}
                    system={props.system}
                    courier={props.courier}
                    user={us}
                  />
                }
              />

              <Route path={routes.control_doc}>
                <Route
                  path=":proId/:id"
                  element={<ControlDocument system={props.system} courier={props.courier} />}
                />
              </Route>

              <Route path={routes.project}>
                <Route
                  index
                  element={
                    <ProjectView
                      system={props.system}
                      courier={props.courier}
                      user={us}
                      changeBlockUI={changeBlockUI}
                      config={config}
                      periodsBlocked={periodsBlocked}
                      environment={props.environment}
                    />
                  }
                />
                <Route
                  path=":id"
                  element={
                    <ProjectView
                      system={props.system}
                      courier={props.courier}
                      user={us}
                      changeBlockUI={changeBlockUI}
                      config={config}
                      periodsBlocked={periodsBlocked}
                      environment={props.environment}
                    />
                  }
                />
                {/* <Route
                  path=":id:/showPanel?"
                  element={
                    <ProjectView
                      changeBlockUI={changeBlockUI}
                      system={props.system}
                      courier={props.courier}
                      user={user}
                    />
                  }
                /> */}
              </Route>

              <Route path={routes.massive_mail_inspector}>
                {/* <Route
                index
                element={
                  <ProjectView
                    system={props.system}
                    courier={props.courier}
                    user={us}
                    changeBlockUI={changeBlockUI}
                    config={config}
                  />
                }
              /> */}
                <Route
                  path=":id"
                  element={
                    <ProjectMassiveMailInspector
                      system={props.system}
                      courier={props.courier}
                      user={us}
                      changeBlockUI={changeBlockUI}
                      config={config}
                      environment={props.environment}
                    />
                  }
                />
              </Route>

              <Route path={routes.collaborator}>
                <Route
                  index
                  element={
                    <CollaboratorView
                      system={props.system}
                      courier={props.courier}
                      changeBlockUI={changeBlockUI}
                      user={us}
                    />
                  }
                />

                <Route
                  path=":collaboratorId"
                  element={
                    <CollaboratorView
                      system={props.system}
                      courier={props.courier}
                      changeBlockUI={changeBlockUI}
                      user={us}
                    />
                  }
                />
              </Route>

              <Route path={routes.cost_per_hour_detail}>
                <Route
                  index
                  element={
                    <CostPerHourDetail
                      system={props.system}
                      courier={props.courier}
                      changeBlockUI={changeBlockUI}
                      // user={us}
                    />
                  }
                />

                <Route
                  path=":costoPorHoraId"
                  element={
                    <CostPerHourDetail
                      system={props.system}
                      courier={props.courier}
                      changeBlockUI={changeBlockUI}
                      // user={us}
                    />
                  }
                />
              </Route>

              <Route
                path={routes.calendar}
                element={
                  <CalendarView
                    system={props.system}
                    courier={props.courier}
                    config={config}
                    user={us}
                    changeBlockUI={changeBlockUI}
                    periodsBlocked={periodsBlocked}
                  />
                }
              />

              <Route
                path={routes.dashboard}
                element={
                  <PanelProjectsProgress
                    system={props.system}
                    courier={props.courier}
                    user={us}
                    changeBlockUI={changeBlockUI}
                  />
                }
              />

              <Route
                path={routes.activity_import}
                element={
                  <MassiveActivitiesUploadAssistant
                    system={props.system}
                    courier={props.courier}
                    user={us}
                    changeBlockUI={changeBlockUI}
                  />
                }
              />

              <Route
                path={routes.activity_checker}
                element={
                  <MassiveActivitiesChecker
                    system={props.system}
                    courier={props.courier}
                    user={us}
                    changeBlockUI={changeBlockUI}
                    config={config}
                    periodsBlocked={periodsBlocked}
                  />
                }
              />

              <Route
                path={routes.distribution_corrector}
                element={
                  <MassiveDistributionCorrector
                    system={props.system}
                    courier={props.courier}
                    user={us}
                    changeBlockUI={changeBlockUI}
                    config={config}
                    periodsBlocked={periodsBlocked}
                  />
                }
              />

              <Route
                path={routes.dashboard_own}
                element={
                  <PanelOwn
                    system={props.system}
                    courier={props.courier}
                    user={us}
                    changeBlockUI={changeBlockUI}
                  />
                }
              />

              <Route
                path={routes.dashboard_empresas}
                element={
                  <PanelCompanies
                    system={props.system}
                    courier={props.courier}
                    user={us}
                    changeBlockUI={changeBlockUI}
                  />
                }
              />

              <Route
                path={routes.dashboard_colaboradores}
                element={
                  <PanelCollaborators
                    system={props.system}
                    courier={props.courier}
                    user={us}
                    changeBlockUI={changeBlockUI}
                  />
                }
              />

              <Route
                path={routes.basic_inputs}
                element={<BasicInputsView system={props.system} courier={props.courier} />}
              />

              <Route path={routes.licenses}>
                <Route
                  index
                  element={
                    <LicensesList
                      user={us}
                      system={props.system}
                      courier={props.courier}
                      changeBlockUI={changeBlockUI}
                    />
                  }
                />
                <Route
                  path=":id"
                  element={
                    <LicensesDetail
                      system={props.system}
                      courier={props.courier}
                      changeBlockUI={changeBlockUI}
                    />
                  }
                />
              </Route>
              <Route path={routes.licences_config}>
                <Route
                  index
                  element={
                    <LicensesView
                      system={props.system}
                      courier={props.courier}
                      changeBlockUI={changeBlockUI}
                    />
                  }
                />
              </Route>

              <Route path={routes.form_example} element={<FormExample />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          )}

          <Route path={routes.maintenance} element={<Maintenance></Maintenance>} />
        </Routes>

        {!loadedUser && (
          <section className="section-container">
            <BasicView title="">{t('Cargando... aguarda unos instantes')}</BasicView>
          </section>
        )}
      </Suspense>
    </>
  );
};

export default AppInRouterContext;
