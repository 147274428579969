import { DateTime } from 'luxon';
import { DateCustom } from '../../lib/DateCustom';
import XhrRequestHandler from '../../lib/XhrRequestHandler';
import { IMail, Mail } from './Mail';

export class MailSystem {
  private readonly base = '/mails';
  constructor(private requestHandler: XhrRequestHandler) {}

  getBaseUrlForDatagrid() {
    return this.requestHandler.requestUrl(`${this.base}/datagrid`);
  }

  async getMailIdentifiedBy(id: string): Promise<Mail> {
    const data = await this.requestHandler.get<IMail>(`${this.base}/${id}`);
    return Mail.buildFrom(data);
  }

  async getDownloadLinkFor(mail: Mail): Promise<string> {
    const { link } = await this.requestHandler.get<{ link: string }>(
      `${this.base}/download-link/${mail.getId()}`
    );

    return link;
  }
}
