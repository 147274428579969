import { DateTime } from 'luxon';
import { DateCustom } from '../../lib/DateCustom';
import XhrRequestHandler from '../../lib/XhrRequestHandler';
import { IMailAttachment, MailAttachment } from './MailAttachment';

export class MailAttachmentSystem {
  private readonly base = '/mail-attachments';
  constructor(private requestHandler: XhrRequestHandler) {}

  getBaseUrlForDatagrid() {
    return this.requestHandler.requestUrl(`${this.base}/datagrid`);
  }

  async getMailAttachmentIdentifiedBy(id: string): Promise<MailAttachment> {
    const data = await this.requestHandler.get<IMailAttachment>(`${this.base}/${id}`);
    return MailAttachment.buildFrom(data);
  }

  async getDownloadLinkFor(mailAttachemnt: MailAttachment): Promise<string> {
    const { link } = await this.requestHandler.get<{ link: string }>(
      `${this.base}/download-link/${mailAttachemnt.getId()}`
    );

    return link;
  }
}
