import { DateTime } from 'luxon';
import { ALL_SECTORS } from '../../Components/Common/OrganizationChartDropdown';
import XhrRequestHandler from '../../lib/XhrRequestHandler';
import { ICollaborator } from '../collaborators/CollaboratorSystem';
import { IOrganizationChartCompleteNode } from '../organization_chart/OrganizationChartSystem';

export interface IPanelProjectSummary {
  id: string;
  name: string;
  code: string;
  amount: number;
}

export interface IPanelCompanySummary {
  date: string;
  companyName: string;
  companyId: string;
  hours: number;
}

interface IPanelCompanySummaryFromAPI {
  emp_nombre: string;
  emp_id: string;
  fecha: string;
  horas: number;
}

export interface IPanelCompanyHours {
  id: string;
  name: string;
  code: string;
  amount: number;
}

interface IActivitiesForProject {
  pro_id: string;
  pro_nombre: string;
  pro_codigo: string;
  cant: number;
}

export interface IAmountByConcept {
  name: string;
  id: string;
  amount: number;
}

export interface IResumenColaboradoresComp {
  colaborador: ICollaborator;
  horas_totales: number;
  docs_totales: number;
}

interface IResumenDocumentos {
  cant_doc: number;
  col_id: string;
  col_nombre: string;
}

interface IResumenHoras {
  col_id: string;
  col_nombre: string;
  horas_cargadas: number;
  pro_codigo: string;
  pro_id: string;
  pro_nombre: string;
  node: IOrganizationChartCompleteNode;
}

interface ICollaboratorsSummaryFromAPI {
  resumenColaboradoresComp: IResumenColaboradoresComp[];
  resumenDocumentos: IResumenDocumentos[];
  resumenHoras: IResumenHoras[];
  totalColaboradores: number;
  totalEmpresas: number;
  totalProyectos: number;
}

interface ICollaboratorPanelFromAPI {
  actividades: ICollaboratorActivitiesFromAPI[];
  documentos: ICollaboratorDocumentsFromAPI[];
  horasPorEmpresa: ICollaboratorHoursByCompanyFromAPI[];
  inventarioActividades: ICollaboratorActivityInventory[];
  actividadesPorColaboradorPorDia: ICollaboratorActivitiesPerDay[];
  header: ICollaboratorPanelHeader;
  proyectosActivos: number;
  empresasActivas: number;
}

interface IQuantityByDate {
  fecha: string;
  qty: number;
}

interface ICollaboratorHoursByCompanyFromAPI {
  emp_id: string;
  emp_nombre: string;
  emp_nombre_comercial: string;
  horas: number;
}

interface ICollaboratorPanelHeader {
  groupBy: string;
}

interface ICollaboratorActivitiesFromAPI {
  pro_id: string;
  pro_codigo: string;
  pro_nombre: string;
  cant_actividades: number;
  participa: number;
  declina: number;
  tag_pending: number;
  horas_participa: number;
  horas_tag_pending: number;
}

interface ICollaboratorDocumentsFromAPI {
  pro_id: string;
  pro_codigo: string;
  pro_nombre: string;
  cant: number;
  doc_pending: number;
}

interface ICollaboratorActivityInventory {
  act_nombre: string;
  act_inicio: string;
  act_id: string;
  pro_id: string;
  pro_codigo: string;
  pro_nombre: string;
  participa: number;
  declina: number;
  tag_pending: number;
  horas_participa: number;
}

interface ICollaboratorActivitiesPerDay {
  dia: string;
  semana: string;
  mes: string;
  label: string;
  horas_participa: number;
  horas_declina: number;
  horas_tag_pending: number;
  horas_totales: number;
}

interface ICollaboratorsSummaryCompaniesFromAPI {
  cant_colaboradores: number;
  cant_proyectos: number;
  emp_id: string;
  emp_nombre: string;
  horas_check_pending: number;
  horas_chequeadas: number;
  importe_costo: number;
  moneda_simbolo: string;
}

export interface ICollaboratorDetailList {
  pro_id: string;
  pro_name: string;
  act_total: number;
  act_confirmed: number;
  act_declined: number;
  act_pending: number;
  hs_confirmed: number;
  hs_pending: number;
  doc_total: number;
  doc_pending: number;
}

export interface ICollaboratorsSummary {
  totalProjects: number;
  activeProjects: number;
  totalCollaborators: number;
  activeCollaborators: number;
  totalCompanies: number;
  activeCompanies: number;
  totalHours: number;
  totalDocuments: number;
  hoursForCompanies: IAmountByConcept[];
  hoursForProjects: IAmountByConcept[];
  hoursForLastLevelSector: IAmountByConcept[];
  hoursForCollaborators: IAmountByConcept[];
  docsForCollaborators: IAmountByConcept[];
  collaboratorComp: IResumenColaboradoresComp[];
}

export interface SingleCollaboratorPanel {
  activitiesByProject: IAmountByConcept[];
  hoursByCompany: IAmountByConcept[];
  docsByProject: IAmountByConcept[];
  hoursByDay: IQuantityByDate[];
  activities: IAmountByConcept[];
  activeCompanies: number;
  activeProjects: number;
  activityHours: IAmountByConcept[];
  documents: IAmountByConcept[];
  projectDetail: ICollaboratorDetailList[];
  header: string;
}

export interface CollaboratorActivityStatus {
  estadoDocumentos: IDocumentStatus[];
  estadoHoras: IHoursStatus[];
}

export interface IDocumentStatus {
  pro_id: string;
  pro_nombre: string;
  pro_codigo: string;
  cant: number;
  ccpd_proid: string;
  cant_cargados: number;
  cant_pendientes: number;
  cant_vencidos: number;
}

export interface IHoursStatus {
  horas: ICollaboratorStatusHours;
  periodo: string;
}

export interface ICollaboratorStatusHours {
  horasEsperadas: number;
  horasCargadasPlantilla: number;
  horasFaltantes: number;
  horasExcedidas: number;
  horasLicencia: number;
}

export class PanelSystem {
  private readonly base = '/panel';
  private readonly baseDVM = '/panelDVM';

  constructor(private requestHandler: XhrRequestHandler) {}

  private getOrganizationChartParams(organizationChartId?: string) {
    return organizationChartId && organizationChartId !== ALL_SECTORS
      ? `nodoOrganigrama=${organizationChartId}`
      : '';
  }

  private getDateParams(from?: DateTime, to?: DateTime, useQuestSymbol: boolean = true) {
    let params = '';
    if (from && to && useQuestSymbol) {
      params += `?desde=${from.year}-${from.month}-${from.day}&hasta=${to.year}-${to.month}-${to.day}`;
    }
    return params;
  }

  private _getDateParams(from?: DateTime, to?: DateTime, useQuestSymbol: boolean = true) {
    let params = '';
    if (from && to && useQuestSymbol) {
      params += `?desde=${from.toFormat('dd-LL-yyyy')}&hasta=${to.toFormat('dd-LL-yyyy')}`;
    }
    return params;
  }

  async getActivitiesForProject(
    organizationNodeId?: string,
    from?: DateTime,
    to?: DateTime
  ): Promise<IPanelProjectSummary[]> {
    let params = this.getOrganizationChartParams(organizationNodeId);
    let res = await this.requestHandler.get<IActivitiesForProject[]>(
      `${this.baseDVM}/actividades-cargadas-proyecto?${params}`
      // `${this.baseDVM}/actividades-cargadas-proyecto${params}${dateParams}`
    );
    return res.map((item) => {
      let ret: IPanelProjectSummary = {
        id: item.pro_id,
        name: item.pro_nombre,
        code: item.pro_codigo,
        amount: item.cant,
      };
      return ret;
    });
  }

  async getCollaboratorPanel(
    from: DateTime,
    to: DateTime,
    collaboratorId: string
  ): Promise<SingleCollaboratorPanel> {
    const from_str = from.toFormat('yyyy-LL-dd');
    const to_str = to.toFormat('yyyy-LL-dd');
    const res = await this.requestHandler.get<ICollaboratorPanelFromAPI>(
      `${this.baseDVM}/colaboradores/${collaboratorId}/${from_str}/${to_str}`
    );

    return this.mapColllaboratorPanel(res);
  }

  async getCollaboratorActivityStatus(collaboratorId: string): Promise<CollaboratorActivityStatus> {
    const res = await this.requestHandler.get<CollaboratorActivityStatus>(
      `${this.baseDVM}/estado-actividad/${collaboratorId}`
    );

    return {
      estadoDocumentos: res.estadoDocumentos,
      estadoHoras: res.estadoHoras,
    };
  }

  async getMyPanelData(from: DateTime, to: DateTime) {
    const res = await this.requestHandler.get<ICollaboratorPanelFromAPI>(
      `${this.baseDVM}/mi-panel/${from.toFormat('yyyy-LL-dd')}/${to.toFormat('yyyy-LL-dd')}`
    );

    return this.mapColllaboratorPanel(res);
  }

  private mapColllaboratorPanel(res: ICollaboratorPanelFromAPI): SingleCollaboratorPanel {
    let _activitiesByProject: IAmountByConcept[] = [];
    _activitiesByProject = res.actividades.map((actByPro) => {
      return {
        name: `(${actByPro.pro_codigo}) - ${actByPro.pro_nombre}`,
        id: actByPro.pro_id,
        amount: actByPro.cant_actividades,
      };
    });

    let _hoursByCompany: IAmountByConcept[] = [];
    _hoursByCompany = res.horasPorEmpresa.map((hsByCompany) => {
      return {
        name: hsByCompany.emp_nombre,
        id: hsByCompany.emp_id,
        amount: hsByCompany.horas,
      };
    });

    let _docsByProject: IAmountByConcept[] = [];
    _docsByProject = res.documentos.map((docsByCompany) => {
      return {
        name: docsByCompany.pro_codigo,
        id: docsByCompany.pro_id,
        amount: docsByCompany.cant,
      };
    });

    let _hoursByDay: IQuantityByDate[] = [];
    _hoursByDay = res.actividadesPorColaboradorPorDia.map((hsPerCollabByDay) => {
      return {
        fecha: hsPerCollabByDay.dia,
        qty: hsPerCollabByDay.horas_participa,
      };
    });

    let _activities: IAmountByConcept[] = [
      {
        name: 'Confirmadas',
        id: '',
        amount: res.actividades.reduce((a, b) => a + b.cant_actividades, 0),
      },
      {
        name: 'Pendientes',
        id: '',
        amount: res.actividades.reduce((a, b) => a + b.tag_pending, 0),
      },
      {
        name: 'Declinadas',
        id: '',
        amount: res.actividades.reduce((a, b) => a + b.declina, 0),
      },
    ];

    let _activityHours: IAmountByConcept[] = [
      {
        name: 'Confirmadas',
        id: '',
        amount: res.actividades.reduce((a, b) => a + b.horas_participa, 0),
      },
      {
        name: 'Pendientes',
        id: '',
        amount: res.actividades.reduce((a, b) => a + b.horas_tag_pending, 0),
      },
    ];

    let _documents: IAmountByConcept[] = [
      {
        name: 'Cargados',
        id: '',
        amount: res.documentos.reduce((a, b) => a + b.cant, 0),
      },
      {
        name: 'Pendientes',
        id: '',
        amount: res.documentos.reduce((a, b) => a + b.doc_pending, 0),
      },
    ];

    const projectIds: string[] = [];
    res.actividades.forEach((act) => {
      projectIds.push(`${parseInt(act.pro_id)}`);
    });
    res.documentos.forEach((doc) => {
      projectIds.push(doc.pro_id);
    });

    let uniqueProjectIds = [...new Set(projectIds)];
    const projectDetail = uniqueProjectIds.map((project): ICollaboratorDetailList => {
      const actividad = res.actividades.find(({ pro_id }) => +pro_id === +project);
      const documento = res.documentos.find(({ pro_id }) => +pro_id === +project);
      const projectName = actividad
        ? `(${actividad?.pro_codigo}) - ${actividad?.pro_nombre}`
        : `(${documento?.pro_codigo}) - ${documento?.pro_nombre}`;
      return {
        pro_id: project,
        pro_name: projectName,
        act_total: actividad?.cant_actividades ? parseInt(`${actividad?.cant_actividades}`) : 0,
        act_confirmed: actividad?.participa ? parseInt(`${actividad?.participa}`) : 0,
        act_declined: actividad?.declina ? parseInt(`${actividad?.declina}`) : 0,
        act_pending: actividad?.tag_pending ? parseInt(`${actividad?.tag_pending}`) : 0,
        hs_confirmed: actividad?.horas_participa ? parseInt(`${actividad?.horas_participa}`) : 0,
        hs_pending: actividad?.horas_tag_pending ? parseInt(`${actividad?.horas_tag_pending}`) : 0,
        doc_total: documento?.cant ? parseInt(`${documento?.cant}`) : 0,
        doc_pending: documento?.doc_pending ? parseInt(`${documento?.doc_pending}`) : 0,
      };
    });

    return {
      activitiesByProject: _activitiesByProject,
      hoursByCompany: _hoursByCompany,
      docsByProject: _docsByProject,
      hoursByDay: _hoursByDay,
      activities: _activities,
      activeCompanies: res.empresasActivas,
      activeProjects: res.proyectosActivos,
      activityHours: _activityHours,
      documents: _documents,
      projectDetail: projectDetail,
      header: res.header.groupBy,
    };
  }

  async getDocumentsForProject(
    organizationChartId?: string,
    from?: DateTime,
    to?: DateTime
  ): Promise<IPanelProjectSummary[]> {
    let params = this.getOrganizationChartParams(organizationChartId);
    let res = await this.requestHandler.get<IActivitiesForProject[]>(
      `${this.baseDVM}/documentos-cargados-proyecto?${params}`
      // `${this.baseDVM}/documentos-cargados-proyecto${params}${dateParams}`
    );
    return res.map((item) => {
      let ret: IPanelProjectSummary = {
        id: item.pro_id,
        name: item.pro_nombre,
        code: item.pro_codigo,
        amount: item.cant,
      };
      return ret;
    });
  }

  async getHoursPerCompany(
    from?: DateTime,
    to?: DateTime,
    companyIdList: string[] = []
  ): Promise<IPanelCompanySummary[]> {
    let dateParams = this._getDateParams(from, to);
    let res = await this.requestHandler.post<IPanelCompanySummaryFromAPI[]>(
      `${this.baseDVM}/panel-horas-por-empresas${dateParams}`,
      companyIdList
    );

    return res.map((item): IPanelCompanySummary => {
      return {
        date: item.fecha,
        companyName: item.emp_nombre,
        companyId: item.emp_id,
        hours: item.horas,
      };
    });
  }

  async getCollaboratorsSummary(
    from: DateTime,
    to: DateTime,
    organizationChartId?: string
  ): Promise<ICollaboratorsSummary> {
    const params = this.getOrganizationChartParams(organizationChartId);
    const res = await this.requestHandler.get<ICollaboratorsSummaryFromAPI>(
      `${this.baseDVM}/colaboradores/${from.toFormat('yyyy-LL-dd')}/${to.toFormat('yyyy-LL-dd')}?${params}`
    );

    const paramsForCompanyPanel = this.getOrganizationChartParams(organizationChartId);
    const resCompanyPanel = await this.requestHandler.get<ICollaboratorsSummaryCompaniesFromAPI[]>(
      `${this.baseDVM}/panel-empresas?desde=${from.toFormat('dd-LL-yyyy')}&hasta=${to.toFormat(
        'dd-LL-yyyy'
      )}&${paramsForCompanyPanel}`
    );

    let _collaboratorIdList: string[] = [];
    let _projectIdList: string[] = [];
    let totalHours = 0;
    let totalDocuments = 0;
    let activeCompanies = 0;
    let hoursForCompanies: IAmountByConcept[] = [];
    let hoursForProjects: IAmountByConcept[] = [];
    const hoursForLastLevelSector: IAmountByConcept[] = [];
    let hoursForCollaborators: IAmountByConcept[] = [];
    let docsForCollaborators: IAmountByConcept[] = [];

    if (res.resumenHoras.length > 0) {
      _projectIdList = res.resumenHoras.map((item) => {
        return item.pro_id;
      });

      _collaboratorIdList = res.resumenHoras.map((item) => {
        return item.col_id;
      });

      res.resumenHoras.forEach((item) => {
        totalHours += item.horas_cargadas;
      });
    }

    let activeCollaborators = res.resumenColaboradoresComp.filter((rc) => rc.horas_totales > 0).length;
    let activeProjects = [...new Set(_projectIdList)].length;

    if (resCompanyPanel.length > 0) {
      let _companyIdList: any[] = [];
      _companyIdList = resCompanyPanel
        .filter((item) => item.horas_chequeadas > 0)
        .map((item) => {
          return item.emp_id;
        });

      activeCompanies = [...new Set(_companyIdList)].length;

      resCompanyPanel.forEach((item) => {
        let idx = hoursForCompanies.findIndex((it) => it.id === item.emp_id);
        if (idx > -1) {
          hoursForCompanies[idx].amount += item.horas_chequeadas;
        } else {
          hoursForCompanies.push({
            id: item.emp_id,
            name: item.emp_nombre,
            amount: item.horas_chequeadas,
          });
        }
      });
    }

    // if (res.resumenDocumentos.length > 0) {
    //   res.resumenDocumentos.forEach((item) => {
    //     totalDocuments += item.cant_doc;
    //   });

    // res.resumenDocumentos.forEach((item) => {
    //   let idx = docsForCollaborators.findIndex((it) => it.id === item.col_id);
    //   if (idx > -1) {
    //     docsForCollaborators[idx].amount += item.cant_doc;
    //   } else {
    //     docsForCollaborators.push({
    //       id: item.col_id,
    //       name: item.col_nombre,
    //       amount: item.cant_doc,
    //     });
    //   }
    // });
    // }

    if (res.resumenColaboradoresComp.length > 0) {
      res.resumenColaboradoresComp.forEach((item) => {
        totalDocuments += item.docs_totales;
      });

      docsForCollaborators = res.resumenColaboradoresComp.map((rcc) => {
        return {
          id: rcc.colaborador.col_id,
          name: rcc.colaborador.col_nombre + ' ' + rcc.colaborador.col_apellido,
          amount: rcc.docs_totales,
        };
      });

      docsForCollaborators.sort((a, b) => (a.amount > b.amount ? -1 : b.amount > a.amount ? 1 : 0));
    }

    res.resumenHoras.forEach((item) => {
      let idx = hoursForProjects.findIndex((it) => it.id === item.pro_id);
      if (idx > -1) {
        hoursForProjects[idx].amount += item.horas_cargadas;
      } else {
        hoursForProjects.push({
          id: item.pro_id,
          name: `(${item.pro_codigo}) - ${item.pro_nombre}`,
          amount: item.horas_cargadas,
        });
      }
    });

    res.resumenHoras.forEach((item) => {
      let idx = hoursForLastLevelSector.findIndex((it) => it.id === item.node.nor_id);
      if (idx > -1) {
        hoursForLastLevelSector[idx].amount += item.horas_cargadas;
        hoursForLastLevelSector[idx].amount = Number(hoursForLastLevelSector[idx].amount.toFixed(2));
      } else {
        hoursForLastLevelSector.push({
          id: item.node.nor_id,
          name: item.node.nor_nombre,
          amount: Number(item.horas_cargadas.toFixed(2)),
        });
        hoursForLastLevelSector.sort((a, b) => (a.amount > b.amount ? -1 : b.amount > a.amount ? 1 : 0));
      }
    });

    // res.resumenHoras.forEach((item) => {
    //   let idx = hoursForCollaborators.findIndex((it) => it.id === item.col_id);
    //   if (idx > -1) {
    //     hoursForCollaborators[idx].amount += item.horas_cargadas;
    //   } else {
    //     hoursForCollaborators.push({
    //       id: item.col_id,
    //       name: item.col_nombre,
    //       amount: item.horas_cargadas,
    //     });
    //   }
    // });

    hoursForCollaborators = res.resumenColaboradoresComp.map((rcc) => {
      return {
        id: rcc.colaborador.col_id,
        name: rcc.colaborador.col_nombre,
        amount: rcc.horas_totales,
      };
    });
    hoursForCollaborators.sort((a, b) => (a.amount > b.amount ? -1 : b.amount > a.amount ? 1 : 0));

    let collaboratorComp: IResumenColaboradoresComp[] = [];
    if (res.resumenColaboradoresComp) {
      collaboratorComp = res.resumenColaboradoresComp;
    }

    return {
      totalProjects: res.totalProyectos,
      activeProjects: activeProjects,
      totalCollaborators: res.resumenColaboradoresComp.length,
      activeCollaborators: activeCollaborators,
      totalCompanies: res.totalEmpresas,
      activeCompanies: activeCompanies,
      totalHours: totalHours,
      totalDocuments: totalDocuments,
      hoursForCompanies: hoursForCompanies,
      hoursForProjects: hoursForProjects,
      hoursForLastLevelSector,
      hoursForCollaborators: hoursForCollaborators,
      docsForCollaborators: docsForCollaborators,
      collaboratorComp: collaboratorComp,
    };
  }
}
