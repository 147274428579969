import { DateCustom } from '../../../../lib/DateCustom';
import DialogTemplate from '../../../Common/DialogTemplate';
import {
  mergeStyleSets,
  Text,
  Icon,
  TextField,
  DatePicker,
  defaultDatePickerStrings,
  ChoiceGroup,
  IChoiceGroupOption,
  Checkbox,
  Toggle,
} from '@fluentui/react';
import { styles } from '../../CalendarStyles';
import React from 'react';
import { ReplicateActivityData } from '../../../../system/activity/ActivitySystemOptions';
import { TimeSelector } from '../../../Common/TimeSelector';
import { newEntityDefaultId } from '../../../../common/constants';
import { Activity } from '../../../../system/activity/Activity';
import TimeTaxSupportSystemClient from '../../../../system';
import { DateTime } from 'luxon';
import MessageCourier from '../../../../lib/MessageCourier';
import i18next, { t } from 'i18next';
import SystemConfiguration from '../../../../system/configuration/SystemConfiguration';
import { getLocalizedStrings } from '../../../../lib/LocalizedStringsForDatePicker';
import { getMinDate } from '../../../../lib/DatePickerUtils';

export interface ReplicarDialogProps {
  activity: Activity;
  // onReplicar: (dataToPost: ReplicateActivityData, cantAReplicar: number | null) => Promise<void>;
  system: TimeTaxSupportSystemClient;
  toggleShowDialog: () => void;
  showDialog: boolean;
  courier: MessageCourier;
  refreshEvents: () => void;
  changeBlockUI: (state: boolean) => void;
  config?: SystemConfiguration;
}

export interface ReplicarDialogState {
  open: boolean;
  desde: Date;
  hasta: Date;
  horaDesde: Date;
  horaHasta: Date;
  frecuencia: number | string;
  dateChanged: boolean;
  diasAReplicar: string[];
  taggeaEquipo: boolean;
  [index: string]: any;
}

interface FrecuenciaDiariaOptions {
  label: string;
  value: string;
}
const DIA_LUNES = '1';
const DIA_MARTES = '2';
const DIA_MIERCOLES = '3';
const DIA_JUEVES = '4';
const DIA_VIERNES = '5';
const DIA_SABADO = '6';
const DIA_DOMINGO = '7';

const frecuenciaDiariaOptions: FrecuenciaDiariaOptions[] = [
  { label: t('Lunes'), value: DIA_LUNES },
  { label: t('Martes'), value: DIA_MARTES },
  { label: t('Miercoles'), value: DIA_MIERCOLES },
  { label: t('Jueves'), value: DIA_JUEVES },
  { label: t('Viernes'), value: DIA_VIERNES },
  { label: t('Sábado'), value: DIA_SABADO },
  { label: t('Domingo'), value: DIA_DOMINGO },
];

const frecuenciaOptions = [
  { text: t('Diaria'), key: '1' },
  { text: t('Semanal'), key: '2' },
  { text: t('Mensual'), key: '3' },
];

export const ReplicateDialog = (props: ReplicarDialogProps) => {
  const [state, setState] = React.useState<ReplicarDialogState | null>(null);

  React.useEffect(() => {
    if (props.activity) {
      setInitialState();
    }
  }, [props.showDialog]);

  const setInitialState = async () => {
    const _project = props.activity.getProject();
    let _desde = await getProximoDiaHabil(props.activity.getStart());
    if (_project) {
      setState({
        open: props.showDialog,
        desde: _desde ? _desde : props.activity.getStart(),
        hasta: _project.getEnd().toJSDate(),
        dateChanged: false,
        frecuencia: frecuenciaOptions[0].key,
        diasAReplicar: [DIA_LUNES, DIA_MARTES, DIA_MIERCOLES, DIA_JUEVES, DIA_VIERNES],
        horaDesde: props.activity.getStart(),
        horaHasta: props.activity.getEnd(),
        taggeaEquipo: true,
      });
    }
  };

  const [cantAReplicar, setCantAReplicar] = React.useState<number | null>(null);
  const [diaDelMes, setDiaDelMes] = React.useState<number | ''>('');
  const [tipoReplicacionMensual, setTipoReplicacionMensual] = React.useState<'diaDeLaSemana' | 'diaDelMes'>(
    'diaDelMes'
  );
  const [repMensualAnteriorDiaHabil, setRepMensualAnteriorDiaHabil] = React.useState(false);
  const [mensualLabel, setMensualLabel] = React.useState<string>('');

  const onChangeTipoReplicacionMensual = (tipo: 'diaDeLaSemana' | 'diaDelMes') => {
    if (tipo === tipoReplicacionMensual) return;

    setTipoReplicacionMensual(tipo);
  };

  const handleDateChangeWrapper = (date: Date | null | undefined, name: string) => {
    if (date && state) {
      if (name === 'desde') {
        setState({ ...state, desde: date, dateChanged: true });
      }
      if (name === 'hasta') {
        setState({ ...state, hasta: date, dateChanged: true });
      }
    }
  };

  const onDiaDelMesChange = (value: number | '') => {
    setDiaDelMes(value);
  };

  const getProximoDiaHabilWrapper = React.useCallback(
    async (desde: string | Date) => {
      if (props.showDialog) {
        const date = await props.system.getActivitySystem().getProximoDiaHabil(desde);
        if (date) {
          return date;
        }
      }
      return null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [props.showDialog]
  );

  const validateReplicarBtn = () => {
    let disable = true;

    if (state) {
      let diff = DateCustom.calcularHoras(state.horaDesde, state.horaHasta);

      if (diff > 0 && diff <= 8) {
        disable = false;
      }

      if (cantAReplicar != null && cantAReplicar === 0) {
        disable = true;
      }
    }

    return disable;
  };

  const adaptOptions = React.useCallback(async () => {
    if (props.activity.getStart()) {
      const data = await props.system.getActivitySystem().getReplicateContext(props.activity);

      if (data) {
        if (props.activity.getStart() instanceof Date) {
          setMensualLabel(
            `El ${DateCustom.traducirPosicion(data.position)} ${DateCustom.getNameOfDay(
              props.activity.getStart().getDay()
            )}`
          );
          setDiaDelMes(props.activity.getStart().getDate());
        }
      }
    }
  }, [props.showDialog]);

  React.useEffect(() => {
    if (props.showDialog) {
      adaptOptions();
    }
  }, [props.showDialog, adaptOptions]);

  const getProximoDiaHabil = async (desde: Date) => {
    const res = await getProximoDiaHabilWrapper(desde);
    if (res) {
      const [day, month, year] = res.fecha.split('/');
      let _desdeFormatted = [year, month, day].join('-');
      const _desde = DateTime.fromISO(`${_desdeFormatted}T00:00:00`).toJSDate();
      return _desde;
    }
  };

  const getCantAReplicar = async () => {
    if (props.showDialog) {
      if (state && props.activity.getId() && props.activity.getId() !== newEntityDefaultId) {
        const dataToPost: ReplicateActivityData = {
          frecuencia: state.frecuencia,
          desde: state.desde,
          hasta: state.hasta,
          diasAReplicar: state.diasAReplicar,
        };
        if (tipoReplicacionMensual && typeof diaDelMes === 'number') {
          dataToPost.diaDelMes = diaDelMes;
          dataToPost.anteriorDiaHabil = repMensualAnteriorDiaHabil;
        }
        const data = await props.system.getActivitySystem().calculateReplica(props.activity, dataToPost);
        if (data) {
          setCantAReplicar(data.data.qty);
        }
      }
    }
  };

  React.useEffect(() => {
    getCantAReplicar();
  }, [props.showDialog, state?.desde, state?.hasta, state?.diasAReplicar]);

  const semanaLaboralSelected = (array: string[]) => {
    return (
      array.length === 5 &&
      array.includes(DIA_LUNES) &&
      array.includes(DIA_MARTES) &&
      array.includes(DIA_MIERCOLES) &&
      array.includes(DIA_JUEVES) &&
      array.includes(DIA_VIERNES)
    );
  };

  const handleChangeFrecuenciaDias = (value: string) => {
    if (state) {
      let _state = { ...state };
      let estaCheckeadoIndex = _state.diasAReplicar.findIndex((elem) => elem === value);

      if (estaCheckeadoIndex >= 0) {
        if (_state.diasAReplicar.length > 1) {
          _state.diasAReplicar.splice(estaCheckeadoIndex, 1);
        }
      } else {
        _state.diasAReplicar.push(value);

        console.log('ver > ', _state.diasAReplicar);
      }

      if (semanaLaboralSelected(_state.diasAReplicar)) {
        _state.frecuencia = frecuenciaOptions[0].key;
      }

      getCantAReplicar();
      setState(_state);
    }
  };

  const checkFrecuenciaByValue = (val: string) => {
    if (state) return state.diasAReplicar.find((elem) => val === elem) != null;
  };

  const handleRbtnFrecuencia = (value: string) => {
    if (state) {
      const _state = { ...state };

      _state.diasAReplicar = [];

      if (value === '1') {
        _state.diasAReplicar.push(DIA_LUNES);
        _state.diasAReplicar.push(DIA_MARTES);
        _state.diasAReplicar.push(DIA_MIERCOLES);
        _state.diasAReplicar.push(DIA_JUEVES);
        _state.diasAReplicar.push(DIA_VIERNES);
      }

      if (value === '2') {
        let nroDia: number | null = null;
        if (props.activity.getStart() instanceof Date) {
          nroDia = props.activity.getStart().getDay();
        }
        if (nroDia) {
          _state.diasAReplicar.push(nroDia.toString());
        }
      }

      // si selecciona la replicacion x mes deshabilita los dias
      if (value === '3') {
        _state.diasAReplicar = [];
      }

      _state.frecuencia = value;

      setState(_state);
    }
  };

  const handleToggleSwitch = (name: string) => {
    if (state) {
      const _state = { ...state };
      _state[name] = !_state[name];
      setState(_state);
    }
  };

  const onClickReplicar = async () => {
    if (state) {
      const dataToPost: ReplicateActivityData = {
        desde: state.desde,
        hasta: state.hasta,
        frecuencia: state.frecuencia,
        diasAReplicar: state.diasAReplicar,
        horaInicio: DateTime.fromJSDate(state.horaDesde).toFormat('HH:mm'),
        horaFin: DateTime.fromJSDate(state.horaHasta).toFormat('HH:mm'),
        taggeaEquipo: state.taggeaEquipo,
      };
      if (tipoReplicacionMensual && typeof diaDelMes === 'number') {
        dataToPost.diaDelMes = diaDelMes;
        dataToPost.anteriorDiaHabil = repMensualAnteriorDiaHabil;
      }
      props.changeBlockUI(true);
      try {
        let res = await props.system.getActivitySystem().replicate(props.activity, dataToPost);
        props.refreshEvents();
        // @ts-ignore
        let cantReplicadasConExito = res.data;
        if (cantReplicadasConExito === cantAReplicar) {
          props.courier.messageSuccess(t('Se replicaron todas las actividades de manera exitosa'));
        } else {
          props.courier.messageSuccess(
            t('De las ') +
              cantAReplicar +
              t(' actividades previstas, se crearon solamente ') +
              cantReplicadasConExito +
              t(' actividades de manera exitosa')
          );
        }
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        props.changeBlockUI(false);
      }
    }
  };

  const getSelectedKeyFrecuencia = () => {
    if (state) {
      let _f = frecuenciaOptions.find((f) => f.key === state.frecuencia);
      if (_f) return _f.key;
      return '';
    }
  };

  const composeDate = (timeSelected: string | number, name: string) => {
    if (state) {
      let _startActivity = DateTime.fromJSDate(props.activity.getStart()).toFormat('yyyy-MM-dd');
      let strDate = _startActivity + 'T' + timeSelected + ':00';
      let date = DateTime.fromISO(strDate).toJSDate();
      if (name === 'horaDesde') {
        setState({ ...state, horaDesde: date });
      }
      if (name === 'horaHasta') {
        setState({ ...state, horaHasta: date });
      }
    }
  };

  const getPositionDay = () => `(o el ${repMensualAnteriorDiaHabil ? 'anterior' : 'próximo'} día hábil)`;

  const stylesCustoms = mergeStyleSets({
    inputDayMonth: {
      width: 50,
      display: 'inline-block',
    },
    rbt: {
      display: 'inline-block',
      position: 'relative',
      bottom: 8,
    },
    chk: {
      display: 'inline-block',
      marginLeft: 10,
    },
    chkDays: {
      marginTop: 10,
    },
    textActivities: { marginTop: 15 },
  });

  const optionsFrecuenciaMensual = () => {
    let ret: IChoiceGroupOption[] = [
      {
        key: 'diaDelMes',
        text: '',
        onRenderField: (props, render) => {
          return (
            <>
              {render!(props)}
              <div className={stylesCustoms.rbt}>
                {t('El')}{' '}
                <TextField
                  underlined
                  className={stylesCustoms.inputDayMonth}
                  value={diaDelMes.toString()}
                  onChange={(e: any) => {
                    const val = e.target.value;
                    onDiaDelMesChange(val && !isNaN(+val) ? +val : '');
                  }}
                ></TextField>{' '}
                {getPositionDay()}
                {tipoReplicacionMensual === 'diaDelMes' && (
                  <div className={stylesCustoms.chk}>
                    <Checkbox
                      label={t('Anterior día hábil')}
                      checked={repMensualAnteriorDiaHabil}
                      onChange={() => setRepMensualAnteriorDiaHabil(!repMensualAnteriorDiaHabil)}
                    />
                  </div>
                )}
              </div>
            </>
          );
        },
      },
      {
        key: 'diaDeLaSemana',
        text: mensualLabel,
      },
    ];
    return ret;
  };

  return (
    <DialogTemplate
      showDialog={props.showDialog}
      toggleShowDialog={() => props.toggleShowDialog()}
      title={t('Selecciona la frecuencia de la actividad a replicar')}
      onAccept={() => onClickReplicar()}
      onCancel={() => props.toggleShowDialog()}
      acceptText={t('Replicar')}
      cancelText={t('Cancelar')}
      minWidth={650}
      validateAccept={validateReplicarBtn}
    >
      <>
        {state && (
          <div className="ms-Grid">
            <div className="ms-Grid-row">
              <div className="display-flex">
                <div className="ms-Grid-col ms-sm4">
                  <ChoiceGroup
                    selectedKey={getSelectedKeyFrecuencia()}
                    options={frecuenciaOptions}
                    onChange={(ev, option) => {
                      if (option) handleRbtnFrecuencia(option.key);
                    }}
                    label={t('Frecuencia')}
                  />
                </div>
                <div className="ms-Grid-col ms-sm8">
                  {state.frecuencia === '2' &&
                    frecuenciaDiariaOptions.map((option, i) => {
                      const frecuenciaChecked = checkFrecuenciaByValue(option.value);
                      return (
                        <Checkbox
                          key={i}
                          label={option.label}
                          checked={frecuenciaChecked}
                          className={stylesCustoms.chkDays}
                          onChange={() => handleChangeFrecuenciaDias(option.value)}
                        />
                      );
                    })}
                  {state.frecuencia === '3' && (
                    <ChoiceGroup
                      selectedKey={tipoReplicacionMensual}
                      options={optionsFrecuenciaMensual()}
                      onChange={(ev, option) => {
                        if (option && (option.key === 'diaDelMes' || option.key === 'diaDeLaSemana'))
                          onChangeTipoReplicacionMensual(option.key);
                      }}
                      label={t('Frecuencia')}
                    />
                  )}
                </div>
              </div>
            </div>
            {state.frecuencia === '1' && (
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <Text block variant="medium" className={stylesCustoms.textActivities}>
                    <Icon iconName="Info" className="text-icon" />
                    En la replicación diaria no se incluyen los dias sábados y domingo.
                  </Text>
                </div>
              </div>
            )}

            <div className="ms-Grid-row">
              <div className="display-flex">
                <div className="ms-Grid-col ms-sm6">
                  <DatePicker
                    strings={getLocalizedStrings()}
                    showWeekNumbers={true}
                    firstWeekOfYear={1}
                    label={t('Inicio')}
                    className={styles.inputCalendarReplicate}
                    showMonthPickerAsOverlay={true}
                    formatDate={DateCustom.formatDateForDatePicker}
                    onSelectDate={(e) => handleDateChangeWrapper(e, 'desde')}
                    value={state.desde}
                    minDate={getMinDate(props.config, props.activity)}
                    maxDate={
                      props.activity.getProject()
                        ? props.activity.getProject()!.getEnd().toJSDate()
                        : undefined
                    }
                  />
                </div>
                <div className="ms-Grid-col ms-sm6">
                  <DatePicker
                    strings={getLocalizedStrings()}
                    showWeekNumbers={true}
                    firstWeekOfYear={1}
                    label={t('Fin')}
                    className={styles.inputCalendarReplicate}
                    showMonthPickerAsOverlay={true}
                    onSelectDate={(e) => handleDateChangeWrapper(e, 'hasta')}
                    formatDate={DateCustom.formatDateForDatePicker}
                    value={state.hasta}
                    minDate={getMinDate(props.config, props.activity)}
                    maxDate={
                      props.activity.getProject()
                        ? props.activity.getProject()!.getEnd().toJSDate()
                        : undefined
                    }
                  />
                </div>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="display-flex">
                <div className="ms-Grid-col ms-sm3">
                  <TimeSelector
                    label={t('Hora inicio')}
                    onChange={(selected: string | number) => composeDate(selected, 'horaDesde')}
                    date={state.horaDesde}
                  ></TimeSelector>
                </div>
                <div className="ms-Grid-col ms-sm3">
                  <TimeSelector
                    label={t('Hora fin')}
                    showPostDate={true}
                    date={state.horaHasta}
                    onChange={(selected: string | number) => composeDate(selected, 'horaHasta')}
                    datePre={state.horaDesde}
                  ></TimeSelector>
                </div>
                <div className="ms-Grid-col ms-sm6">
                  {props.activity.getCollaboratorActivityList().length > 1 && (
                    <Toggle
                      label={t('Taggear a todo el equipo')}
                      checked={state.taggeaEquipo}
                      onChange={() => handleToggleSwitch('taggeaEquipo')}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col">
                <Text block variant="medium" className={stylesCustoms.textActivities}>
                  <Icon iconName="AccountActivity" className="text-icon" />
                  {cantAReplicar && cantAReplicar > 0
                    ? t('Se crearán ') + cantAReplicar + t(' actividades')
                    : t('No se creará ninguna actividad')}
                </Text>
                {/* <Text block variant="small" className={stylesCustoms.textActivities}>
                  <Icon iconName="Warning" className="text-icon" />
                  {t(
                    'AVISO: Si los integrantes del equipo ya han cargado sus 8 horas en algún dia de la replicación no se creará la actividad'
                  )}
                </Text> */}
              </div>
            </div>
          </div>
        )}
      </>
    </DialogTemplate>
  );
};
