import * as React from 'react';
import {
  Callout, Text, DirectionalHint,
  Icon,
  TextField, Facepile,
  OverflowButtonType,
  PersonaSize,
  IFacepilePersona,
  IComboBoxOption
} from '@fluentui/react';
import { ActionButton, CommandBarButton, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { DateCustom } from '../../lib/DateCustom';
import { IIconProps } from '@fluentui/react';
import './Calendar.scss';
import TTSEvent from './TTSEvent';
import { styles } from './CalendarStyles';
import { Activity } from '../../system/activity/Activity';
import { IProjectDescriptor } from '../../system/projects/ProjectDescriptor';
import { useTranslation } from 'react-i18next';
import { DateSelector } from '../Common/DateSelector';
import { ErrorIntermediate } from '../Common/ErrorIntermediate';
import { ActivityValidations } from './ActivityDialog/ActivityValidations';
import { User } from '../../system/User';
import ComboBoxSearch from '../Common/ComboBoxSearch';
import SystemConfiguration from '../../system/configuration/SystemConfiguration';
import { getMinDate } from '../../lib/DatePickerUtils';

interface ICalendarNewActivityCallout {
  validations: ActivityValidations;
  activity: Activity;
  user: User;
  projectOptions: IComboBoxOption[];
  eventData: any;
  openDialog: () => void;
  labelId: string;
  descriptionId: string;
  targetId: string;
  toggleIsCalloutVisible: () => void;
  onChangeActivity: (e: any) => void;
  onChangeDate: (e: any) => void;
  onChangeDropdown: (name: string, option: any) => void;
  onSave: () => void;
  updateActivity: () => void;
  submitted: boolean;
  onSubmit: (v: boolean) => void;
  config?: SystemConfiguration;
}

export const CalendarNewActivityCallout = (props: ICalendarNewActivityCallout) => {
  const groupIcon: IIconProps = { iconName: 'Group' };
  const attachIcon: IIconProps = { iconName: 'Attach' };
  const { t } = useTranslation();

  const onChange = (e: any) => {
    props.onChangeActivity(e);
  };

  const projectSelected: IProjectDescriptor | null = React.useMemo(() => {
    return props.activity.getProject();
  }, [props.activity]);

  // Mapea el array que necesita <Facepile> para pasarselo como parametro
  const [CollaboratorsPersona, setCollaboratorsPersona] = React.useState<IFacepilePersona[]>([]);
  React.useEffect(() => {
    let _colaboradorActividadList = props.activity.getCollaboratorActivityList();
    if (_colaboradorActividadList) {
      let _colList = _colaboradorActividadList.map((col) => {
        return {
          // imageUrl: col.getCollaborator(). ? col.colaborador.col_imagen_url : '',
          // imageInitials: col.colaborador.col_iniciales,
          personaName: col.getCollaborator().fullName(),
        };
      });
      setCollaboratorsPersona(_colList);
    } else {
      setCollaboratorsPersona([]);
    }
  }, [props.activity]);

  const overflowButtonProps = {
    ariaLabel: 'More users',
    onClick: (ev: React.MouseEvent<HTMLButtonElement>) => alert('overflow icon clicked'),
  };

  const getSelectedKey = () => {
    let _project = props.activity.getProject();

    if (_project != null) {
      let selectedKey = _project.getId() != null ? _project.getId() : '';
      return [selectedKey];
    } else {
      return [];
    }
  };

  const getOptions = () => {
    if (props.activity.getId() && props.activity.getId() !== 'new') {
      const isInCurrentProjects = props.projectOptions.find((opt) => opt.key === getSelectedKey()[0]);
      if (isInCurrentProjects) {
        return props.projectOptions;
      } else {
        // este caso es especial por si el proyecto ya no está en vigencia
        const project = props.activity.getProject();
        const ret: IComboBoxOption[] = [
          {
            key: project ? project.getId() : '',
            text: project ? project.getTitleProject() : '',
          },
        ];
        return ret;
      }
    } else {
      return props.projectOptions;
    }
  };

  return (
    <Callout
      className={styles.calloutNewEvent}
      ariaLabelledBy={props.labelId}
      ariaDescribedBy={props.descriptionId}
      role="dialog"
      gapSpace={0}
      directionalHint={DirectionalHint.topAutoEdge}
      target={`#${props.targetId}`}
      onDismiss={props.toggleIsCalloutVisible}
      setInitialFocus
    >
      <div className={styles.header}>
        <Icon
          onClick={() => props.openDialog()}
          iconName="FullScreen"
          className="text-icon cursor-pointer text-white"
        />
      </div>
      <div className={styles.container}>
        <Text block variant="xLarge" className={styles.title}>
          <Icon iconName="AccountActivity" className="text-icon" />
          <TextField
            value={props.activity.getName() ? props.activity.getName() : ''}
            onChange={onChange}
            className={styles.input}
            placeholder={t('Name of the activity')}
            name="name"
            underlined
          />
        </Text>
        <div className="ml-3">
          <ErrorIntermediate
            validation={props.validations.name}
            submitted={props.submitted}
            params={props.activity.getName()}
          ></ErrorIntermediate>
        </div>

        {/* <Text block variant="large">
          <Icon iconName="Teamwork" className={styles.iconDropdown} />
          <Dropdown
            onChange={(e, option) => props.onChangeDropdown('proid', option)}
            placeholder={t('Projects')}
            className={styles.inputDropdown}
            options={props.projectOptions}
          />
        </Text> */}

        <Text block variant="large">
          <Icon iconName="Teamwork" className={styles.iconComboBox} />
          <ComboBoxSearch
            className={styles.inputDropdown}
            options={getOptions()}
            selected={getSelectedKey()}
            onSelectOption={(option: IComboBoxOption) => props.onChangeDropdown('proid', option)}
            disabled={props.activity.getId() && props.activity.getId() !== 'new' ? true : false}
          />
        </Text>

        <div className="ml-3">
          <ErrorIntermediate
            validation={props.validations.project}
            submitted={props.submitted}
            params={props.activity.getProject()}
          ></ErrorIntermediate>
        </div>

        <div className={styles.dateSelectorCallout}>
          <DateSelector
            defaultStartDate={props.activity.getStart()}
            defaultDuration={props.activity.getHours()}
            onChangeDuration={(valueDuration: any) => {
              const durationInHours = DateCustom.convertMillisToHours(valueDuration);
              props.activity.setHours(durationInHours);
              props.activity.getCollaboratorActivityList().forEach((colact) => {
                colact.setHoras(durationInHours);
              });
              props.updateActivity();
            }}
            onChangeStart={(valueStart) => {
              props.activity.setStart(valueStart);
              props.updateActivity();
            }}
            onChangeEnd={(valueEnd: any) => {
              props.activity.setEnd(valueEnd);
              props.updateActivity();
            }}
            minDate={getMinDate(props.config, props.activity)}
            maxDate={projectSelected != null ? projectSelected.getStart().toJSDate() : undefined}
          />

          <div className="ml-3">
            {/* <ErrorIntermediate
            validation={props.validations.dateInProjectRange}
            submitted={props.submitted}
            params={(props.activity.getProject(), props.activity.getStart())}
          ></ErrorIntermediate> */}
          </div>
          <div className="ml-3">
            <ErrorIntermediate
              validation={props.validations.duration}
              submitted={props.submitted}
              params={props.activity.getHours()}
            ></ErrorIntermediate>
          </div>
        </div>

        <div className="padding-to-div-for-button">
          <CommandBarButton
            className={styles.btnCommand}
            iconProps={attachIcon}
            text={t('Add documents')}
            onClick={() => props.openDialog()}
          />
        </div>

        <div className="padding-to-div-for-button">
          <CommandBarButton
            className={styles.btnCommand}
            iconProps={groupIcon}
            text={t('Edit team')}
            onClick={() => props.openDialog()}
          />
        </div>

        {props.activity.getCollaboratorActivityList().length > 0 && (
          <div className="padding-to-div-for-team">
            <Facepile
              className={styles.facepileTeam}
              personas={CollaboratorsPersona}
              personaSize={PersonaSize.size32}
              maxDisplayablePersonas={10}
              overflowButtonProps={{ ariaLabel: 'Ver más' }}
              overflowButtonType={OverflowButtonType.descriptive}
            />
          </div>
        )}
      </div>
      <div className={styles.footerNewEvent}>
        <PrimaryButton
          text={t('Accept')}
          onClick={() => {
            props.onSubmit(true);
            if (props.validations.all(props.activity, props.user)) props.onSave();
          }}
          allowDisabledFocus
        />
        <DefaultButton
          text={t('Cancel')}
          onClick={() => props.toggleIsCalloutVisible()}
          allowDisabledFocus
          className={styles.defaultButtonControl}
        />
        <ActionButton className={styles.moreOptions} allowDisabledFocus onClick={() => props.openDialog()}>
          {t('More options')}
        </ActionButton>
      </div>
    </Callout>
  );
};
