import React, { useRef } from 'react';
import { Text, Icon } from '@fluentui/react';

// import logo from '../logo.svg';
import { useTranslation } from 'react-i18next';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import DialogTemplate from './Common/DialogTemplate';
import newsJSON from '../news/news.json';
import { APP_VERSION, routes } from '../common/constants';

interface INew {
  update: string;
  updateNews: {
    title: string;
    description: string;
    mediaHelperURL: string;
  };
}

interface INewsComponent {
  openNewsDialog: boolean;
  setOpenNewsDialog: (v: boolean) => void;
}

const News = (props: INewsComponent) => {
  const { t } = useTranslation();

  // const [openNewsDialog, props.setOpenNewsDialog] = React.useState(false);
  const myRefs = useRef<any>([]);

  React.useEffect(() => {
    //updateLS viene del LocalStorage
    let updateLS = localStorage.getItem('updateNews');
    let _updateNews = {
      news: [
        {
          update: APP_VERSION,
          readed: false,
        },
      ],
    };

    if (updateLS) {
      _updateNews = JSON.parse(updateLS);
      const update = _updateNews.news.find((notice) => notice.update === APP_VERSION);
      if (update) {
        if (!update.readed) {
          // aca pasa cuando no vio las novedades
          props.setOpenNewsDialog(true);
        }
      } else {
        // aca pasa la primera vez que carga la aplicacion con una version nueva
        _updateNews.news.push({
          update: APP_VERSION,
          readed: false,
        });
        props.setOpenNewsDialog(true);
      }
    } else {
      // aca pasa la primera vez
      props.setOpenNewsDialog(true);
    }

    localStorage.setItem('updateNews', JSON.stringify(_updateNews));
  }, []);

  const readNews = () => {
    props.setOpenNewsDialog(false);
    let updateLS = localStorage.getItem('updateNews');
    if (updateLS) {
      let _updateNews = JSON.parse(updateLS);
      _updateNews.news.forEach((notice) => {
        if (notice.update === APP_VERSION) {
          notice.readed = true;
        }
      });
      localStorage.setItem('updateNews', JSON.stringify(_updateNews));
    }
  };

  const currentUpdate = React.useMemo(() => {
    return newsJSON.news.find((update) => update.update === APP_VERSION);
  }, []);

  const isVideo = (url) => {
    let formatosVideo = /\.(mp4|avi|wmv|mov|flv)$/i;
    return formatosVideo.test(url);
  };

  const isImage = (url) => {
    let formatosImagen = /\.(jpg|jpeg|png|gif|bmp)$/i;
    return formatosImagen.test(url);
  };

  const toggleFullscreen = (el) => {
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
  };

  return (
    <DialogTemplate
      showDialog={props.openNewsDialog && !!currentUpdate}
      toggleShowDialog={() => readNews()}
      title={t('Ultimas novedades')}
      onAccept={() => readNews()}
      hideCancelButton={true}
      onCancel={() => {}}
      acceptText={t('Entendido')}
      cancelText={t('')}
      minWidth={600}
    >
      <div className="align-center">
        {currentUpdate?.updateNews.map((updateNew, i) => {
          return (
            <div className="mt-2">
              <Text variant="large" className="fw-500 d-block text-center">
                <Icon iconName="news" className="text-icon"></Icon>
                {updateNew.title}
              </Text>
              <Text variant="medium" className="d-block mt-2 mb-2">
                {updateNew.description}
              </Text>
              {updateNew.url && (
                <div className="text-center">
                  {isVideo(updateNew.url) && (
                    // <div className="text-center">
                    //   <video
                    //     muted={updateNew.muted}
                    //     autoPlay={updateNew.autoplay}
                    //     controls
                    //     loop
                    //     className=" max-width-50 text-center d-block m-auto mt-2"
                    //     ref={(el) => (myRefs.current[i] = el)}
                    //   >
                    //     <source src={updateNew.url} type="video/mp4"></source>
                    //   </video>
                    //   <span className="link-styles" onClick={() => toggleFullscreen(myRefs.current[i])}>
                    //     Ver en pantalla completa
                    //   </span>
                    // </div>
                    <div>
                      <a
                        style={{ textAlign: 'center' }}
                        href={`${routes.help}?video=${updateNew.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={updateNew.image} alt="subida-de-archivos" className="tutorial-video" />
                      </a>
                    </div>
                  )}
                  {isImage(updateNew.url) && (
                    <img className="max-width-50 d-block" src={updateNew.url} alt="" />
                  )}
                </div>
              )}

              <hr />
            </div>
          );
        })}
      </div>
    </DialogTemplate>
  );
};

export default News;
