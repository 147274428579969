import XhrRequestHandler, { getQueryParamsExpression, QueryParam } from '../../lib/XhrRequestHandler';
import { Company, CompanyJson } from './Company';
import { Currency } from './Currency';

export class CompanySystem {
  private readonly base = '/empresas';

  constructor(private requestHandler: XhrRequestHandler) {}

  async getEmpresaById(id: string) {
    let ret = await this.requestHandler.get<CompanyJson>(`${this.base}/${id}`);
    return Company.fromJson(ret);
  }

  async getList(params?: QueryParam[]) {
    const query = getQueryParamsExpression(params);
    let ret = await this.requestHandler.get<CompanyJson[]>(`${this.base}${query}`);
    return ret.map((emp) => Company.fromJson(emp));
  }

  async mainCompanyCurrency(): Promise<Currency> {
    const data = await this.requestHandler.get<ICurrency>(`${this.base}/getMonedaEmpresaPrincipal`);

    return Currency.identified(
      data.moneda_id,
      data.moneda_denominacion,
      data.moneda_simbolo,
      +data.moneda_cotiz_us_dollar
    );
  }
}

interface ICurrency {
  moneda_denominacion: string;
  moneda_id: string;
  moneda_simbolo: string;
  moneda_cotiz_us_dollar: string;
}

export default CompanySystem;
