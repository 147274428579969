import { ProjectsSystem } from '../projects/ProjectsSystem';
import { SystemDocument } from './SystemDocument';
import { ProjectRelatedDocument } from './ProjectRelatedDocument';
import { IStoredProjectActivityDocument } from './DocumentsSystem';
import Project from '../projects/Project';

export class ActivityDocument extends ProjectRelatedDocument {
  private id: string;

  protected constructor(system: ProjectsSystem, document: SystemDocument, project: Project, id: string) {
    super(system, document, project);
    this.id = id;
  }

  static fromIStoredProjectActivityDocument(
    system: ProjectsSystem,
    systemDocument: SystemDocument,
    project: Project,
    doc: IStoredProjectActivityDocument
  ) {
    return new this(system, systemDocument, project, doc.dxa_id);
  }

  getId() {
    return this.id;
  }

  canDeactivate(): boolean {
    return false;
  }
}
