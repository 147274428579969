import { DateTime } from 'luxon';

export class DefenseFileBySettlement {
  private constructor(
    private id: string,
    private from: DateTime,
    private to: DateTime,
    private state: string
  ) {}

  getId() {
    return this.id;
  }

  getFrom() {
    return this.from.toJSDate();
  }

  getTo() {
    return this.to.toJSDate();
  }

  getState() {
    return this.state;
  }

  static from(id: string, from: DateTime, to: DateTime, state: string) {
    return new this(id, from, to, state);
  }
}
