import ProjectCompanySaved from './ProjectCompanySaved';
import { ProjectsSystem, ProjectJson } from './ProjectsSystem';
import ProjectTeam from './ProjectTeam';
import { DateTime } from 'luxon';
import { Collaborator } from '../collaborators/Collaborator';
import DocumentUploadControlVisitor from './DocumentUploadControlVisitor';
import DocumentUploadControl, { IDocumentControlSaved } from '../documents/DocumentUploadControl';
import DocumentUploadControlConfirmed from '../documents/DocumentUploadControlConfirmed';
import { ProjectCompanyDistribution } from './ProjectCompanyDistribution';
import { ProjectAssertions } from './ProjectAssertions';
import ProjectDescriptor, { IProjectDescriptor } from './ProjectDescriptor';
import { OrganizationChartNode } from '../organization_chart/OrganizationChartNode';

class Project implements IProjectDescriptor {
  private documentUploadControls: DocumentUploadControl[];

  protected constructor(
    private descriptor: ProjectDescriptor,
    private ownerColid: string,
    private deadline: DateTime | null,
    private hoursIndicator: number,
    private allowExceedHours: boolean,
    private hoursTotal: number,
    private projectTeam: ProjectTeam,
    private projectCompanyDistribution: ProjectCompanyDistribution,
    private organizationChartNode: OrganizationChartNode
  ) {
    this.documentUploadControls = [];
  }

  static build(
    descriptor: ProjectDescriptor,
    ownerColid: string,
    deadline: DateTime | null,
    hoursIndicator: number,
    allowExceedHours: boolean,
    hoursTotal: number,
    projectTeam: ProjectTeam,
    projectCompanyDistribution: ProjectCompanyDistribution,
    organizationNode: OrganizationChartNode
  ) {
    const assertions = ProjectAssertions.build();
    assertions.evaluateAllAssertions({
      descriptor,
      ownerColid,
      deadline,
      hoursIndicator,
      allowExceedHours,
      hoursTotal,
      projectTeam,
      projectCompanyDistribution,
      organizationNode,
    });
    return new this(
      descriptor,
      ownerColid,
      deadline,
      hoursIndicator,
      allowExceedHours,
      hoursTotal,
      projectTeam,
      projectCompanyDistribution,
      organizationNode
    );
  }

  static buildAsserted(
    descriptor: ProjectDescriptor,
    ownerColid: string,
    deadline: DateTime | null,
    hoursIndicator: number,
    allowExceedHours: boolean,
    hoursTotal: number,
    projectTeam: ProjectTeam,
    projectCompanyDistribution: ProjectCompanyDistribution,
    organizationNode: OrganizationChartNode
  ) {
    return new this(
      descriptor,
      ownerColid,
      deadline,
      hoursIndicator,
      allowExceedHours,
      hoursTotal,
      projectTeam,
      projectCompanyDistribution,
      organizationNode
    );
  }

  static async fromJson(system: ProjectsSystem, project: ProjectJson) {
    const dateParser = system.getDateParser();

    let equipo: ProjectTeam | null = null;
    if (!project.equipo) {
      equipo = await system.getProjectTeamIdentifiedBy(project.pro_eqid!);
    } else {
      equipo = ProjectTeam.fromIProjectTeam(system.getCollaboratorSystem(), project.equipo);
    }

    const organizationChartNodes = await system.getOrganizationChartSystem().getAllNodes();
    let organizationChartNode = organizationChartNodes.find((node) => node.getId() === project.pro_norid);

    organizationChartNode = organizationChartNode
      ? organizationChartNode
      : await system.getOrganizationNode(project.pro_norid);

    let projectCompanyList: ProjectCompanySaved[] = [];
    if (project.empresaXProyectoList) {
      projectCompanyList = project.empresaXProyectoList.map((exp) => ProjectCompanySaved.fromJson(exp));
    }

    const descriptor = ProjectDescriptor.fromJson(project, dateParser);

    const result = this.buildAsserted(
      descriptor,
      project.pro_owner_colid,
      project.pro_deadline ? dateParser.parseNoTime(project.pro_deadline) : null,
      project.pro_ind_horas,
      project.pro_permite_exceder_horas,
      project.pro_total_horas,
      equipo,
      ProjectCompanyDistribution.forCompaniesAsserted(projectCompanyList, !!project.pro_facturable),
      organizationChartNode
    );

    if (project.controlCarga) {
      const controls = await Promise.all(
        project.controlCarga.map((item) =>
          system.mapIDocumentControlSaved(item as IDocumentControlSaved, result)
        )
      );
      controls.forEach((control) => result.addDocumentControl(control));
    }

    return result;
  }

  isIdentifiedBy(anId: string): boolean {
    return this.descriptor.isIdentifiedBy(anId);
  }

  toJson(): ProjectJson {
    let _projectTeam = this.getProjectTeam();
    const descriptorJson = this.descriptor.asJson();

    const ret: ProjectJson = {
      ...descriptorJson,
      pro_ind_horas: this.hoursIndicator,
      pro_owner_colid: this.ownerColid,
      pro_eqid: _projectTeam.getId(),
      pro_permite_exceder_horas: this.allowExceedHours,
      pro_total_horas: this.hoursTotal,
      pro_deadline: this.deadline ? String(this.deadline.toUTC().toISO()) : '',
      pro_norid: this.organizationChartNode.getId(),
      equipo: _projectTeam.toIProjectTeam(_projectTeam),
      empresaXProyectoList: this.projectCompanyDistribution.asJson(),
      controlCarga: this.mapDocumentControls((control) => control.toIDocumentControl()),
    };

    return ret;
  }

  visitDocumentsUploadControls(visitor: DocumentUploadControlVisitor) {
    this.documentUploadControls.forEach((control) => control.accept(visitor));
  }

  getIProjectCompanyList(_dbList: ProjectCompanySaved[]) {
    return _dbList.map((_db) => ProjectCompanySaved.toJson(_db));
  }

  getTitleProject(): string {
    return this.descriptor.getTitleProject();
  }

  async collaborators(): Promise<Collaborator[]> {
    const team = this.getProjectTeam();
    return team.collaborators();
  }

  addDocumentControl(control: DocumentUploadControl) {
    this.documentUploadControls.push(control);
  }

  getDocumentControls() {
    return this.documentUploadControls;
  }

  mapDocumentControls<T = any>(mapClosure: (control: DocumentUploadControl, index: number) => T): T[] {
    return this.documentUploadControls.map(mapClosure);
  }

  mapDocumentControlsForList() {
    return this.mapDocumentControls((control, i) => {
      let uploaded = false;
      let expired = false;
      if (control instanceof DocumentUploadControlConfirmed) {
        uploaded = true;
      } else {
        if (control.getLimitDate() < DateTime.fromJSDate(new Date())) {
          expired = true;
        }
      }
      return {
        documentTypeName: control.getDocumentType().getName(),
        responsible: control.getCollaborator().fullName(),
        concept: control.getConcept(),
        limitDate: control.getLimitDate().toFormat('dd/LL/yyyy'),
        uploaded: uploaded,
        expired: expired,
      };
    });
    // return this.documentUploadControls.map((docUpload) => {
    //   return {
    //     documentTypeName: docUpload.documentType.getName(),
    //     collaboratorResponsible: docUpload.responsible.fullName(),
    //     concept: docUpload.concept,
    //   };
    // });
  }

  getId() {
    return this.descriptor.getId();
  }
  getCode() {
    return this.descriptor.getCode();
  }
  getName() {
    return this.descriptor.getName();
  }
  getState(): string {
    return this.descriptor.getState();
  }
  getCanEditDistribution() {
    return this.descriptor.getCanEditDistribution();
  }
  getCanViewDistribution() {
    return this.descriptor.getCanViewDistribution();
  }
  getStart() {
    return this.descriptor.getStart();
  }
  getEnd() {
    return this.descriptor.getEnd();
  }
  getBillable() {
    return this.descriptor.getBillable();
  }
  getActive() {
    return this.descriptor.getActive();
  }
  getDescription() {
    return this.descriptor.getDescription();
  }
  getSettlementType() {
    return this.descriptor.getSettlementType();
  }

  getSuggestTags() {
    return this.descriptor.getSuggestTags();
  }

  getDocumentClassificationEnabled(): boolean {
    return this.descriptor.getDocumentClassificationEnabled();
  }

  getOwnerColid() {
    return this.ownerColid;
  }
  getDeadline() {
    return this.deadline;
  }
  getHoursIndicator() {
    return this.hoursIndicator;
  }
  getConfidentialDocumentsByDefault(): boolean {
    return this.descriptor.getConfidentialDocumentsByDefault();
  }
  getAllowExceedHours() {
    return this.allowExceedHours;
  }
  getHoursTotal() {
    return this.hoursTotal;
  }
  getProjectTeam() {
    return this.projectTeam;
  }

  getProjectCompanyList() {
    return this.projectCompanyDistribution;
  }

  getOrganizationChartNode(): OrganizationChartNode {
    return this.organizationChartNode;
  }

  setOwnerColid(value: string) {
    this.ownerColid = value;
  }

  setDeadline(value: DateTime) {
    this.deadline = value;
  }

  setHoursIndicator(value: number) {
    this.hoursIndicator = value;
  }
  setAllowExceedHours(value: boolean) {
    this.allowExceedHours = value;
  }

  setHoursTotal(value: number) {
    this.hoursTotal = value;
  }

  isInRange(date: DateTime): boolean {
    return this.descriptor.isInRange(date);
  }

  isOwner(col: Collaborator): boolean {
    return col.isIdentifiedBy(this.ownerColid);
  }
}

export default Project;
