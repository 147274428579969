import { CostPerHourAssertions } from './CostPerHourAssertions';
import { ICostPerHour } from './CostPerHourSystem';

export interface CostPerHourJson {
  rcp_id: string;
  rcp_periodo_anio: string;
  rcp_periodo_mes: string;
  rcp_costo_hora: string;
  rcp_colid: string;
  rcp_coef_valor_agr: string;
  rcp_nombre_valor_agr: string;
}

export class CostPerHour {
  protected constructor(
    private id: string,
    private year: string,
    private month: string,
    private costPH: string,
    private colid: string,
    private coef: string,
    private AVName: string
  ) {}

  static build(
    id: string,
    year: string,
    month: string,
    costPH: string,
    colid: string,
    coef: string,
    AVName: string
  ) {
    const assertions = CostPerHourAssertions.build();
    assertions.evaluateAllAssertions({
      id,
      year,
      month,
      costPH,
      colid,
      coef,
      AVName,
    });
    return new this(id, year, month, costPH, colid, coef, AVName);
  }

  toICostPerHour(): ICostPerHour {
    return {
      rcp_id: this.getId(),
      rcp_periodo_anio: this.getYear(),
      rcp_periodo_mes: this.getMonth(),
      rcp_costo_hora: this.getCostPH(),
      rcp_colid: this.getColid(),
      rcp_coef_valor_agr: this.getCoef(),
      rcp_nombre_valor_agr: this.getAVName(),
    };
  }

  toJson(): ICostPerHour {
    let json: ICostPerHour = {
      rcp_id: this.getId(),
      rcp_periodo_anio: this.getYear(),
      rcp_periodo_mes: this.getMonth(),
      rcp_costo_hora: this.getCostPH(),
      rcp_colid: this.getColid(),
      rcp_coef_valor_agr: this.getCoef(),
      rcp_nombre_valor_agr: this.getAVName(),
    };

    return json;
  }

  static fromJson(costPerHour: ICostPerHour): CostPerHour {
    return CostPerHour.build(
      costPerHour.rcp_id,
      costPerHour.rcp_periodo_anio,
      costPerHour.rcp_periodo_mes,
      costPerHour.rcp_costo_hora,
      costPerHour.rcp_colid,
      costPerHour.rcp_coef_valor_agr,
      costPerHour.rcp_nombre_valor_agr
    );
  }

  static fromICostPerHour(costPH: ICostPerHour) {
    return new this(
      costPH.rcp_id,
      costPH.rcp_periodo_anio,
      costPH.rcp_periodo_mes,
      costPH.rcp_costo_hora,
      costPH.rcp_colid,
      costPH.rcp_coef_valor_agr,
      costPH.rcp_nombre_valor_agr
    );
  }

  getYear(): string {
    return this.year;
  }

  getId(): string {
    return this.id;
  }

  getMonth(): string {
    return this.month;
  }

  getCostPH(): string {
    return this.costPH;
  }

  getColid(): string {
    return this.colid;
  }

  getCoef(): string {
    return this.coef;
  }

  getAVName(): string {
    return this.AVName;
  }
}
