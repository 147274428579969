import { Text, Icon } from '@fluentui/react';
import { FontWeights, ITextStyles } from '@fluentui/react';

const textBoldStyle = { fontWeight: FontWeights.semibold };
const boldStyle: Partial<ITextStyles> = {
  root: textBoldStyle,
};

interface IAlertPillFluent {
  title: string;
  iconTitle?: string;
  series: { label: string; value: any; icon?: string }[];
  small?: boolean;
}

export const AlertPillFluent = (props: IAlertPillFluent) => {
  return (
    <>
      {!props.small ? (
        <div className="alert-pill-fluent ms-depth-8">
          <div className="ms-Grid-col ms-md12 pill-fluent-title bg-hat-pill bg-alert-primary">
            <Text
              variant="mediumPlus"
              styles={boldStyle}
              style={{ fontSize: '15px' }}
              className="white-color mt-5"
            >
              {props.title}
              {props.iconTitle && (
                <Icon iconName={props.iconTitle} className={'text-icon-title-big float-right'} />
              )}
            </Text>
          </div>
          {props.series.map((serie, i) => {
            return (
              <div
                className="ms-Grid-col ms-md12 "
                style={{ marginBottom: '8px', marginTop: '8px' }}
                key={`${i}`}
              >
                <Text variant="medium" styles={boldStyle} nowrap={true} block={true}>
                  {serie.icon && <Icon iconName={serie.icon} className={'text-icon'} />}
                  <span className="smooth-label">{serie.label}:</span> {serie.value}
                </Text>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="alert-pill-fluent-small ms-depth-8">
          <div className="ms-Grid-col ms-md12 pill-fluent-title-small bg-hat-pill">
            <Text variant="medium" styles={boldStyle} style={{ fontSize: '15px' }} className="white-color ">
              {props.iconTitle && (
                <Icon iconName={props.iconTitle} className={'text-icon-title-small float-right'} />
              )}
              {props.title}
            </Text>
          </div>
          {props.series.map((serie, i) => {
            return (
              <div className="ms-Grid-col ms-md12 " style={{ marginBottom: '8px', marginTop: '8px' }} key={i}>
                <Text variant="smallPlus" styles={boldStyle} nowrap={true} block={true}>
                  {serie.icon && <Icon iconName={serie.icon} className={'text-icon-small'} />}
                  <span className="smooth-label">{serie.label}:</span> {serie.value}
                </Text>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
