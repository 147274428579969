import XhrRequestHandler from '../lib/XhrRequestHandler';
import { Collaborator } from './collaborators/Collaborator';
import CollaboratorSystem, { ICollaborator } from './collaborators/CollaboratorSystem';
import { SystemDateParser } from './SystemDateParser';
import { User } from './User';
import { UserProfile } from './UserProfile';

export interface IUserProfile {
  per_id: string;
  per_nombre: string;
  per_nemotecnico: string;
}

export interface IQRurl {
  qrUrl: string;
}

export interface ILoggedUser extends IUser {
  representing: ICollaborator;
  perfil: IUserProfile;
}

export interface IUser {
  us_id: string;
  us_email: string;
  us_nombre: string;
}

class AuthenticationSystem {
  private readonly loginBase = '/loginDVM';
  private loggedUser: User | undefined;

  constructor(
    private requestHandler: XhrRequestHandler,
    private dateParser: SystemDateParser,
    private collaboratorsSystem: CollaboratorSystem
  ) {
    this.loggedUser = undefined;
  }

  async loggedInUser(): Promise<User> {
    const data = await this.requestHandler.get<ILoggedUser>(
      `${this.loginBase}/isLoggedIn?tz=${encodeURIComponent(this.dateParser.getUserTimezone())}`
    );

    this.loggedUser = this.userFromILoggedUser(data);

    return this.loggedUser;
  }

  async signOut(returnTo: string) {
    this.requestHandler.clearCache();
    // this.requestHandler.navigateTo(`${this.loginBase}/logout?returnTo=${returnTo}`);
    const data = await this.requestHandler.post<{ redirectTo: string }>(`${this.loginBase}/logout`);
    window.location.href = data.redirectTo;
  }

  private userFromILoggedUser({ representing, perfil, ...user }: ILoggedUser) {
    const collaborator = Collaborator.fromICollaborator(this.collaboratorsSystem, representing);
    const profile = UserProfile.fromIUserProfile(perfil);

    return User.fromIUser(collaborator, profile, user);
  }
}

export default AuthenticationSystem;
