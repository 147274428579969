import React from 'react';
import { Stack, IStackStyles, MessageBar, MessageBarType } from '@fluentui/react';

import Header from './Header';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import MessageCourier from '../../lib/MessageCourier';
import { MessageReceiver } from '../../lib/MessageReceiver';
import SystemClient from '../../system';
import { IFilesUploadTracker } from '../../system/documents/DocumentsUploader';
import { IFileTrackData, FileUploadInfo } from './FileUploadInfo';
import { User } from '../../system/User';
import SystemConfiguration from '../../system/configuration/SystemConfiguration';
import { fileUploadProgressTimeout } from '../../common/constants';
import PeriodDatesBlockedSystem from '../../system/period_dates_blocked/PeriodDatesBlockedSystem';
import Environment from '../../lib/Environment';

const mainStackStyles: Partial<IStackStyles> = {
  root: {
    width: '100%',
    margin: '0 auto',
    // height: '94%',
    paddingTop: '62px',
  },
};

interface IBaseProps {
  courier: MessageCourier;
  user?: User;
  system: SystemClient;
  config: SystemConfiguration;
  changeBlockUI: (v: boolean) => void;
  periodsBlocked: PeriodDatesBlockedSystem;
  environment: Environment;
}

interface IBaseState {
  isSidebarOpen: boolean;
  filesData: IFileTrackData[];
  filesWithErrors: File[];
  abortController?: AbortController;
  message?: string;
  preparingUpload?: boolean;
  messageType?: MessageBarType;
  fileWithUploadProblem?: File;
}

class Base extends React.Component<IBaseProps, IBaseState> implements MessageReceiver, IFilesUploadTracker {
  constructor(props: IBaseProps) {
    super(props);
    this.state = { isSidebarOpen: false, filesData: [], filesWithErrors: [], preparingUpload: false };
    this.onDismissFileUpload = this.onDismissFileUpload.bind(this);
    this.onCancelFileUpload = this.onCancelFileUpload.bind(this);
  }

  componentDidMount(): void {
    if (this.props.courier) {
      this.props.courier.subscribe(this);
    }
    this.props.system.registerFileUploadTracker(this);
  }

  onStartUpload(files: File[], abortController: AbortController): void {
    this.setState({
      filesData: files
        .map((file): IFileTrackData => {
          const fileTrackData = { file, loaded: 0, total: file.size };
          this.resetProgressTimeout(fileTrackData);
          return fileTrackData;
        })
        .concat([...this.state.filesData]),
      abortController,
      preparingUpload: false,
    });
  }

  onPreparingUpload(): void {
    this.setState({ preparingUpload: true });
  }

  onEndUpload(): void {
    // setTimeout(() => {
    //   this.setState({ filesData: [], filesWithErrors: [] });
    // }, 10000);
  }

  onFileProgress(file: File, loaded: number, total: number): void {
    const prev = [...this.state.filesData];
    const f = prev.find((f) => f.file === file);
    if (f) {
      f.loaded = loaded;
      f.total = total;
      this.resetProgressTimeout(f);
    }
    // Se limpia fileWithUploadProblem porque si hay alguna actualización,
    // es porque la carga no está interrumpida.
    this.setState({ filesData: prev, fileWithUploadProblem: undefined });
  }

  private resetProgressTimeout(f: IFileTrackData) {
    this.clearProgressTimeout(f);
    if (f.loaded < f.total) {
      f.timeoutId = setTimeout(() => {
        this.setState({ fileWithUploadProblem: f.file });
      }, fileUploadProgressTimeout);
    }
  }

  private clearProgressTimeout(f: IFileTrackData) {
    if (f.timeoutId) {
      clearTimeout(f.timeoutId);
    }
  }

  onFileUploadError(file: File): void {
    const f = this.state.filesData.find((f) => f.file === file);
    if (f) {
      this.clearProgressTimeout(f);
    }
    const errors = [...this.state.filesWithErrors];
    errors.push(file);
    this.setState({ filesWithErrors: errors });
  }

  receiveError(message: string, _error: unknown): void {
    this.setState({ message, messageType: MessageBarType.error });
    setTimeout(() => {
      this.onDismissMessage();
    }, 30000);
  }

  receiveSuccess(message: string): void {
    this.setState({ message, messageType: MessageBarType.success });
    setTimeout(() => {
      this.onDismissMessage();
    }, 30000);
  }

  private onDismissMessage = () => {
    this.setState({ message: undefined });
  };

  private onDismissFileUpload() {
    this.setState({ filesData: [], filesWithErrors: [] });
  }

  private onCancelFileUpload() {
    if (this.state.abortController) {
      this.state.abortController.abort();
    }
  }

  render() {
    const {
      isSidebarOpen,
      message,
      messageType,
      filesData,
      filesWithErrors,
      fileWithUploadProblem,
      preparingUpload,
    } = this.state;

    return (
      <React.Fragment>
        {this.props.user && (
          <Header
            system={this.props.system}
            onMenuButtonClick={() => this.openSibedar()}
            user={this.props.user}
            changeBlockUI={this.props.changeBlockUI}
            courier={this.props.courier}
            config={this.props.config}
            periodsBlocked={this.props.periodsBlocked}
          />
        )}

        <Stack horizontal styles={mainStackStyles}>
          {/* <Stack.Item> */}
          {this.props.user && (
            <Sidebar
              user={this.props.user}
              isOpen={isSidebarOpen}
              dismissPanel={() => this.closeSibedar()}
              environment={this.props.environment}
            />
          )}
          {/* </Stack.Item>
          <Stack.Item> */}
          <section className="section-container">
            {message && (
              <MessageBar
                messageBarType={messageType}
                isMultiline={false}
                onDismiss={this.onDismissMessage}
                dismissButtonAriaLabel="Close"
              >
                {message}
              </MessageBar>
            )}

            <Outlet />
          </section>
          {/* </Stack.Item> */}
        </Stack>

        <FileUploadInfo
          filesData={filesData}
          filesWithErrors={filesWithErrors}
          fileWithUploadProblem={fileWithUploadProblem}
          preparingUpload={preparingUpload}
          onCancelUpload={this.onCancelFileUpload}
          onDismiss={this.onDismissFileUpload}
        />
        {/* <Footer /> */}
      </React.Fragment>
    );
  }

  private openSibedar() {
    this.setState({ isSidebarOpen: true });
  }

  private closeSibedar() {
    this.setState({ isSidebarOpen: false });
  }
}

export default Base;
