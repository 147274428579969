import { DateTime } from 'luxon';
import { Collaborator } from '../collaborators/Collaborator';
import { Company } from '../company/Company';
import { DefenseFileState, DefenseFileType } from './DefenseFileSystem';
import { t } from 'i18next';

export class DefenseFile {
  protected constructor(
    private id: string,
    private from: DateTime,
    private to: DateTime,
    private type: DefenseFileType,
    private state: DefenseFileState,
    private includesActivities: boolean,
    private uploadedOn: DateTime,
    private uploadedBy: Collaborator,
    private company: Company
  ) {}

  static from(
    id: string,
    from: DateTime,
    to: DateTime,
    type: DefenseFileType,
    state: DefenseFileState,
    includesActivities: boolean,
    uploadedOn: DateTime,
    uploadedBy: Collaborator,
    company: Company
  ) {
    return new this(id, from, to, type, state, includesActivities, uploadedOn, uploadedBy, company);
  }

  getId() {
    return this.id;
  }
  getFrom() {
    return this.from;
  }
  getTo() {
    return this.to;
  }
  getType() {
    return this.type;
  }
  getState() {
    return this.state;
  }
  getIncludesActivities() {
    return this.includesActivities;
  }
  getUploadedOn() {
    return this.uploadedOn;
  }
  getUploadedBy() {
    return this.uploadedBy;
  }
  getCompany() {
    return this.company;
  }

  isProjectType() {
    return this.type === DefenseFileType.project;
  }

  getStatusTitle() {
    if (this.state === DefenseFileState.pendingStructure) return t('Pendiente de estructura');
    if (this.state === DefenseFileState.pendingZip) return t('Pendiente de zip');
    if (this.state === DefenseFileState.zipGenerated) return t('Zip generado');
  }
}
