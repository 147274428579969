import { ProjectsSystem, IStoredProjectSettlementDocument } from '../projects/ProjectsSystem';
import { SystemDocument } from './SystemDocument';
import { ProjectRelatedDocument } from './ProjectRelatedDocument';
import Project from '../projects/Project';

export class SettlementDocument extends ProjectRelatedDocument {
  private id: string;

  protected constructor(system: ProjectsSystem, document: SystemDocument, project: Project, id: string) {
    super(system, document, project);
    this.id = id;
  }

  static fromIStoredProjectSettlementDocument(
    system: ProjectsSystem,
    systemDocument: SystemDocument,
    project: Project,
    doc: IStoredProjectSettlementDocument
  ) {
    return new this(system, systemDocument, project, doc.dxli_id);
  }

  getId() {
    return this.id;
  }

  canDeactivate(): boolean {
    return false;
  }
}
