import SystemUploadedDocument from '../documents/SystemUploadedDocument';

export class SettlementRelatedDocument {
  protected constructor(
    private id: string,
    private active: boolean,
    private document: SystemUploadedDocument
  ) {}

  static identifiedBy(id: string, active: boolean, document: SystemUploadedDocument) {
    return new this(id, active, document);
  }

  getId() {
    return this.id;
  }

  isIdentifiedBy(anId: string): boolean {
    return +this.id === +anId;
  }

  isActive(): boolean {
    return this.active;
  }

  name(): string {
    return this.document.getName();
  }
}
