import * as React from 'react';
import { Validation } from '../../common/Validation';

interface IErrorIntermediate {
  validation: Validation;
  submitted: boolean;
  params: any;
}

export const ErrorIntermediate = (props: IErrorIntermediate) => {
  return (
    <>
      {!props.validation.validate(props.params) && props.submitted && (
        <span className="text-error"> {props.validation.getError()}</span>
      )}
    </>
  );
};
