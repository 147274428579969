import Project from './Project';
import { ProjectsSystem, IStoredProjectFolder } from './ProjectsSystem';
import ProjectFolder from './ProjectFolder';
import { ProjectRelatedDocument } from '../documents/ProjectRelatedDocument';
import { ProjectSystemFolder } from './ProjectSystemFolder';
import LazyInitialization from '../../lib/LazyInitialization';
import i18next, { t } from 'i18next';

/**
 * @TODO sacar código repetido de acá con ProjectCommonFolder
 */
export class ProjectTrashFolder extends ProjectSystemFolder {
  private children: LazyInitialization<ProjectFolder[]>;

  private constructor(system: ProjectsSystem, project: Project, id: string, name: string) {
    super(system, project, id, name);
    this.children = new LazyInitialization(() => system.foldersChildrenOf(this));
  }

  static fromIProjectFolder(system: ProjectsSystem, project: Project, folder: IStoredProjectFolder) {
    return new this(system, project, folder.pca_id, t(folder.pca_nombre));
  }

  getChildren(): Promise<ProjectFolder[]> {
    return this.children.value();
  }

  /**
   * @TODO hacerlo tambien para valores no cacheados
   */
  findChildIdentifiedAs(id: string): ProjectFolder | undefined {
    if (this.isIdentifiedBy(id)) {
      return this;
    }
    return this.children.withCachedValueDo<ProjectFolder | undefined>(
      (children) => {
        for (const child of children) {
          if (child.isIdentifiedBy(id)) {
            return child;
          }
          const possibleFolder = child.findChildIdentifiedAs(id);
          if (possibleFolder) {
            return possibleFolder;
          }
        }
        return undefined;
      },
      () => undefined
    );
  }

  hasChild(child: ProjectFolder): boolean {
    return this.children.withCachedValueDo<boolean>(
      (children) => children.includes(child),
      () => false
    );
  }

  pathUpTo(descendant: ProjectFolder): ProjectFolder[] {
    const path: ProjectFolder[] = [];
    this.collectPathUpToInto(path, descendant);
    return path;
  }

  collectPathUpToInto(path: ProjectFolder[], descendant: ProjectFolder) {
    path.push(this);
    this.children.withCachedValueDo(
      (children) => {
        const nextInPath = children.find((child) => child.hasDescendant(descendant));
        if (nextInPath) {
          nextInPath.collectPathUpToInto(path, descendant);
        }
      },
      () => {}
    );
  }

  hasDescendant(folder: ProjectFolder): boolean {
    return (
      this.hasChild(folder) ||
      this.children.withCachedValueDo<boolean>(
        (children) => children.some((child) => child.hasDescendant(folder)),
        () => false
      )
    );
  }

  resetChildren() {
    return this.children.reload();
  }

  async moveInto(documents: ProjectRelatedDocument[]): Promise<void> {
    for (const document of documents) {
      await document.deactivate();
    }
  }

  addChildNamed(childName: string): Promise<ProjectFolder> {
    throw new Error('Can not add project folder to trash.');
  }

  isTrash(): boolean {
    return true;
  }
}
