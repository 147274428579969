import { DateTime } from 'luxon';
import { DateCustom } from '../../../lib/DateCustom';
import XhrRequestHandler, { getQueryParamsExpression } from '../../../lib/XhrRequestHandler';

interface horasAsignadas {
  cantDiasLaborales: number;
  cantDiasTotales: number;
  horasAsignadas: number;
}

export default class CollaboratorTeamSystem {
  private readonly base = '/colaboradores-equipo';
  constructor(private requestHandler: XhrRequestHandler) {}

  calcularAsignacionDeHoras = async (
    inicio: Date | string,
    fin: Date | string,
    colId: string,
    porcentajeAsignacion: number
  ) => {
    let inicio_formatted: string = '';

    if (inicio instanceof Date) {
      inicio_formatted = DateTime.fromJSDate(inicio).toFormat('dd-LL-yyyy');
    } else {
      const [day, month, year] = inicio.split('/');
      inicio_formatted = [day, month, year].join('-');
    }

    let fin_formatted: string = '';

    if (fin instanceof Date) {
      fin_formatted = DateTime.fromJSDate(fin).toFormat('dd-LL-yyyy');
    } else {
      const [day, month, year] = fin.split('/');
      fin_formatted = [day, month, year].join('-');
    }

    const params = [
      { name: 'col_id', value: colId },
      { name: 'porcentaje_de_asignacion', value: porcentajeAsignacion },
      { name: 'pro_inicio', value: inicio_formatted },
      { name: 'pro_fin', value: fin_formatted },
    ];
    const query = getQueryParamsExpression(params);

    return await this.requestHandler.get<horasAsignadas>(
      `${this.base}/calcular-horas-por-asignacion${query}`
    );
  };
}
