import AssertionEntity from '../../lib/FormValidator/Assertion/AssertionEntity';
import AssertionItem from '../../lib/FormValidator/Assertion/AssertionItem';
import i18next, { t } from 'i18next';

export class ProjectAssertions extends AssertionEntity {
  private constructor() {
    super();
  }

  static build() {
    const instance = new this();

    // instance.addAssertionForDocumentsControlLimitDate();
    instance.addAssertionForOrganizationChartNode();

    return instance;
  }

  addAssertionForOrganizationChartNode(key = 'organizationNode') {
    this.defineAssertionForProperty(
      key,
      AssertionItem.forClosure(
        (value) => {
          return value !== null;
        },
        key,
        t('The organization chart node is required')
      )
    );
  }
}
