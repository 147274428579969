import React from 'react';

interface ISizeInBytesLabelProps {
  size: number;
}
export class SizeInBytesLabel extends React.Component<ISizeInBytesLabelProps> {
  readonly threshold = 1024;
  readonly bytesUnit = 'b';
  readonly higherUnits = ['Kb', 'Mb', 'Gb'];
  formatter: Intl.NumberFormat;

  constructor(props: ISizeInBytesLabelProps) {
    super(props);

    /**
     * @TODO recibir el locale por prop
     * No usar constantes globales
     */
    this.formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }

  labelBySize(size: number): string {
    const result = this.higherUnits.reduce(
      (previous, unit) => (previous.size > this.threshold ? { size: previous.size / 1024, unit } : previous),
      { size, unit: this.bytesUnit }
    );

    return `${this.formatter.format(result.size)} ${result.unit}`;
  }

  render() {
    const { size } = this.props;
    const label = this.labelBySize(size);

    return label;
  }
}
