import * as React from 'react';
import { Dialog, DialogType, DialogFooter, IDialogContentProps } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { IModalProps } from '@fluentui/react/lib/Modal';

const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
// const modalPropsStyles = { main: { minWidth: 900 } };

interface IDialogTemplate {
  showDialog: boolean;
  toggleShowDialog: () => void;
  title: string;
  children: JSX.Element;
  onAccept: () => void;
  onCancel: () => void;
  acceptText: string;
  cancelText: string;
  minWidth?: number;
  maxWidth?: number;
  blocking?: boolean;
  draggable?: boolean;
  validateAccept?: () => boolean;
  hideCancelButton?: boolean;
  hideAcceptButton?: boolean;
}

const DialogTemplate = (props: IDialogTemplate) => {
  const draggable = React.useMemo(() => props.draggable === undefined || props.draggable, [props.draggable]);

  const modalProps = React.useMemo(
    (): IModalProps => ({
      isBlocking: !!props.blocking,
      // styles: modalPropsStyles,
      dragOptions: draggable ? dragOptions : undefined,
      allowTouchBodyScroll: true,
    }),
    [props.blocking, draggable]
  );

  const dialogContentProps = React.useMemo((): IDialogContentProps => {
    return {
      type: DialogType.normal,
      title: props.title,
    };
  }, [props]);

  return (
    <>
      <Dialog
        hidden={!props.showDialog}
        onDismiss={props.toggleShowDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
        minWidth={props.minWidth ? props.minWidth : 400}
        // className="animation-height"
      >
        {props.children}
        <DialogFooter>
          {!props.hideAcceptButton && (
            <PrimaryButton
              disabled={props.validateAccept ? props.validateAccept() : false}
              onClick={props.onAccept}
              text={props.acceptText}
            />
          )}
          {!props.hideCancelButton && <DefaultButton onClick={props.onCancel} text={props.cancelText} />}
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default DialogTemplate;
