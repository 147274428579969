import { DateCustom } from '../../../../lib/DateCustom';
import DialogTemplate from '../../../Common/DialogTemplate';
import { ChoiceGroup } from '@fluentui/react';
import { styles } from '../../CalendarStyles';
import TTSEvent from '../../TTSEvent';
import React from 'react';
import TimeTaxSupportSystemClient from '../../../../system';
import useSystemCall from '../../../../common/custom-hooks/useSystemCall';
import { Activity } from '../../../../system/activity/Activity';
import { useTranslation, Trans } from 'react-i18next';

interface IActivityMultipleOptionsDialog {
  system: TimeTaxSupportSystemClient;
  showDialog: boolean;
  toggleShowDialog: () => void;
  activity: Activity;
  onAcceptCallback: (allAfterThis: boolean, all: boolean) => void;
  acceptText: string;
}

export const ActivityMultipleOptionsDialog = (props: IActivityMultipleOptionsDialog) => {
  const { t, i18n } = useTranslation();
  const SOLO_ESTA = 'justThis';
  const TODAS_A_PARTIR_DE_ESTA = 'allAfterThis';
  const TODAS = 'all';

  const optionsMode = [
    { text: t('Sólo esta'), key: SOLO_ESTA },
    { text: t('Todas las réplicas a partir de esta'), key: TODAS_A_PARTIR_DE_ESTA },
    { text: t('Todas las réplicas'), key: TODAS },
  ];

  const [mode, setMode] = React.useState<string>(SOLO_ESTA);

  const onAcceptCallback = () => {
    if (mode == SOLO_ESTA) props.onAcceptCallback(false, false);
    if (mode == TODAS_A_PARTIR_DE_ESTA) props.onAcceptCallback(true, false);
    if (mode == TODAS) props.onAcceptCallback(false, true);
  };
  return (
    <DialogTemplate
      showDialog={props.showDialog}
      toggleShowDialog={() => props.toggleShowDialog()}
      title={t('Selecciona una opción')}
      onAccept={() => onAcceptCallback()}
      onCancel={() => props.toggleShowDialog()}
      acceptText={props.acceptText}
      cancelText={t('Cancelar')}
      minWidth={600}
    >
      <div className="ms-Grid">
        <div className="ms-Grid-row">
          <div className="display-flex">
            <ChoiceGroup
              selectedKey={mode}
              options={optionsMode}
              onChange={(ev, option) => {
                if (option) setMode(option.key);
              }}
              label={props.acceptText}
            />
          </div>
        </div>
      </div>
    </DialogTemplate>
  );
};
