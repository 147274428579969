import AssertionEntity from '../../lib/FormValidator/Assertion/AssertionEntity';
import AssertionItem from '../../lib/FormValidator/Assertion/AssertionItem';

export class ProjectDescriptorAssertions extends AssertionEntity {
  protected constructor() {
    super();
  }

  static build() {
    const instance = new this();
    instance.addAssertionsForProjectName();
    instance.addAssertionForStart();
    instance.addAssertionForEnd();
    instance.addAssertionForLifetime();
    return instance;
  }

  addAssertionsForProjectName(key = 'name') {
    this.defineAssertionForProperty(
      key,
      AssertionItem.forClosure((value) => value !== '', key, 'Project name cannot be empty')
    );
  }

  addAssertionForStart(key = 'start') {
    this.defineAssertionForProperty(
      key,
      AssertionItem.forClosure((value) => value !== '', key, 'required')
    );
  }

  addAssertionForEnd(key = 'end') {
    this.defineAssertionForProperty(
      key,
      AssertionItem.forClosure((value) => value !== '', key, 'required')
    );
  }

  addAssertionForLifetime() {
    this.defineAssertionForObject(
      AssertionItem.forClosure(
        (value) => {
          return value && value.start && value.end && value.start.toJSDate() < value.end.toJSDate();
        },
        '',
        'Start date must be prior to the end date'
      )
    );
  }
}
