// import { ColaboradorEquipoExtended } from './colaborador-equipo';
import CollaboratorSystem, { ICollaborator } from '../CollaboratorSystem';
import { Collaborator } from '../Collaborator';
import CollaboratorTeam from '../collaborator_team/CollaboratorTeam';
import { User } from '../../User';

export interface ICollaboratorActivity {
  colact_id?: string;
  colact_actid: string;
  colact_checked?: boolean;
  colact_checked_by_colid?: null;
  colact_colid: string;
  colact_fecha_tag?: Date | string;
  colact_horas?: number;
  colact_taggeado?: boolean;
  colaborador: ICollaborator;
  actividad?: null;
  __delete: boolean;
}

class CollaboratorActivity {
  constructor(
    private system: CollaboratorSystem,
    private id: string,
    private checked: boolean,
    private checked_by_colid: null,
    private date_tag: Date | string,
    private horas: number,
    private tagged: boolean,
    private collaborator: Collaborator,
    private __delete: boolean
  ) {}

  static fromICollaboratorActivity(
    collaboratorSystem: CollaboratorSystem,
    collaboratorActivity: ICollaboratorActivity
  ) {
    const collaborator = Collaborator.fromICollaborator(collaboratorSystem, collaboratorActivity.colaborador);
    return new this(
      collaboratorSystem,
      collaboratorActivity.colact_id ? collaboratorActivity.colact_id : '',
      collaboratorActivity.colact_checked ? collaboratorActivity.colact_checked : false,
      collaboratorActivity.colact_checked_by_colid ? collaboratorActivity.colact_checked_by_colid : null,
      collaboratorActivity.colact_fecha_tag ? collaboratorActivity.colact_fecha_tag : '',
      collaboratorActivity.colact_horas ? collaboratorActivity.colact_horas : 0,
      collaboratorActivity.colact_taggeado ? collaboratorActivity.colact_taggeado : false,
      collaborator,
      collaboratorActivity.__delete
    );
  }

  static toICollaboratorActivity(_ca: CollaboratorActivity, act_id: string) {
    let ret: ICollaboratorActivity = {
      colact_id: _ca.getId(),
      colact_actid: act_id,
      colact_checked: _ca.getChecked(),
      colact_checked_by_colid: _ca.getChecked_by_colid(),
      colact_colid: _ca.getCollaborator().getId(),
      colact_fecha_tag: _ca.getDate_tag(),
      colact_horas: _ca.getHoras(),
      colact_taggeado: _ca.getTagged(),
      colaborador: _ca.getCollaborator().toICollaborator(),
      actividad: null,
      __delete: _ca.get__delete(),
    };
    return ret;
  }

  referencesUser(user: User): boolean {
    return user.referencesCollaborator(this.collaborator);
  }

  public getId() {
    return this.id;
  }
  public getChecked() {
    return this.checked;
  }
  public getChecked_by_colid() {
    return this.checked_by_colid;
  }
  public getDate_tag() {
    return this.date_tag;
  }
  public getHoras() {
    return this.horas;
  }
  public getTagged() {
    return this.tagged;
  }
  public getCollaborator(): Collaborator {
    return this.collaborator;
  }
  public get__delete() {
    return this.__delete;
  }

  public setId(value: string) {
    this.id = value;
  }
  public setChecked(value: boolean) {
    this.checked = value;
  }
  public setChecked_by_colid(value: null) {
    this.checked_by_colid = value;
  }
  public setDate_tag(value: Date | string) {
    this.date_tag = value;
  }
  public setHoras(value: number) {
    this.horas = value;
  }
  public setTagged(value: boolean) {
    this.tagged = value;
  }
  public setCollaborator(value: Collaborator) {
    this.collaborator = value;
  }
  public set__delete(value: boolean) {
    this.__delete = value;
  }

  static getCollaboratorActivityFromProjectContext(
    collaboratorSystem: CollaboratorSystem,
    collaboratorsTeam: CollaboratorTeam[],
    hours: number,
    colaboradorLoggeadoId?: string
  ): CollaboratorActivity[] {
    return collaboratorsTeam
      .filter((colTeam) => colTeam.getCollaborator().getActive())
      .map((collaboratorTeam: CollaboratorTeam) => {
        return new CollaboratorActivity(
          collaboratorSystem,
          '',
          colaboradorLoggeadoId === collaboratorTeam.getCollaborator().getId(),
          null,
          '',
          hours,
          colaboradorLoggeadoId !== collaboratorTeam.getCollaborator().getId(),
          collaboratorTeam.getCollaborator(),
          false
        );
      });
  }

  static getNewFromColaborador(
    collaboratorSystem: CollaboratorSystem,
    collaborator: Collaborator,
    hours: number
  ): CollaboratorActivity {
    return new CollaboratorActivity(
      collaboratorSystem,
      '',
      false,
      null,
      '',
      hours,
      true,
      collaborator,
      false
    );
  }
}

// export const getNewFromColaborador = (colaborador: Collaborator, horas: number): ICollaboratorActivity => {
//   return {
//     colaborador: { ...colaborador },
//     __delete: false,
//     colact_actid: 'new',
//     colact_colid: colaborador.col_id,
//     colact_horas: horas,
//     colact_taggeado: true,
//   };
// };

export default CollaboratorActivity;
