import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import 'react-block-ui/style.css';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';

import './App.css';
import './lib/FormValidator/Form/form-validator-styles.css';
import 'devextreme/dist/css/dx.light.css';

import './i18n';

import TimeTaxSupportSystemClient from './system/TimeTaxSupportSystemClient';
import Environment from './lib/Environment';
import MessageCourier from './lib/MessageCourier';
import AppInRouterContext from './AppInRouterContext';
import UpdateNoticeDialog from './Components/Common/UpdateNoticeDialog';
import StandardErrorBoundary from './ErrorBoundary';
import { UserDeviceProvider } from './common/providers/UserDeviceProvider';
import PWAInstallation from './Components/PWAInstallation';

initializeIcons();
initializeFileTypeIcons();
interface IApp {
  newVersionAvailable: boolean;
  onUpdateVersion: () => void;
}

const environment = new Environment();
const system = TimeTaxSupportSystemClient.start(environment);
const appMessageCourier = new MessageCourier();

const App = (props: IApp) => {
  return (
    <React.Fragment>
      <ThemeProvider style={{ height: '100%' }}>
        <UserDeviceProvider>
          <BrowserRouter>
            {props.newVersionAvailable && (
              <UpdateNoticeDialog
                showDialog={props.newVersionAvailable}
                toggleShowDialog={() => props.onUpdateVersion()}
                onAccept={() => props.onUpdateVersion()}
                onCancel={() => props.onUpdateVersion()}
                hideCancelButton={true}
              />
            )}

            <StandardErrorBoundary>
              <AppInRouterContext system={system} environment={environment} courier={appMessageCourier} />
            </StandardErrorBoundary>
          </BrowserRouter>

          <PWAInstallation />
        </UserDeviceProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
