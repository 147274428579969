export class UserDevice {
  private constructor(private pwa: boolean, private hasTouchScreen: boolean, private width: number) {}

  static start(pwa: boolean, width: number) {
    const device = new this(pwa, this.hasTouchScreen(), width);
    return device;
  }

  static hasTouchScreen(): boolean {
    if ('maxTouchPoints' in navigator) {
      return navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
      return (navigator as any).msMaxTouchPoints > 0;
    } else {
      const mQ = matchMedia?.('(pointer:coarse)');
      if (mQ?.media === '(pointer:coarse)') {
        return !!mQ.matches;
      } else if ('orientation' in window) {
        return true; // deprecated, but good fallback
      }
    }
    return false;
  }

  inPWA(): boolean {
    return this.pwa;
  }

  isMobilePWA() {
    return this.isMobile() && this.pwa;
  }

  isMobile() {
    const defaultView = this.width <= 700 && window.screen.orientation.angle === 0;
    const landscapeView = this.width <= 1000 && window.screen.orientation.angle === 90;
    return this.hasTouchScreen && (defaultView || landscapeView);
  }
}
