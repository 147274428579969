import { DateTime } from 'luxon';
import { DateCustom } from '../../../lib/DateCustom';
import XhrRequestHandler, { getQueryParamsExpression } from '../../../lib/XhrRequestHandler';
import { CollaboratorLicense, ICollaboratorLicense, ILicenseType, LicenseType } from './CollaboratorLicense';
import CollaboratorSystem from '../CollaboratorSystem';

class CollaboratorLicenseSystem {
  private readonly base = '/colaboradorlicencias';
  constructor(private requestHandler: XhrRequestHandler, private collaboratorSystem: CollaboratorSystem) {}

  async getLicensesByCollaboratorId(id: string, start: Date, end: Date): Promise<CollaboratorLicense> {
    // // buscamos si lo tenemos en el local storage
    // const jsoncommonContext = localStorage.getItem(this.keyLocalStorageCollaboratorList);
    // if (jsoncommonContext) {
    //   const commonContext = JSON.parse(jsoncommonContext);
    //   if (commonContext.data.colaboradores) {
    //     const col = commonContext.data.colaboradores.find((col) => col.col_id === id);
    //     if (col) {
    //       return Collaborator.fromICollaborator(this, col);
    //     }
    //   }
    // }

    const data = await this.requestHandler.get<ICollaboratorLicense>(`${this.base}/licenses-of/${id}`);
    return CollaboratorLicense.buildFrom(this.collaboratorSystem, data);
  }

  async getMyLicenses(_start: Date, _end: Date): Promise<CollaboratorLicense[]> {
    const start = DateTime.fromJSDate(_start).toFormat('yyyy-LL-dd');
    const end = DateTime.fromJSDate(_end).toFormat('yyyy-LL-dd');
    const data = await this.requestHandler.get<ICollaboratorLicense[]>(
      `${this.base}/my-licenses/${start}/${end}`
    );
    return data.map((d) => CollaboratorLicense.buildFrom(this.collaboratorSystem, d));
  }

  async getCollaboratorLicensesById(id: string): Promise<CollaboratorLicense> {
    const data = await this.requestHandler.get<ICollaboratorLicense>(`${this.base}/${id}`);
    return CollaboratorLicense.buildFrom(this.collaboratorSystem, data);
  }

  async getLicenseTypes(): Promise<LicenseType[]> {
    const data = await this.requestHandler.get<ILicenseType[]>(`${'/licenciatipos'}`);
    return data.map(LicenseType.buildFrom);
  }

  async deactivate(id: string) {
    return await this.requestHandler.put<null, any>(`${this.base}/deactivate/${id}`, {});
  }

  async deleteLicense(id: string) {
    return await this.requestHandler.put<null, any>(`${this.base}/delete/${id}`, {});
  }

  async activate(id: string) {
    return await this.requestHandler.put<null, any>(`${this.base}/activate/${id}`, {});
  }

  getBaseUrlForDatagrid() {
    return this.requestHandler.requestUrl(`${this.base}/datagrid`);
  }

  calculateDifferenceDays(from: DateTime, to: DateTime): number | undefined {
    const differenceDays = to.diff(from, ['days']);

    if (differenceDays) return +(Math.floor(differenceDays['days']) + 1); // El valor absoluto para evitar resultados negativos
  }

  async generateLicense(
    from: DateTime,
    to: DateTime,
    collic_tipolic: string,
    collic_motivo: string,
    collic_activo: boolean
  ): Promise<ICollaboratorLicense> {
    const diasDeLicencia = this.calculateDifferenceDays(from, to);
    const _postData = {
      collic_inicio: from.toISODate(),
      collic_fin: to.toISODate(),
      collic_tipolic: collic_tipolic,
      collic_motivo,
      collic_activo,
      collic_dias_lic: diasDeLicencia,
    };

    return this.requestHandler.post(this.base, _postData);
  }
}

export default CollaboratorLicenseSystem;
