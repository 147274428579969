import { DateTime } from 'luxon';
import { Collaborator } from '../collaborators/Collaborator';
import DocumentUploadControlConfirmed from './DocumentUploadControlConfirmed';
import SystemDocumentType from './SystemDocumentType';

export abstract class SystemDocument {
  abstract getType(): Promise<SystemDocumentType>;
  abstract getUploadedBy(): Promise<Collaborator>;
  abstract uploaded(): boolean;
  abstract getUploadedOn(): DateTime;
  abstract getUploadedDate(): string;
  abstract getDeletedOn(): string | undefined;
  abstract getTypeDescription(): Promise<string>;
  abstract getName(): string;
  abstract getId(): string;
  abstract isDeleted(): boolean;
  abstract isActive(): boolean;
  abstract isConfidentialDocument(): boolean;
  abstract getContentType(): string;
  abstract getSizeInBytes(): number;
  abstract hasControlReference(): boolean;
  abstract getControlReference(): undefined | DocumentUploadControlConfirmed;
  abstract getClassification(): string | undefined;
  abstract getKeys(): string | undefined;
}
