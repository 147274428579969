import AssertionEntity from '../../lib/FormValidator/Assertion/AssertionEntity';
import AssertionItem from '../../lib/FormValidator/Assertion/AssertionItem';
import { useTranslation, Trans } from 'react-i18next';
export class CostPerHourAssertions extends AssertionEntity {
  private constructor() {
    super();
  }

  static build() {
    const instance = new this();

    instance.addAssertionForYear();
    instance.addAssertionForMonth();
    // instance.addAssertionForYear();
    // instance.addAssertionForMonth();
    instance.addAssertionForCostPH();
    instance.addAssertionForColid();
    instance.addAssertionForCoef();
    // instance.addAssertionForAVNAME();

    return instance;
  }

  addAssertionForYear(key = 'year') {
    this.defineAssertionForProperty(
      key,
      AssertionItem.forClosure(
        (value) => {
          return value > '1900' && !isNaN(value);
        },
        key,
        // t(
        'El año debe ser mayor a 1900'
        // )
      )
    );
  }

  addAssertionForMonth(key = 'month') {
    this.defineAssertionForProperty(
      key,
      AssertionItem.forClosure(
        (value) => {
          return value >= 1 && value < 13 && !isNaN(value);
        },
        key,
        // t(
        'El mes debe ser un nùmero entre 1 y 12'
        // )
      )
    );
  }

  addAssertionForCostPH(key = 'costPH') {
    this.defineAssertionForProperty(
      key,
      AssertionItem.forClosure(
        (value) => {
          return value !== '' && !isNaN(value);
        },
        key,
        // t(
        'El costo por hora debe ser un nùmero'
        // )
      )
    );
  }

  addAssertionForColid(key = 'colid') {
    this.defineAssertionForProperty(
      key,
      AssertionItem.forClosure(
        (value) => {
          return value !== '';
        },
        key,
        // t(
        'Se requiere un colaborador'
        // )
      )
    );
  }

  addAssertionForCoef(key = 'coef') {
    this.defineAssertionForProperty(
      key,
      AssertionItem.forClosure(
        (value) => {
          return value !== '' && !isNaN(value);
        },
        key,
        // t(
        'El coeficiente de valor agregado debe ser un nùmero'
        // )
      )
    );
  }

  addAssertionForAVNAME(key = 'AVName') {
    this.defineAssertionForProperty(
      key,
      AssertionItem.forClosure(
        (value) => {
          return value !== '';
        },
        key,
        // t(
        'Se requiere el nombre del valor agregado'
        // )
      )
    );
  }
}
