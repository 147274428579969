import React from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import FileFolderBuilder, { IFileWithPath } from './FileFolderBuilder';
import FileFolder from './FileFolder';
import { maxBytesPerUpload } from '../../common/constants';

// const fileTooLargeCode = 'file-too-large';

interface IFileDropzoneProps {
  onDropStart?: () => void;
  onDrop: (fileFolder: FileFolder) => void | Promise<void>;
  onUploadTooLarge?: () => void;
  shouldOpen?: boolean;
  openOnClick?: boolean;
  multiple?: boolean;
  disabled?: boolean;
}

const FileDropzone: React.FC<IFileDropzoneProps> = (props) => {
  const onDrop = <T extends File & { path?: string }>(acceptedFiles: T[], rejections: FileRejection[]) => {
    if (!acceptedFiles.every((file) => typeof file.path === 'string')) {
      // TODO: error
      return;
    }

    if (rejections.length > 0) {
      if (props.onUploadTooLarge) {
        props.onUploadTooLarge();
      }
    } else if (acceptedFiles.length > 0) {
      const folderStructure = FileFolderBuilder.from(acceptedFiles as IFileWithPath[]);
      if (folderStructure.totalSize() > maxBytesPerUpload) {
        if (props.onUploadTooLarge) {
          props.onUploadTooLarge();
        }
      } else {
        props.onDrop(folderStructure);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, open, isFileDialogActive } = useDropzone({
    noClick: !props.openOnClick,
    noKeyboard: true,
    noDragEventsBubbling: true,
    onDrop,
    maxSize: maxBytesPerUpload,
    onError: (err) => console.error(err),
    multiple: props.multiple === undefined ? true : props.multiple,
    disabled: props.disabled,
    // onDropRejected(fileRejections, event) {
    //   // const tooLarge = fileRejections
    //   //   .filter((reject) => reject.errors.some((error) => error.code === fileTooLargeCode))
    //   //   .map((reject) => reject.file)
    //   //   .map((f: File & { path?: string }) => f.name);
    //   if (props.onUploadTooLarge) {
    //     props.onUploadTooLarge();
    //   }
    // },
  });

  React.useEffect(() => {
    if (props.shouldOpen && !isFileDialogActive) {
      open();
    }
  }, [open, props.shouldOpen, isFileDialogActive]);

  return (
    <div
      {...getRootProps({
        className: `dropzone full-height ${isDragActive && 'dropzone-drag-active'} ${
          isDragAccept && 'dropzone-drag-accept'
        }`,
        onDrop: (_e) => {
          if (props.onDropStart) props.onDropStart();
        },
      })}
    >
      <input {...getInputProps()} />
      {/* <input {...getInputProps()} onChange={() => console.log('changed')} /> */}
      {props.children}
    </div>
  );
};

export default FileDropzone;
