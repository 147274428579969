import { DateTime } from 'luxon';
import { SystemDateParser } from '../SystemDateParser';
import { ProjectDescriptorAssertions } from './ProjectDescriptorAssertions';
import { ProjectJson } from './ProjectsSystem';
import { Collaborator } from '../collaborators/Collaborator';

export interface IProjectDescriptor {
  getDocumentClassificationEnabled(): boolean;
  getConfidentialDocumentsByDefault(): boolean;
  isIdentifiedBy(anId: string): boolean;
  getTitleProject(): string;
  isInRange(date: DateTime): boolean;
  getId(): string;
  getCode(): string;
  getName(): string;
  getState(): string;
  getCanEditDistribution(): boolean;
  getCanViewDistribution(): boolean;
  getStart(): DateTime;
  getEnd(): DateTime;
  getBillable(): boolean;
  getActive(): boolean;
  getDescription(): string;
  getSettlementType(): string;
  getOwnerColid(): string;
  getSuggestTags(): boolean;
  isOwner(col: Collaborator): boolean;
}

class ProjectDescriptor implements IProjectDescriptor {
  protected constructor(
    private name: string,
    private code: string,
    private id: string,
    private description: string,
    private state: string,
    private start: DateTime,
    private end: DateTime,
    private billable: boolean,
    private active: boolean,
    private settlementType: string,
    private canEditDistribution: boolean,
    private canViewDistribution: boolean,
    private confidentialDocumentsByDefault: boolean,
    private suggestTags: boolean,
    private ownerColId: string,
    private enableDocumentClassification: boolean
  ) {}

  static named(
    name: string,
    code: string,
    id: string,
    description: string,
    state: string,
    start: DateTime,
    end: DateTime,
    billable: boolean,
    active: boolean,
    settlementType: string,
    canEditDistribution: boolean,
    canViewDistribution: boolean,
    documentsConfidentialByDefault: boolean,
    suggestTags: boolean,
    ownerColId: string,
    enableDocumentClassification: boolean
  ): ProjectDescriptor {
    const assertions = ProjectDescriptorAssertions.build();
    assertions.evaluateAllAssertions({
      name,
      code,
      id,
      description,
      state,
      start,
      end,
      billable,
      active,
      settlementType,
      canEditDistribution,
      canViewDistribution,
      documentsConfidentialByDefault,
    });
    return new this(
      name,
      code,
      id,
      description,
      state,
      start,
      end,
      billable,
      active,
      settlementType,
      canEditDistribution,
      canViewDistribution,
      documentsConfidentialByDefault,
      suggestTags,
      ownerColId,
      enableDocumentClassification
    );
  }

  static fromJson(project: ProjectJson, dateParser: SystemDateParser) {
    return this.named(
      project.pro_nombre,
      project.pro_codigo,
      project.pro_id,
      project.pro_descripcion,
      project.pro_estado,
      project.pro_inicio ? dateParser.parseNoTime(project.pro_inicio) : DateTime.now(),
      project.pro_fin ? dateParser.parseNoTime(project.pro_fin) : DateTime.now(),
      !!project.pro_facturable,
      !!project.pro_activo,
      project.pro_tipo_liquidacion === 'D' ? 'D' : 'I',
      !!project.pro_us_pueden_editar_distrib,
      !!project.pro_us_pueden_visualizar_distrib,
      project.pro_doc_confidenciales,
      project.pro_sugiere_tag,
      project.pro_owner_colid,
      project.pro_clasificar_documentos
    );
  }

  asJson() {
    return {
      pro_id: this.id,
      pro_codigo: this.code,
      pro_nombre: this.name,
      pro_descripcion: this.description,
      pro_inicio: String(this.start.toUTC().toISO()),
      pro_fin: String(this.end.toUTC().toISO()),
      pro_estado: this.state,
      pro_activo: this.active,
      pro_us_pueden_editar_distrib: this.canEditDistribution,
      pro_us_pueden_visualizar_distrib: this.canViewDistribution,
      pro_facturable: this.billable,
      pro_tipo_liquidacion: this.settlementType,
      pro_sugiere_tag: this.suggestTags,
      pro_doc_confidenciales: this.confidentialDocumentsByDefault,
      pro_clasificar_documentos: this.enableDocumentClassification,
    };
  }

  isIdentifiedBy(anId: string): boolean {
    return +this.id === +anId;
  }

  getTitleProject(): string {
    return `(${this.code}) ${this.name}`;
  }

  getRangeTitle(): string {
    return `(${this.start.toFormat('dd/LL/yyyy')} - ${this.end.toFormat('dd/LL/yyyy')})`;
  }

  isInRange(date: DateTime): boolean {
    return this.start.startOf('day') <= date.startOf('day') && this.end.startOf('day') >= date.startOf('day');
    // return date.diff(this.start).days >= 0 && date.diff(this.end).days <= 0;
  }

  getId(): string {
    return this.id;
  }

  getCode(): string {
    return this.code;
  }

  getName(): string {
    return this.name;
  }

  getState(): string {
    return this.state;
  }

  getCanEditDistribution(): boolean {
    return this.canEditDistribution;
  }

  getCanViewDistribution(): boolean {
    return this.canViewDistribution;
  }

  getStart(): DateTime {
    return this.start;
  }

  getEnd(): DateTime {
    return this.end;
  }

  getBillable(): boolean {
    return this.billable;
  }

  getActive(): boolean {
    return this.active;
  }

  getDescription(): string {
    return this.description;
  }

  getSettlementType(): string {
    return this.settlementType;
  }

  getConfidentialDocumentsByDefault(): boolean {
    return this.confidentialDocumentsByDefault;
  }

  getSuggestTags(): boolean {
    return this.suggestTags;
  }

  getOwnerColid(): string {
    return this.ownerColId;
  }

  isOwner(col: Collaborator): boolean {
    return col.isIdentifiedBy(this.ownerColId);
  }

  getDocumentClassificationEnabled(): boolean {
    return this.enableDocumentClassification;
  }
}

export default ProjectDescriptor;
