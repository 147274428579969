import AssertionEntity from '../../lib/FormValidator/Assertion/AssertionEntity';
import AssertionItem from '../../lib/FormValidator/Assertion/AssertionItem';
import CustomInput, { InputObserver } from '../../lib/FormValidator/Form/CustomInput';
import { ProjectCompanyInDistribution } from './IProjectCompanyInDistribution';

export class ProjectCompanyDistributionAssertions extends AssertionEntity implements InputObserver {
  private constructor() {
    super();
  }

  static build(billable: boolean) {
    const instance = new this();
    instance.addAssertionForCompanies(billable);
    return instance;
  }

  static buildForForm(billableInput: CustomInput) {
    const instance = this.build(billableInput.getValue());
    billableInput.addInputObserver(instance);
    return instance;
  }

  onValueChanged(input: CustomInput): void {
    this.addAssertionForCompanies(input.getValue());
  }

  addAssertionForCompanies(billable: boolean, key = 'companies') {
    const assertions = [
      AssertionItem.forClosure(
        (list: ProjectCompanyInDistribution[]) => {
          const distributionSum = list
            .filter((item) => item.isActive())
            .reduce((partialSum, projectCompany) => projectCompany.affectTotalPercentage(partialSum), 0);
          console.log('distributionSum > ', distributionSum);
          return distributionSum >= 99.9 && distributionSum <= 100;
        },
        key,
        'La suma de los porcentajes debe ser del 100%'
      ),
      AssertionItem.forClosure(
        (list: ProjectCompanyInDistribution[]) =>
          list
            .filter((item) => item.isActive())
            .every((projectCompany) =>
              list.every(
                (anotherProjectCompany) =>
                  projectCompany === anotherProjectCompany ||
                  !projectCompany.IsForSameCompanyAs(anotherProjectCompany)
              )
            ),
        key,
        'Repeated company not allowed'
      ),
      // AssertionItem.forClosure(
      //   (list: ProjectCompanyInDistribution[]) =>
      //     list
      //       .filter((item) => item.isActive())
      //       .every((projectCompany) => projectCompany.getCompany().allowedBillable(billable)),
      //   key,
      //   billable
      //     ? `All companies must be allowed for billable projects`
      //     : `All companies must be allowed for non billable projects`
      // ),
    ];
    if (billable) {
      assertions.unshift(
        AssertionItem.forClosure(
          (list: ProjectCompanyInDistribution[]) => list.filter((item) => item.isActive()).length > 0,
          key,
          'Distribution must have at least one company'
        )
      );
    }
    this.defineMultipleAssertionsForProperty(key, assertions);
  }
}
