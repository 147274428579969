import { DateTime } from 'luxon';
import XhrRequestHandler from '../../lib/XhrRequestHandler';
import CollaboratorSystem from '../collaborators/CollaboratorSystem';
import { Company } from '../company/Company';
import CompanySystem from '../company/CompanySystem';
import Project from '../projects/Project';
import { SystemDateParser } from '../SystemDateParser';
import { DefenseFile } from './DefenseFile';
import { DefenseFileBySettlement } from './DefenseFileBySettlement';
import { DefenseFileBySettlementUploadedDocument } from './DefenseFileBySettlementUploadedDocument';
import DefenseFileGenericRequirement, {
  INewDefenseFileGenericRequirement,
} from './DefenseFileGenericRequirement';
import DefenseFileTaxOfficeRequirement, {
  INewDefenseFileTaxOfficeRequirement,
} from './DefenseFileTaxOfficeRequirement';

export type DefenseFileRequirement = DefenseFileGenericRequirement | DefenseFileTaxOfficeRequirement;
export type INewDefenseFileRequirement =
  | INewDefenseFileGenericRequirement
  | INewDefenseFileTaxOfficeRequirement;

export enum DefenseFileType {
  project = 'proyecto',
  settlements = 'liquidacionIndirecta',
}

export enum DefenseFileState {
  pendingStructure = 'pendienteGeneracionEstructura',
  pendingZip = 'pendienteGeneracionZip',
  zipGenerated = 'zipGenerado',
}

interface INewDefenseFile {
  ard_empid: string;
  ard_desde: string;
  ard_hasta: string;
  ard_tipo: DefenseFileType;
  ard_incluye_actividades_proyectos_indirectos: boolean;
  seleccion: string[];
  requerimiento: INewDefenseFileRequirement;
}

interface IDefenseFile {
  ard_id: string;
  ard_empid: string;
  ard_desde: string;
  ard_hasta: string;
  ard_tipo: DefenseFileType;
  ard_incluye_actividades_proyectos_indirectos: boolean;
  ard_creado_por: string;
  ard_fecha_carga: string;
  ard_estado: DefenseFileState;
}

interface DefenseFileBySettlementJson {
  adl_id: string;
  adl_desde: string; // 01/03/2023
  adl_hasta: string;
  adl_estado: string;
  adl_fecha_carga: string;
  adl_cargado_por: string;
}

interface DefenseFileBySettlementUploadedDocumentJson {
  adla_id: string;
  adla_acceso_url: string;
  adla_adlid: string;
  adla_descripcion: string;
}

export class DefenseFileSystem {
  private readonly entityBase = '/archivos-defensa';
  private readonly entityBaseForSettlements = '/archivos-defensa-por-liquidacion';

  constructor(
    private requestHandler: XhrRequestHandler,
    private dateParser: SystemDateParser,
    private collaboratorsSystem: CollaboratorSystem,
    private companySystem: CompanySystem
  ) {}

  getBaseUrlForDatagrid() {
    return this.requestHandler.requestUrl(`${this.entityBase}/datagrid`);
  }

  getBaseUrlForDatagridForSection(project: Project) {
    return this.requestHandler.requestUrl(`${this.entityBase}/datagrid/${project.getId()}`);
  }

  getBaseUrlForDatagridBySettlement() {
    return this.requestHandler.requestUrl(`${this.entityBaseForSettlements}/datagrid`);
  }

  async generateForAllCompanies(from: DateTime, to: DateTime): Promise<DefenseFileBySettlement> {
    const data = await this.requestHandler.post<DefenseFileBySettlementJson>(
      `${this.entityBaseForSettlements}/generate/${from.toISODate()}/${to.toISODate()}`
    );

    return DefenseFileBySettlement.from(
      data.adl_id,
      this.dateParser.parseNoTime(data.adl_desde),
      this.dateParser.parseNoTime(data.adl_hasta),
      data.adl_estado
    );
  }

  async getDefenseFileBySettlementById(id: string): Promise<DefenseFileBySettlement> {
    const data = await this.requestHandler.get<DefenseFileBySettlementJson>(
      `${this.entityBaseForSettlements}/${id}`
    );

    return DefenseFileBySettlement.from(
      data.adl_id,
      this.dateParser.parseNoTime(data.adl_desde),
      this.dateParser.parseNoTime(data.adl_hasta),
      data.adl_estado
    );
  }

  async getDefenseFileBySettlementUploadedDocuments(
    id: string
  ): Promise<DefenseFileBySettlementUploadedDocument[]> {
    const data = await this.requestHandler.get<DefenseFileBySettlementUploadedDocumentJson[]>(
      `${this.entityBaseForSettlements}/uploaded-documents/${id}`
    );

    return data.map((doc) =>
      DefenseFileBySettlementUploadedDocument.from(doc.adla_id, doc.adla_acceso_url, doc.adla_descripcion)
    );
  }

  async downloadUploadedDocument(document: DefenseFileBySettlementUploadedDocument): Promise<void> {
    const { link } = await this.requestHandler.get<{ link: string }>(
      `${this.entityBaseForSettlements}/download-link-uploaded-document/${document.getId()}`
    );

    window.open(link, '_blank');
  }

  async getById(defenseFileId: string): Promise<DefenseFile> {
    const data = await this.requestHandler.get<IDefenseFile>(`${this.entityBase}/${defenseFileId}`);

    return this.mapToDefenseFile(data);
  }

  async generateForProjects(
    from: DateTime,
    to: DateTime,
    company: Company,
    includesActivities: boolean,
    selectedIds: string[],
    requirement: DefenseFileRequirement
  ): Promise<DefenseFile> {
    return this.generateByType(
      DefenseFileType.project,
      from,
      to,
      company,
      includesActivities,
      selectedIds,
      requirement
    );
  }

  async generateForSettlements(
    from: DateTime,
    to: DateTime,
    company: Company,
    includesActivities: boolean,
    selectedIds: string[],
    requirement: DefenseFileRequirement
  ): Promise<DefenseFile> {
    return this.generateByType(
      DefenseFileType.settlements,
      from,
      to,
      company,
      includesActivities,
      selectedIds,
      requirement
    );
  }

  async generateStructure(defenseFile: DefenseFile) {
    return this.requestHandler.post(`${this.entityBase}/generar-estructura/${defenseFile.getId()}`);
  }

  async generateZippedFile(defenseFile: DefenseFile) {
    return this.requestHandler.post(`${this.entityBase}/generar-zip/${defenseFile.getId()}`);
  }

  async downloadDefenseFileZipped(defenseFileId: string) {
    const { uri } = await this.requestHandler.get<{ uri: string }>(
      `${this.entityBase}/zip-download-uri/${defenseFileId}`
    );
    return uri;
  }

  private async generateByType(
    type: DefenseFileType,
    from: DateTime,
    to: DateTime,
    company: Company,
    includesActivities: boolean,
    selectedIds: string[],
    requirement: DefenseFileRequirement
  ): Promise<DefenseFile> {
    const fromFormatted = this.formatDateForGeneration(from);
    const toFormatted = this.formatDateForGeneration(to);
    const data = await this.requestHandler.post<IDefenseFile, INewDefenseFile>(
      `${this.entityBase}/generar-archivo-defensa`,
      {
        ard_tipo: type,
        ard_desde: fromFormatted,
        ard_hasta: toFormatted,
        ard_empid: company.getId(),
        ard_incluye_actividades_proyectos_indirectos: includesActivities,
        seleccion: selectedIds,
        requerimiento: requirement.toGenerationObject(),
      }
    );

    return this.mapToDefenseFile(data);
  }

  private async mapToDefenseFile(data: IDefenseFile): Promise<DefenseFile> {
    const [collaborator, company] = await Promise.all([
      this.collaboratorsSystem.getCollaboratorIdentifiedBy(data.ard_creado_por),
      this.companySystem.getEmpresaById(data.ard_empid),
    ]);

    return DefenseFile.from(
      data.ard_id,
      this.dateParser.parseNoTime(data.ard_desde),
      this.dateParser.parseNoTime(data.ard_hasta),
      data.ard_tipo,
      data.ard_estado,
      data.ard_incluye_actividades_proyectos_indirectos,
      this.dateParser.parse(data.ard_fecha_carga),
      collaborator,
      company
    );
  }

  private formatDateForGeneration(date: DateTime): string {
    return String(date.startOf('month').toISODate());
  }
}
