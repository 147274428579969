import { DateTime } from 'luxon';

import { BillableDistribution } from '../billable_distribution/BillableDistribution';
import CollaboratorActivity from '../collaborators/collaborator_activity/CollaboratorActivity';
import { ActivityDocument } from '../documents/ActivityDocument';
import Project from '../projects/Project';
import { SystemDateParser } from '../SystemDateParser';
import { ActivityJson } from './ActivitySystem';
import { Activity } from './Activity';

export interface ActivityPendingJson extends ActivityJson {
  hasDuplicates: boolean;
}

export class ActivityPending extends Activity {
  protected constructor(private activity: Activity, private hasDuplicates: boolean) {
    super(
      activity.getId(),
      activity.getHours(),
      activity.getPro_id(),
      activity.getName(),
      DateTime.fromJSDate(activity.getStart()),
      DateTime.fromJSDate(activity.getEnd()),
      activity.getTimezone(),
      activity.getDescription(),
      activity.getActive(),
      activity.getUpload_colid(),
      activity.getOwner_colid(),
      activity.getStatus(),
      activity.getLast_modified_date(),
      activity.getReplicated_from_id(),
      activity.getRefer_outlook(),
      activity.getDistributionBillableList(),
      activity.getDocumentActivityList(),
      activity.getCollaboratorActivityList(),
      activity.getProject(),
      activity.getDocumentErrors()
    );
  }

  static fromJson(
    dateParser: SystemDateParser,
    project: Project | null,
    collaboratorActivity: CollaboratorActivity[],
    billableDistribution: BillableDistribution[],
    documentActivityList: ActivityDocument[],
    activity: ActivityPendingJson,
    documentErrors: string[]
  ) {
    return new this(
      Activity.fromJson(
        dateParser,
        project,
        collaboratorActivity,
        billableDistribution,
        documentActivityList,
        activity,
        documentErrors
      ),
      activity.hasDuplicates
    );
  }

  getHasDuplicates() {
    return this.hasDuplicates;
  }
}
