export const APP_VERSION = '1.0.0';
// para tts
// export const APP_NAME = 'Time Tax Support';
// export const LOGO_PATH = '/images/logo-tts.png';
// export const LOGO_PATROCINADOR = '';
// export const SHOW_HORAS_ESTIMADAS = false;

// para tpx
export const APP_NAME = 'Transfer Pricing Extreme';
export const LOGO_PATH = '/images/logo-tpx.png';
export const LOGO_PATROCINADOR = '';
// export const LOGO_PATROCINADOR = 'images/basefirma-big.png';
export const SHOW_HORAS_ESTIMADAS = true;

export const routes = {
  activity: '/actividad',
  activity_import: '/actividad-import',
  activity_checker: '/actividad-checker',
  distribution_corrector: '/distribution-corrector',
  project: '/proyectos',
  project_list: '/proyectos-list',
  project_config: '/proyectos-config',
  project_panel: 'proyectos-panel',
  project_docs: 'proyectos-docs-obligatorios',
  edit_distribution_by_period: '/edit-distribution-by-period',
  view_distribution_by_period: '/view-distribution-by-period',
  activity_list: '/activity-list',
  collaborator_list: '/colaboradores-list',
  collaborator: '/colaboradores',
  calendar: '/calendario',
  documents: '/documentos',
  dashboard: '/panel-proyectos',
  dashboard_own: '/mi-panel',
  dashboard_empresas: '/panel-empresas',
  dashboard_colaboradores: '/panel-colaboradores',
  reports: '/reportes',
  reportsCompany: '/reportes-empresas',
  reportsSector: '/reportes-sector',
  reportsProject: 'reportes-proyecto',
  reportsProjectDocs: 'reportes-docs-obligatorios-proyecto',
  reportsMMI: '/reportes-mmi',
  reportsBillableDistribution: '/distribucion-facturable',
  preliquidation: '/preliquidacion',
  liquidation: '/liquidacion-directa',
  settlement: '/liquidaciones',
  direct_settlement: '/liquidaciones-directas',
  settlement_list: '/liquidaciones-list',
  direct_settlement_list: '/liquidaciones-directas-list',
  settlementView: '/asistente-liquidaciones',
  directSettlementView: '/asistente-liquidaciones-directas',
  collaborators: '/colaboradores',
  notifications: '/notificaciones',
  // defenseFile: '/archivo-defensa',
  admin: '/admin',
  basic_inputs: '/basic-inputs',
  form_example: '/form-example',
  recover_password: '/recover-password',
  wizard: '/wizard',
  defense_file_assistant: '/asistente-archivo-defensa',
  defense_file: '/archivos-defensa',
  massive_defense_list: '/defensa-masiva-list',
  massive_defense_view: '/asistente-defensa-masiva',
  massive_defense: '/defensa-masiva-list',
  control_doc: '/doc_obligatorio',
  company_tree: '/company-tree',
  maintenance: '/maintenance',
  help: '/help',
  licenses: '/licenses',
  licences_config: '/generate-license',
  mails: '/mails',
  massive_mail_inspector: '/proyectos-mmi',
  bloqueo_periodos: '/bloqueo-periodos',
  cost_per_hour_list: '/costo-por-hora',
  cost_per_hour_view: '/asistente-plantillas',
  cost_per_hour_detail: '/costoPorHora-detalle',
};

export const includesThisRoute = (routesAvailables: string[], pathname: string) => {
  routesAvailables.forEach((route) => {
    if (route[0] === '/') {
      let str = route.slice();
      if (pathname === str) {
        return true;
      }
    }
  });

  return false;
};

export const routesAvailableForStandardUser = [
  routes.activity,
  routes.activity_list,
  routes.distribution_corrector,
  routes.activity_import,
  routes.activity_checker,
  routes.project,
  routes.project_list,
  routes.project_config,
  routes.project_panel,
  routes.project_docs,
  routes.calendar,
  routes.dashboard_own,
  routes.notifications,
  routes.collaborator,
  routes.maintenance,
  routes.help,
  routes.licenses,
  routes.licences_config,
];

export const routesAvailableForDirective = [
  ...routesAvailableForStandardUser,
  routes.collaborator_list,
  routes.documents,
  routes.dashboard,
  routes.dashboard_empresas,
  routes.dashboard_colaboradores,
  routes.reports,
  routes.reportsCompany,
  routes.reportsProject,
  routes.reportsProjectDocs,
  routes.collaborators,
  routes.wizard,
  routes.preliquidation,
  routes.reportsSector,
  routes.company_tree,
  routes.massive_mail_inspector,
  routes.reportsMMI,
  routes.cost_per_hour_list,
  routes.cost_per_hour_view,
  routes.cost_per_hour_detail,
];

export const routesAvailableForPowerUser = [
  ...routesAvailableForDirective,
  routes.settlement,
  routes.direct_settlement,
  routes.settlement_list,
  routes.direct_settlement_list,
  routes.settlementView,
  routes.admin,
  routes.defense_file_assistant,
  routes.defense_file,
  routes.control_doc,
  routes.directSettlementView,
  routes.liquidation,
  routes.massive_defense,
  routes.massive_defense_list,
  routes.massive_defense_view,
  routes.mails,
  routes.bloqueo_periodos,
  routes.edit_distribution_by_period,
  routes.view_distribution_by_period,
  routes.reportsBillableDistribution,
];

export const newEntityDefaultId = 'new';

export const defenseFileAssistantDropdownValues = {
  forProjects: '2',
  forIndirectsSettlements: '1',
};

export const maxBytesPerUpload = 1024 ** 3; // 1 GB
// export const maxBytesPerUpload = 16 * 1024; // 10 KB
export const fileUploadProgressTimeout = 10000;
