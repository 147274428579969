import React, { useEffect } from 'react';
import { IconButton } from '@fluentui/react/lib/Button';
import {
  DefaultPalette,
  Stack,
  IStackStyles,
  IStackTokens,
  IStackItemStyles,
  ComboBox,
  IComboBoxOption,
  SelectableOptionMenuItemType,
  IComboBox,
} from '@fluentui/react';
import {
  ContextualMenuItemType,
  IContextualMenuItem,
  ContextualMenu,
} from '@fluentui/react/lib/ContextualMenu';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import TimeTaxSupportSystemClient from '../../system';
import UserPersona from '../User/UserPersona';
import { LOGO_PATH, routes } from '../../common/constants';
import { useNavigate } from 'react-router-dom';
import { INotification } from '../../system/notifications/NotificationSystem';
import './layoutStyles.css';
import { User } from '../../system/User';
import { t } from 'i18next';
import useSystemCall from '../../common/custom-hooks/useSystemCall';
import { Activity } from '../../system/activity/Activity';
import { CalendarEventCallout } from '../Calendar/CalendarEventCallout';
import SystemConfiguration from '../../system/configuration/SystemConfiguration';
import MessageCourier from '../../lib/MessageCourier';
import PeriodDatesBlockedSystem from '../../system/period_dates_blocked/PeriodDatesBlockedSystem';
import { DateTime } from 'luxon';
import { SystemDateParser } from '../../system/SystemDateParser';
import { useUserDevice } from '../../common/providers/UserDeviceProvider';

const stackStyles: IStackStyles = {
  root: {
    // background: DefaultPalette.themeTertiary,
    background: DefaultPalette.themeLight,
    width: '100%',
    // height: '56px',
    padding: '1rem',
    position: 'fixed',
    top: '0',
    zIndex: '100',
  },
};

const stackItemRoorStyles = {
  alignItems: 'center',
  display: 'flex',
  height: 30,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    ...stackItemRoorStyles,
    justifyContent: 'start',
  },
};

const stackItemJustifyEndStyles: IStackItemStyles = {
  root: {
    ...stackItemRoorStyles,
    justifyContent: 'end',
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 5,
  // padding: 10,
};

const searchOptions: IComboBoxOption[] = [
  { key: 'Header1', text: 'First heading', itemType: SelectableOptionMenuItemType.Header },
  { key: 'A', text: 'Option A' },
  { key: 'B', text: 'Option B' },
  { key: 'C', text: 'Option C' },
  { key: 'D', text: 'Option D' },
  { key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
  { key: 'Header2', text: 'Second heading', itemType: SelectableOptionMenuItemType.Header },
  { key: 'E', text: 'Option E' },
  { key: 'G', text: 'Option G' },
  { key: 'H', text: 'Option H' },
  { key: 'I', text: 'Option I' },
  { key: 'J', text: 'Option J' },
];

interface IHeaderProps {
  onMenuButtonClick: () => void;
  system: TimeTaxSupportSystemClient;
  user: User;
  config: SystemConfiguration;
  courier: MessageCourier;
  changeBlockUI: (v: boolean) => void;
  periodsBlocked: PeriodDatesBlockedSystem;
}

const signOutKey = 'signOut';
const myPanelKey = 'myPanel';
const openNotificationsKey = 'openNotifications';
const openPendingActivities = 'openPendingActivities';

const Header: React.FC<IHeaderProps> = (props) => {
  const personaRef = React.useRef(null);
  const userDevice = useUserDevice();
  const NotificationsQty = 10;
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const [showNotificationMenu, setShowNotificationMenu] = React.useState(false);
  const [notificationsList, setNotificationsList] = React.useState<INotification[]>([]);
  const [searching, setSearching] = React.useState(false);
  const [unreadNotifications, setUnreadNotifications] = React.useState<number>();

  const [activity, setActivity] = React.useState<Activity | undefined>(undefined);
  const [showDialog, setShowDialog] = React.useState(false);

  const projects = useSystemCall(props.system, (system) => system.getProjects());
  const commonContext = useSystemCall(props.system, (system) =>
    system.getActivitySystem().getCommonContext()
  );

  const onHideContextualMenu = () => {
    setShowContextualMenu(false);
    setShowNotificationMenu(false);
  };
  const onShowContextualMenu = () => setShowContextualMenu(true);
  const onShowNotificationMenu = () => setShowNotificationMenu(true);

  const navigate = useNavigate();

  const MINUTE_MS = 120000;
  // const MINUTE_MS = 120000;

  useEffect(() => {
    const interval = setInterval(() => {
      getNotificationsList();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const getNotificationsList = async () => {
    try {
      let notifications: any = await props.system.getNotificationSystem().getLastNotifications(false);
      const cant = await props.system.getNotificationSystem().getTotal();
      setUnreadNotifications(cant);

      setNotificationsList(notifications);
      // let unread = 0;
      // notifications.forEach((notification: INotification) => {
      //   if (notification.not_leido === false) {
      //     unread++;
      //   }
      // });
      // const notificacionesNoLeidas = notifications.filter((not) => !not.not_leido).length;
      // setUnreadNotifications(notificacionesNoLeidas);
    } catch (error) {
      props.courier.messageError(error);
    }
  };

  const markAsRead = async (id: string) => {
    let mark = await props.system.getNotificationSystem().markAsRead(id);
  };

  const onNotificationClick = (i: number) => {
    onNotificationClickWrapped(i);
  };
  const onNotificationClickWrapped = async (i: number) => {
    await markAsRead(notificationsList[i].not_id);

    if (notificationsList[i].not_entidad === 'Actividad') {
      await getActivityFromNotificacion(i);
    }

    await getNotificationsList();
  };

  const getActivityFromNotificacion = async (i: number) => {
    props.changeBlockUI(true);
    try {
      const act = await props.system.getActivitySystem().getActivity(notificationsList[i].not_entidad_id);
      setActivity(act);
      setShowDialog(true);
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      props.changeBlockUI(false);
    }
  };

  const goToActivitiesChecker = () => {
    navigate(routes.activity_checker);
  };

  function calcularTiempoPasado(fecha: string): string {
    let parser = new SystemDateParser('dd/LL/yyyy HH:mm');
    const fechaActual = DateTime.now();
    const fechaPasada = parser.parse(fecha);
    const diferencia = fechaActual
      .diff(fechaPasada, ['years', 'months', 'days', 'hours', 'minutes', 'seconds'])
      .toObject();

    if (diferencia.years && diferencia.years > 0) {
      return `Hace ${diferencia.years} ${diferencia.years === 1 ? 'año' : 'años'}`;
    } else if (diferencia.months && diferencia.months > 0) {
      return `Hace ${diferencia.months} ${diferencia.months === 1 ? 'mes' : 'meses'}`;
    } else if (diferencia.days && diferencia.days > 0) {
      return `Hace ${diferencia.days} ${diferencia.days === 1 ? 'día' : 'días'}`;
    } else if (diferencia.hours && diferencia.hours > 0) {
      return `Hace ${diferencia.hours} ${diferencia.hours === 1 ? 'hora' : 'horas'}`;
    } else if (diferencia.minutes && diferencia.minutes > 0) {
      return `Hace ${diferencia.minutes} ${diferencia.minutes === 1 ? 'minuto' : 'minutos'}`;
    } else {
      return `Hace ${diferencia.seconds} ${diferencia.seconds === 1 ? 'segundo' : 'segundos'}`;
    }
  }

  const getFormattedNotificationList = () => {
    let notifications: IContextualMenuItem[] | undefined = [];
    if (notificationsList && notificationsList.length > 0) {
      let notificationsDisplayed;
      if (notificationsList.length > NotificationsQty) {
        notificationsDisplayed = NotificationsQty;
      } else {
        notificationsDisplayed = notificationsList.length;
      }

      for (let i = 0; i < notificationsDisplayed; i++) {
        notifications.push({
          key: notificationsList[i].not_id,
          iconProps: { iconName: notificationsList[i].not_leido ? 'Ringer' : 'RingerSolid' },
          text: notificationsList[i].not_texto,
          title: notificationsList[i].not_asunto,
          onClick: () => onNotificationClick(i),
          secondaryText: calcularTiempoPasado(notificationsList[i].not_fecha),
        });
      }
    } else {
      notifications.push({ key: 'null', text: t('No hay notificaciones') });
    }
    notifications.push(
      { key: 'divider_2', itemType: ContextualMenuItemType.Divider },
      { key: openNotificationsKey, iconProps: { iconName: 'ArrowUpRight' }, text: t('Ver todas') },
      {
        key: openPendingActivities,
        iconProps: { iconName: 'AccountActivity' },
        text: t('Ver todas las actividades pendientes'),
        onClick: goToActivitiesChecker,
      }
    );
    return notifications;
  };

  useEffect(() => {
    setTimeout(getNotificationsList, 500);
  }, [window.location.href]);

  const menuItems: IContextualMenuItem[] = React.useMemo(
    () => [
      { key: myPanelKey, iconProps: { iconName: 'AccountManagement' }, text: t('Mi Perfil') },
      { key: signOutKey, iconProps: { iconName: 'SignOut' }, text: t('Cerrar sesión') },
    ],
    []
  );

  const notificationItems: any = React.useMemo(
    () => getFormattedNotificationList(),
    [notificationsList, getFormattedNotificationList]
  );

  const onClickItem = (item: IContextualMenuItem | undefined) => {
    if (!item) return onHideContextualMenu();
    if (item.key === signOutKey) {
      props.system.signOut(`${window.location.origin}`);
    }
    if (item.key == openNotificationsKey) {
      navigate(`${routes.notifications}`);
    }
    if (item.key == myPanelKey) {
      navigate(routes.collaborator + '/' + props.user.getCollaboratorId());
    }

    return onHideContextualMenu();
  };

  const onSearchInputChange = (value: string): void => {
    // console.log('value :>> ', value);
  };

  const onSearch = (_: React.FormEvent<IComboBox>, option?: IComboBoxOption, __?: number, value?: string) => {
    // TODO: search
  };

  const collaboratorActivity = React.useMemo(
    () => activity?.getCollaboratorActivityList().find((colact) => colact.referencesUser(props.user)),
    [activity, props.user]
  );

  const pendingTag = React.useMemo(
    () => !!collaboratorActivity && !collaboratorActivity.getChecked(),
    [collaboratorActivity]
  );

  const onSearchIconClick = () => setSearching(true);
  const onCancelSearchIconClick = () => setSearching(false);
  return (
    <Stack horizontal styles={stackStyles} tokens={stackTokens}>
      {searching ? (
        <Stack.Item grow={1} styles={stackItemStyles}>
          <ComboBox
            selectedKey={undefined}
            label=""
            autoComplete="on"
            allowFreeform={true}
            options={searchOptions}
            onChange={onSearch}
            onInputValueChange={onSearchInputChange}
            openOnKeyboardFocus
            styles={{ container: { width: '100%' } }}
          />
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            title={t('Cancelar búsqueda')}
            ariaLabel="Cancel search"
            styles={{ root: { marginLeft: '1rem' } }}
            onClick={onCancelSearchIconClick}
          />
        </Stack.Item>
      ) : (
        <React.Fragment>
          <Stack.Item grow={1} styles={stackItemStyles}>
            <IconButton
              iconProps={{
                iconName: 'GlobalNavButton',
                // styles: { root: { color: DefaultPalette.themeLight } }
              }}
              title={t('Menú')}
              ariaLabel="Menú"
              onClick={props.onMenuButtonClick}
              className="ms-hiddenLgUp"
            />
            <Image
              imageFit={ImageFit.contain}
              width={130}
              src={LOGO_PATH}
              alt='Example of the image fit value "centerContain" on an image smaller than the frame.'
            />
          </Stack.Item>
          <Stack.Item grow={4} styles={stackItemStyles}></Stack.Item>
          <Stack.Item grow={1} styles={stackItemJustifyEndStyles}>
            <div>
              <IconButton
                iconProps={{ iconName: 'Ringer' }}
                title={t('Notificaciones')}
                ariaLabel="Notifications"
                styles={{ root: { marginRight: '1rem' } }}
                onClick={onShowNotificationMenu}
              />
              {unreadNotifications ? (
                <div className="notification-alert-container">
                  <div className="notification-alert">
                    <span className="notification-alert-text">
                      {unreadNotifications > 999 ? `999+` : unreadNotifications}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
            <UserPersona
              user={props.user}
              className="clickable"
              ref={personaRef}
              onClick={onShowContextualMenu}
              hidePersonaDetails={userDevice.isMobile()}
            />
            <ContextualMenu
              items={menuItems}
              hidden={!showContextualMenu}
              target={personaRef}
              onItemClick={(e, item) => onClickItem(item)}
              onDismiss={onHideContextualMenu}
            />
            <ContextualMenu
              items={notificationItems}
              hidden={!showNotificationMenu}
              target={personaRef}
              onItemClick={(e, item) => onClickItem(item)}
              onDismiss={onHideContextualMenu}
            />
          </Stack.Item>
        </React.Fragment>
      )}

      {activity && (
        <CalendarEventCallout
          periodsBlocked={props.periodsBlocked}
          system={props.system}
          projects={projects ? projects : []}
          user={props.user}
          eventData={null}
          targetId={'forNotif'}
          labelId={'forNotif'}
          descriptionId={'forNotif'}
          toggleIsCalloutVisible={() => {
            if (pendingTag) setShowDialog(!showDialog);
            setActivity(undefined);
          }}
          isCalloutVisible={pendingTag ? showDialog : false}
          commonContext={commonContext}
          getEvents={() => {
            getNotificationsList();
            setShowDialog(false);
          }}
          courier={props.courier}
          onlyShowDialogs={!pendingTag}
          activityProvided={activity}
          toggleShowDialog={() => {
            if (!pendingTag) setShowDialog(!showDialog);
            setActivity(undefined);
          }}
          showDialog={!pendingTag}
          changeBlockUI={props.changeBlockUI}
          config={props.config}
          hideMenuOptions={true}
        />
      )}
    </Stack>
  );
};

export default Header;
