import { Icon, Text } from '@fluentui/react';
import React from 'react';
import DialogTemplate from '../Common/DialogTemplate';
import { useTranslation, Trans } from 'react-i18next';

export interface IUnableModifyHours {
  show: boolean;
  onClose: (accept: boolean) => void;
}

export const UnableModifyHoursDialog = (props: IUnableModifyHours) => {
  const { t, i18n } = useTranslation();

  const cancel = () => {
    props.onClose(false);
  };

  const accept = () => {
    props.onClose(true);
  };

  return (
    <DialogTemplate
      showDialog={props.show}
      toggleShowDialog={() => props.onClose(false)}
      title={t('No puedes modificar tus horas')}
      onAccept={accept}
      onCancel={cancel}
      acceptText={t('Entendido')}
      cancelText={''}
      hideCancelButton={true}
      minWidth={600}
    >
      <div>
        <Trans>
          <Text block variant="medium">
            <Icon iconName="AlertSolid" className="text-icon" />
            {t(
              'En esta fecha ya no está disponible la creación/edición de actividades y tampoco la confirmacion de participación de las mismas'
            )}
          </Text>
        </Trans>
      </div>
    </DialogTemplate>
  );
};
