import React, { createContext, useContext } from 'react';
import { UserDevice } from '../UserDevice';

const UserDeviceContext = createContext(UserDevice.start(false, window.innerWidth));

export const useUserDevice = () => useContext(UserDeviceContext);

export const UserDeviceProvider = ({ children }) => {
  const [userDevice, setUserDevice] = React.useState(UserDevice.start(true, window.innerWidth));
  // const [userDevice, setUserDevice] = React.useState(UserDevice.start(false, window.innerWidth));

  const handleContentLoaded = React.useCallback(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      console.log('Using PWA');
      setUserDevice(UserDevice.start(true, window.innerWidth));
    }
  }, []);

  const handleResize = React.useCallback(() => {
    setUserDevice((u) => UserDevice.start(u.inPWA(), window.innerWidth));
  }, []);

  React.useEffect(() => {
    window.addEventListener('DOMContentLoaded', handleContentLoaded);
    return () => {
      window.removeEventListener('DOMContentLoaded', handleContentLoaded);
    };
  }, [handleContentLoaded]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return <UserDeviceContext.Provider value={userDevice}>{children}</UserDeviceContext.Provider>;
};
