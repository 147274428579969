import React from 'react';
import ReactDOM from 'react-dom';
import { mergeStyles } from '@fluentui/react';

import App from './App';
import './index.css';
import './styles/utilities.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

function renderApp(newVersion: boolean, onUpdate: () => void) {
  ReactDOM.render(
    <App newVersionAvailable={newVersion} onUpdateVersion={onUpdate} />,
    document.getElementById('root')
  );
}

renderApp(false, () => {});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      // if (window.confirm('New version available!  refresh to update your app?')) {}
      renderApp(true, () => {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      });
    }
  },
};

// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register(configuration);
