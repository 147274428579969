import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { INavStyles } from '@fluentui/react/lib/Nav';
import { User } from '../../system/User';

import AppNav from './AppNav';
import Environment from '../../lib/Environment';

const navStylesWhenNotInPanel: Partial<INavStyles> = {
  root: {
    height: '100%',
    boxSizing: 'border-box',
    border: '1px solid #eee',
    overflowY: 'auto',
  },
};

interface ISidebarProps {
  isOpen: boolean;
  dismissPanel: () => void;
  user: User;
  environment: Environment;
}

const Sidebar: React.FC<ISidebarProps> = (props) => {
  return (
    <React.Fragment>
      <AppNav
        user={props.user}
        className="ms-hiddenMdDown"
        styles={navStylesWhenNotInPanel}
        environment={props.environment}
      />
      <Panel
        isLightDismiss
        isOpen={props.isOpen}
        onDismiss={props.dismissPanel}
        closeButtonAriaLabel="Cerrar"
        type={PanelType.smallFixedNear}
        className="ms-hiddenLgUp"
        styles={{ content: { paddingLeft: '0.5rem' } }}
      >
        <AppNav user={props.user} onLinkClicked={props.dismissPanel} environment={props.environment} />
      </Panel>
    </React.Fragment>
  );
};

export default Sidebar;
