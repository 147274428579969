import { DateTime } from 'luxon';
import { SystemDateParser } from '../SystemDateParser';

export interface IMailAttachment {
  mat_id: string;
  mat_nombre: string;
  mat_mailid: string;
  mat_acceso_url: string;
  mat_hash: string;
  mat_clasificacion: string;
  mat_claves: string;
  mat_fecha_creacion: string;
}

export class MailAttachment {
  protected constructor(
    private id: string,
    private nombre: string,
    private mailid: string,
    private acceso_url: string,
    private hash: string,
    private clasificacion: string,
    private claves: string,
    private fecha_creacion: DateTime
  ) {}

  static identifiedBy(
    id: string,
    nombre: string,
    mailid: string,
    acceso_url: string,
    hash: string,
    clasificacion: string,
    claves: string,
    fecha_creacion: DateTime
  ) {
    return new this(id, nombre, mailid, acceso_url, hash, clasificacion, claves, fecha_creacion);
  }

  static buildFrom(mail_attachment: IMailAttachment) {
    let parser = new SystemDateParser('dd/LL/yyyy HH:mm');

    return this.identifiedBy(
      mail_attachment.mat_id,
      mail_attachment.mat_nombre,
      mail_attachment.mat_mailid,
      mail_attachment.mat_acceso_url,
      mail_attachment.mat_hash,
      mail_attachment.mat_clasificacion,
      mail_attachment.mat_claves,
      parser.parse(mail_attachment.mat_fecha_creacion)
    );
  }

  getId = () => this.id;
  getNombre = () => this.nombre;
  getMailid = () => this.mailid;
  getAcceso_url = () => this.acceso_url;
  getHash = () => this.hash;
  getClasificacion = () => this.clasificacion;
  getClaves = () => this.claves;
  getFecha_creacion = () => this.fecha_creacion;
}
