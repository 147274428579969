import { DateTime } from 'luxon';
import { PeriodDatesBlocked } from './PeriodDatesBlocked';

class PeriodDatesBlockedSystem {
  private readonly base = '/periodos-bloqueo-carga';
  private constructor(private periods: PeriodDatesBlocked[]) {}

  static default() {
    return new this([]);
  }

  static build(periods: PeriodDatesBlocked[]) {
    return new this(periods);
  }

  isDateBlocked(d: DateTime) {
    let isBlocked = false;
    this.periods.forEach((period) => {
      if (!isBlocked) {
        isBlocked = !!period.isInRange(d);
      }
    });
    return isBlocked;
  }
}

export default PeriodDatesBlockedSystem;
