import LazyInitialization from '../../lib/LazyInitialization';
import Project from './Project';
import { ProjectsSystem, IStoredProjectFolder } from './ProjectsSystem';
import ProjectFolder from './ProjectFolder';
import { ProjectRelatedDocument } from '../documents/ProjectRelatedDocument';
import { t } from 'i18next';

export class ProjectCommonFolder extends ProjectFolder {
  private children: LazyInitialization<ProjectFolder[]>;

  private constructor(
    system: ProjectsSystem,
    project: Project,
    id: string,
    name: string,
    private root: boolean,
    private hasFoldersInside: boolean
  ) {
    super(system, project, id, name);
    this.children = new LazyInitialization(() => system.foldersChildrenOf(this));
  }

  static fromIProjectFolder(system: ProjectsSystem, project: Project, folder: IStoredProjectFolder) {
    const root = !folder.pca_padre;
    return new this(
      system,
      project,
      folder.pca_id,
      root ? t('Documentos') : folder.pca_nombre,
      root,
      !!folder.hasChildren
    );
  }

  moveInto(documents: ProjectRelatedDocument[]): Promise<void> {
    return this.system.moveDocumentsInto(this, documents);
  }

  addChildNamed(childName: string): Promise<ProjectFolder> {
    return this.system.createFolderChildOf(this, childName);
  }

  isSystemFolder(): boolean {
    return false;
  }

  isRoot(): boolean {
    return this.root;
  }

  hasChildren(): boolean {
    return this.hasFoldersInside;
  }

  /**
   * @TODO hacerlo tambien para valores no cacheados
   */
  findChildIdentifiedAs(id: string): ProjectFolder | undefined {
    if (this.isIdentifiedBy(id)) {
      return this;
    }
    return this.children.withCachedValueDo<ProjectFolder | undefined>(
      (children) => {
        for (const child of children) {
          if (child.isIdentifiedBy(id)) {
            return child;
          }
          const possibleFolder = child.findChildIdentifiedAs(id);
          if (possibleFolder) {
            return possibleFolder;
          }
        }
        return undefined;
      },
      () => undefined
    );
  }

  hasChild(child: ProjectFolder): boolean {
    return this.children.withCachedValueDo<boolean>(
      (children) => children.includes(child),
      () => false
    );
  }

  async addChild(folder: ProjectFolder): Promise<void> {
    await this.system.addChildTo(this, folder);
    await this.resetChildren();
  }

  moveToTrash(): Promise<void> {
    return this.system.moveToTrash(this);
  }

  restoreFromTrash(): Promise<void> {
    return this.system.restoreFromTrash(this);
  }

  pathUpTo(descendant: ProjectFolder): ProjectFolder[] {
    const path: ProjectFolder[] = [];
    this.collectPathUpToInto(path, descendant);
    return path;
  }

  collectPathUpToInto(path: ProjectFolder[], descendant: ProjectFolder) {
    path.push(this);
    this.children.withCachedValueDo(
      (children) => {
        const nextInPath = children.find((child) => child.hasDescendant(descendant));
        if (nextInPath) {
          nextInPath.collectPathUpToInto(path, descendant);
        }
      },
      () => {}
    );
  }

  hasDescendant(folder: ProjectFolder): boolean {
    return (
      this.hasChild(folder) ||
      this.children.withCachedValueDo<boolean>(
        (children) => children.some((child) => child.hasDescendant(folder)),
        () => false
      )
    );
  }

  getChildren(): Promise<ProjectFolder[]> {
    return this.children.value();
  }

  resetChildren() {
    return this.children.reload();
  }

  async changeNameTo(value: string): Promise<void> {
    await this.system.changeNameOf(this, value);
    this.name = value;
  }

  isTrash(): boolean {
    return false;
  }

  belongsToUser(): boolean {
    return false;
  }
}
